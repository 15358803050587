import React from 'react';
import { Col } from 'react-bootstrap';

export const calculateLTV = inquiryInfo => {
	let totLoan = 0;
	inquiryInfo.mortgageBalance.index.forEach(loanBal => {
		totLoan += +loanBal.balanceAmt;
	});
	totLoan += inquiryInfo.loanAmt;
	switch (inquiryInfo.loanType) {
		case 'purchase':
			return (+inquiryInfo.loanAmt / +inquiryInfo.currentEstValue) * 100;
		case '2nd':
			return ((+inquiryInfo.loanAmt + +inquiryInfo.mortgageBalance.index[0].balanceAmt) / +inquiryInfo.currentEstValue) * 100;
		case '3rd':
			return (
				((+inquiryInfo.loanAmt + +inquiryInfo.mortgageBalance.index[0].balanceAmt + +inquiryInfo.mortgageBalance.index[1].balanceAmt) / +inquiryInfo.currentEstValue) * 100
			);
		case 'bridge/mezz':
			return ((+inquiryInfo.loanAmt + +inquiryInfo.mortgageBalance.index[0].balanceAmt) / +inquiryInfo.currentEstValue) * 100;
		case 'refinance':
			return (+inquiryInfo.loanAmt / +inquiryInfo.currentEstValue) * 100;
		case 'fix & flip/Rehab':
			return {
				LTV: (+inquiryInfo.loanAmt / +inquiryInfo.purchasedPrice) * 100,
				ARV: ((+inquiryInfo.fixUpCost + +inquiryInfo.loanAmt) / +inquiryInfo.afterRemodelingValue) * 100,
			};
		case 'construction/project':
			if (inquiryInfo.includingLandCost === 'true') {
				return (
					(+inquiryInfo.landPurchase - +inquiryInfo.landDownPayment + +inquiryInfo.loanAmt) / +inquiryInfo.marketValueAfterCompletion *
					100
				)
			} else {
				return (
					(+inquiryInfo.landLoanBalance + +inquiryInfo.loanAmt) / +inquiryInfo.marketValueAfterCompletion *
					100
				)
			}
		default:
			return (+totLoan / +inquiryInfo.currentEstValue) * 100;
	}
};

export const showWhichTD = tds => {
	if (tds.length) {
		if (tds[0].balanceAmt === null || !tds[0].balanceAmt || tds[0].balanceAmt === 0) {
			return '1st ';
		}
		for (let i = tds.length - 1; i >= 0; i--) {
			if (tds[i].balanceAmt !== null && tds[i].balanceAmt !== 0 && tds[i]) {
				if (i === 0) return '2nd ';
				if (i === 1) return '3rd ';
				return i + 1 + 'th ';
			}
		}
	} else {
		return '';
	}
};

export const priceTag = price => {
	if (price) {
		let numDec = price.toString().length;
		let numArr = Array.from(price.toString());
		let numFormat = [];
		for (let x = 0; x < numDec; x++) {
			if (x % 3 === 0 && x !== 0) {
				numFormat.unshift(',');
			}
			numFormat.unshift(numArr[numDec - x - 1]);
		}
		numFormat = numFormat.join('');
		return '$' + numFormat;
	} else {
		return 0;
	}
};

export const shortenPrice = n => {
	if (n) {
		let x = n.toString();
		if (x.length > 9) {
			return Math.round(n / 100000000) / 10 + 'B';
		} else if (x.length > 6) {
			return Math.round(n / 100000) / 10 + 'M';
		} else if (x.length > 3) {
			return x.slice(0, -3) + 'K';
		} else {
			return priceTag(n);
		}
	} else {
		return '-';
	}
};

export const commafy = num => {
	let parts = num.toString().split('.');
	parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
	return '$' + parts.join('.');
};

export const formatPhoneNumber = str => {
	const cleaned = ('' + str).replace(/\D/g, '');

	const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

	if (match) {
		return '(' + match[1] + ') ' + match[2] + '-' + match[3];
	}

	return null;
};

export const totalAmtOwned = arr => {
	let total = 0;
	for (let i = 0; i < arr.length; i++) {
		total = total + arr[i].balanceAmt;
	}
	return total;
};

export const DisplayLTV = ({ singleProp, color, formatView }) => {
	let cltv = '';
	const whichTD = showWhichTD(singleProp.mortgageBalance.index);
	if (formatView) {
		if (singleProp.loanType === 'fix & flip/Rehab') {
			cltv = calculateLTV(singleProp);
			return (
				<>
					<Col xs={4} className="p-0">
						<span className="d-block invest-details text-no-hover">LTV/ARV(%):&nbsp;</span>
					</Col>
					<Col xs={7} className="p-0">
						<span className={`fw-600  text-no-hover`}>
							{cltv.LTV > 100 || isNaN(cltv.LTV) || cltv.LTV === 0 ? '' : cltv.LTV.toFixed(0) + '%, '}
							{cltv.ARV > 100 || isNaN(cltv.ARV) || cltv.ARV === 0 ? '' : cltv.ARV.toFixed(0) + '%'}
						</span>
					</Col>
				</>
			);
		} else {
			cltv = calculateLTV(singleProp);
			return (
				<>
					<Col xs={4} className="p-0">
						<span className="d-block invest-details text-no-hover">{whichTD === '1st ' ? 'LTV: ' : 'CLTV: '}</span>
					</Col>
					<Col xs={7} className="p-0">
						<span className={`fw-600  text-no-hover`}>{cltv > 100 || isNaN(cltv) || cltv === 0 ? '' : cltv.toFixed(0) + '%'}</span>
					</Col>
				</>
			);
		}
	} else {
		if (singleProp.loanType === 'fix & flip/Rehab') {
			cltv = calculateLTV(singleProp);
			return (
				<>
					{'LTV & ARV: '}
					<span className={`fw-600  text-no-hover`}>
						{cltv.LTV > 100 || isNaN(cltv.LTV) || cltv.LTV === 0 ? '-' : cltv.LTV.toFixed(2)}%,{' '}
						{cltv.ARV > 100 || isNaN(cltv.ARV) || cltv.ARV === 0 ? '-' : cltv.ARV.toFixed(2)}%
					</span>
				</>
			);
		} else {
			cltv = calculateLTV(singleProp);
			return (
				<>
					{whichTD === '1st ' ? 'LTV: ' : 'CLTV: '}
					<span className={`fw-600  text-no-hover`}>{cltv > 100 || isNaN(cltv) || cltv === 0 ? '-' : cltv.toFixed(2)}%</span>
				</>
			);
		}
	}
};

export const trimAddress = ({ line1 }) => {
	return line1.trim().substr(line1.indexOf(' ') + 1);
};

export const limitStringLength = (length, string) => {
	return string.length > length ? string.substr(0, length - 1) + '...' : string;
};
