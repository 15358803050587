import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { CenterStick } from '../../global/svgs';
import { Link } from 'react-router-dom';

const HeroV2 = ({ jumpToSectionRef }) => {
	const Bullseye = () => (
		<div className="mx-2 bullseye">
			<svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M5 9.375C6.16032 9.375 7.27312 8.91406 8.09359 8.09359C8.91406 7.27312 9.375 6.16032 9.375 5C9.375 3.83968 8.91406 2.72688 8.09359 1.90641C7.27312 1.08594 6.16032 0.625 5 0.625C3.83968 0.625 2.72688 1.08594 1.90641 1.90641C1.08594 2.72688 0.625 3.83968 0.625 5C0.625 6.16032 1.08594 7.27312 1.90641 8.09359C2.72688 8.91406 3.83968 9.375 5 9.375ZM5 10C6.32608 10 7.59785 9.47322 8.53553 8.53553C9.47322 7.59785 10 6.32608 10 5C10 3.67392 9.47322 2.40215 8.53553 1.46447C7.59785 0.526784 6.32608 0 5 0C3.67392 0 2.40215 0.526784 1.46447 1.46447C0.526784 2.40215 0 3.67392 0 5C0 6.32608 0.526784 7.59785 1.46447 8.53553C2.40215 9.47322 3.67392 10 5 10Z"
					fill="#666666"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M5 8.125C5.8288 8.125 6.62366 7.79576 7.20971 7.20971C7.79576 6.62366 8.125 5.8288 8.125 5C8.125 4.1712 7.79576 3.37634 7.20971 2.79029C6.62366 2.20424 5.8288 1.875 5 1.875C4.1712 1.875 3.37634 2.20424 2.79029 2.79029C2.20424 3.37634 1.875 4.1712 1.875 5C1.875 5.8288 2.20424 6.62366 2.79029 7.20971C3.37634 7.79576 4.1712 8.125 5 8.125ZM5 8.75C5.49246 8.75 5.98009 8.653 6.43506 8.46455C6.89003 8.27609 7.30343 7.99987 7.65165 7.65165C7.99987 7.30343 8.27609 6.89003 8.46455 6.43506C8.653 5.98009 8.75 5.49246 8.75 5C8.75 4.50754 8.653 4.01991 8.46455 3.56494C8.27609 3.10997 7.99987 2.69657 7.65165 2.34835C7.30343 2.00013 6.89003 1.72391 6.43506 1.53545C5.98009 1.347 5.49246 1.25 5 1.25C4.00544 1.25 3.05161 1.64509 2.34835 2.34835C1.64509 3.05161 1.25 4.00544 1.25 5C1.25 5.99456 1.64509 6.94839 2.34835 7.65165C3.05161 8.35491 4.00544 8.75 5 8.75Z"
					fill="#666666"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M5 6.875C5.49728 6.875 5.97419 6.67746 6.32583 6.32583C6.67746 5.97419 6.875 5.49728 6.875 5C6.875 4.50272 6.67746 4.02581 6.32583 3.67417C5.97419 3.32254 5.49728 3.125 5 3.125C4.50272 3.125 4.02581 3.32254 3.67417 3.67417C3.32254 4.02581 3.125 4.50272 3.125 5C3.125 5.49728 3.32254 5.97419 3.67417 6.32583C4.02581 6.67746 4.50272 6.875 5 6.875ZM5 7.5C5.66304 7.5 6.29893 7.23661 6.76777 6.76777C7.23661 6.29893 7.5 5.66304 7.5 5C7.5 4.33696 7.23661 3.70107 6.76777 3.23223C6.29893 2.76339 5.66304 2.5 5 2.5C4.33696 2.5 3.70107 2.76339 3.23223 3.23223C2.76339 3.70107 2.5 4.33696 2.5 5C2.5 5.66304 2.76339 6.29893 3.23223 6.76777C3.70107 7.23661 4.33696 7.5 5 7.5Z"
					fill="#666666"
				/>
				<path
					d="M5.9375 5C5.9375 5.24864 5.83873 5.4871 5.66291 5.66291C5.4871 5.83873 5.24864 5.9375 5 5.9375C4.75136 5.9375 4.5129 5.83873 4.33709 5.66291C4.16127 5.4871 4.0625 5.24864 4.0625 5C4.0625 4.75136 4.16127 4.5129 4.33709 4.33709C4.5129 4.16127 4.75136 4.0625 5 4.0625C5.24864 4.0625 5.4871 4.16127 5.66291 4.33709C5.83873 4.5129 5.9375 4.75136 5.9375 5Z"
					fill="#03946d"
				/>
			</svg>
		</div>
	);

	return (
		<section id="home-hero">
			<div className="bg-illust-wrapper">
				<figure className="top-right-large-spill" />
				<figure className="left-bottom-small-spill" />
			</div>
			<Container fluid>
				<Row className="py-5 justify-content-center">
					<Col xs={10} md={6} className="my-4 text-center">
						<h1 className="hero-headline text-center" style={{ wordBreak: 'keep-all' }}>
							100% LTV & 1031 EXCHANGE
							<sup>
								<span className="landing-footnote">*</span>
							</sup>
							<span className="hero-headline-red">
								부동산 교환, 세금유예
							</span>
						</h1>
						{/* <span className="hero-subhead mt-1 retina-font">
							Submit Property
							<Bullseye />
							Wait for Investors
							<Bullseye />
							Get Funded
						</span> */}
						<p className="hero-description retina-font">
							<strong>1% 대출 수수료</strong> (When funded on our platform)
						</p>
						<div className="mt-3">
							<CenterStick />
						</div>
						<div className="mt-3 pr-0">
							<p className="hero-description retina-font">미국 공인 감정서 제공</p>
						</div>
						<Row className="button-wrapper mt-4 hero-btn-container p-3  col-12  justify-content-center">
							<Button className="primary-button no-deco mt-3 mr-md-2 hero-1-btn">
								<Link to="/kr/investors">
									<span className="button-inner-text text-no-hover">투자자</span>
								</Link>
							</Button>

							<Button className="primary-button no-deco mt-3 ml-md-2 hero-2-btn">
								<Link to="/kr/borrower">
									<span className="button-inner-text text-no-hover ">융자 신청자</span>
								</Link>
							</Button>
						</Row>
					</Col>
				</Row>
				<div className="jump-button-wrapper">
					<div className="jump-button text-center">
						<svg width="50" height="48" viewBox="0 0 50 48" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={e => jumpToSectionRef.current.scrollIntoView()}>
							<g filter="url(#filter0_d)">
								<path d="M5 3L25 23L45 3" stroke="black" strokeWidth="2" />
								<path d="M5 12.0566L25 32.0566L45 12.0566" stroke="#666666" strokeWidth="2" />
								<path d="M5 21.1132L25 41.1132L45 21.1132" stroke="#03946d" />
							</g>
							<defs>
								<filter id="filter0_d" x="0.292847" y="0.292908" width="49.4142" height="47.5274" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
									<feFlood floodOpacity="0" result="BackgroundImageFix" />
									<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
									<feOffset dy="2" />
									<feGaussianBlur stdDeviation="2" />
									<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
									<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
									<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
								</filter>
							</defs>
						</svg>
					</div>
				</div>
			</Container>
		</section>
	);
};

export default HeroV2;
