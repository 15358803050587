import React, { useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Layout from '../../components/kr/global/Layout';
import { Helmet } from 'react-helmet';
const KRFeeSchedulePage = props => {
	const currentUserData = props.user.data;

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	return (
		<Layout currentUserData={currentUserData} history={props.history}>
			<Helmet>
				<meta data-react-helmet="true" charSet="utf-8" />
				<title data-react-helmet="true">Fee Schedule - Yoonimoni</title>
				<meta name="description" content="" />
				<link rel="canonical" data-react-helmet="true" href="https://yoonimoni.com/kr/fee-schedule" />
				<meta data-react-helmet="true" property="og:locale" content="ko_KR" />
				<meta data-react-helmet="true" property="og:type" content="article" />
				<meta data-react-helmet="true" property="og:title" content="Fee Schedule - Yoonimoni" />
				<meta property="og:description" content="" />
				<meta data-react-helmet="true" property="og:url" content="https://yoonimoni.com/kr/fee-schedule" />
				<meta data-react-helmet="true" property="og:site_name" content="Yoonimoni" />
				<meta data-react-helmet="true" property="og:image" content="https://yoonimoni.com/og_yoonimoni.png" />
			</Helmet>
			<Container>
				<Grid container>
					<Grid item sm={12}>
						<p>Last Updated: Dec 18 2020</p>
						<h1>Fee Schedule</h1>
						<p>
							<strong>INVESTOR RETURN:</strong> Interest charges for the duration of the term of the loan.
						</p>

						<p>
							{/* <strong>BORROWERS:</strong> Minimum $3000 or 1% Gross Financing Fee */}
							<strong>BORROWERS:</strong> Minimum $3000 to up to 4 points
						</p>
						<p>
							<strong>CLOSING:</strong> Escrow Company and Title Company will handle funds, review the complete collateral file packages.
						</p>
						<p>
							<strong>GENERAL INFO:</strong> Yoonimoni is the premier web based platform for investors and borrowers of hard money loans to work directly together as
							their own agents and representatives. The marketplace is an efficient loan trading platform where investors can communicate directly and trade privately
							with the site guiding them through the seamless transaction process. The borrower will put together fully compliant disclosure packages for the loan
							transaction as the requested by the investor/lender. The borrowers must provide all critical documents and information. Yoonimoni verified the funding
							availability of investors to a certain degree, but it cannot be guaranteed.
						</p>
						<p>
							<strong>DISCLAIMER:</strong> Yoonimoni is not and has never been an agent, broker, associate, fiduciary, servicor or representative for either party in
							connection with any transaction on this site and cannot enforce nor confirm any obligations the parties they may have in performing, cooperating, or
							otherwise with the other party in the transactions contemplated on this site or through the site. Each hard money loan has significant information
							available from the borrower, but investors should always do their own due diligence and research. No fees shall be assessed unless outlined in the terms
							of use and terms & conditions.
						</p>
					</Grid>
				</Grid>
			</Container>
		</Layout>
	);
};

export default KRFeeSchedulePage;
