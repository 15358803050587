import React from 'react';
import MainNavbar from '../../components/nav/Navbar';
import Ribbon from '../../components/Home/Ribbon';
import Translation from '../Translation/Translation';

const Layout = props => {
	const { children, currentUserData } = props;
	return (
		<div className="d-flex">
			<section id="layout" className="w-100">
				{/* <Translation></Translation> */}
				<MainNavbar currentUserData={currentUserData} history={props.history}></MainNavbar>
				{children}
			</section>
		</div>
	);
};

export default Layout;
