import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Helmet } from 'react-helmet';
import ProgressBar from 'react-bootstrap/ProgressBar';
import { Container, Row, Col, Button, Alert, Modal } from 'react-bootstrap';
import Judgements from '../components/Borrowers/Judgements';
import SubjectPropertyAddr from '../components/Borrowers/SubjectPropertyAddr';
import Zoning from '../components/Borrowers/Zoning';
import Construction from '../components/Borrowers/Construction';
import MortgageBalance from '../components/Borrowers/MortgageBalance';
import LoanType from '../components/Borrowers/LoanType';
import BorrowingEntity from '../components/Borrowers/BorrowingEntity';
import PropertyType from '../components/Borrowers/PropertyType';
import Purchase from '../components/Borrowers/Purchase';
import FixFlip from '../components/Borrowers/FixFlip';
import Purpose from '../components/Borrowers/Purpose';
import ContactInformation from '../components/Borrowers/ContactInformation';
import LastStep from '../components/Borrowers/LastStep';
import Spinner from '../components/Spinner';
import Layout from '../components/global/Layout';

const BorrowersPage = props => {
	const currentUserData = props.user.data;
	const [onLoad, setOnLoad] = useState(false);
	const [currProgress, setCurrProgress] = useState(0); //current progress value
	const [currStep, setCurrStep] = useState(0);
	const [progressValue, setProgressValue] = useState(0);
	const [disabledNextBtn, setDisabledNextBtn] = useState(true);
	const [disabledSaveBtn, setDisabledSaveBtn] = useState(true);
	const [totalSteps, setTotalSteps] = useState(10);
	const [show, setShow] = useState(false);
	const [Warning, setWarning] = useState({
		message: '',
		type: '',
	});

	const emptyTemplate = {
		loanType: '',
		borrowingEntity: '',
		borrowingEntity_desc: '',
		typeOfProperty: '',
		typeOfProperty_desc: '',
		subjectPropertyAddr: {
			line1: '',
			line2: '',
			city: '',
			state: '',
			zip: '',
			country: 'United States',
			APN: '',
		},
		zoning: '',
		lotSize: '',
		landState: '',
		ownership: '',
		entitlementProcess: '',
		includingLandCost: '',
		landPurchase: '',
		landDownPayment: '',
		marketValueAfterCompletion: '',
		landPurchasedDate: '',
		landPurchasedPrice: '',
		landLoanBalance: '',
		description: '',
		occupancy: '',
		loanAmt: '',
		ltv: '',
		downPayment: '',
		sellerCarry: '',
		escrowOpened: '',
		fixUpCost: '',
		afterRemodelingValue: '',
		estTimeToFix: '',
		// finished edit above
		propertyRemodelFixingExp: '',
		howManyProperties: '',
		howManyProperties_when: '',
		purpose: '',
		reqLoanAmt: '',
		currentEstValue: '',
		interestRate: '',
		term: '',
		purchasedDate: '',
		purchasedPrice: '',
		mortgageBalance: {
			index: [
				{
					balanceAmt: '',
					lenderType: '',
					maturityDate: '',
					currentRate: '',
				},
				{
					balanceAmt: '',
					lenderType: '',
					maturityDate: '',
					currentRate: '',
				},
				{
					balanceAmt: '',
					lenderType: '',
					maturityDate: '',
					currentRate: '',
				},
			],
		},
		appraisalReport: '',
		appraisalValue: '',
		environmentalReport: '',
		judgementsOrLiensRecoreded: '',
		totalAmtRecoreded: '',
		dateRecorded: '',
		describeReason: '',
		paybackPlan: '',
		firstName: '',
		lastName: '',
		email: '',
		phoneNumber: '',
		officeMemo: '',
		images: [],
	};
	const [data, setData] = useState(emptyTemplate);
	/* console.log(data) */
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	/* useEffect(() => {
		let steps = 5;
		if (currentUserData && currentUserData._id) {
			axios.get(`/api/inquiry/get/draft/`).then(response => {
				if (response.data.success && response.data.draftedData) {
					setData({ ...response.data.draftedData });
					if (
						response.data.draftedData.loanType === 'purchase' ||
						response.data.draftedData.loanType === 'fix & flip/Rehab' ||
						response.data.draftedData.loanType === 'bridge/mezz'
					) {
						steps = 3;
					} else if (response.data.draftedData.loanType === 'construction/Project') {
						steps = 4;
					} else {
						steps = 5;
					}
					setProgressValue(100 / steps);
					setTotalSteps(steps);
					setShow(true);
				} else {
					steps = 5;
				}
			});
		}
	}, [currentUserData]); */

	/* const handleClose = () => setShow(false);

	const handleContinue = () => {
		setShow(false);
		setDisabledNextBtn(false);
		setDisabledSaveBtn(false);
	};

	const handleStartNew = async () => {
		return await axios.delete(`/api/inquiry/delete/draft/`).then(response => {
			setData(emptyTemplate);
			setShow(false);
		});
	}; */

	const loanTypeRadioHandle = (e, steps) => {
		const newValue = e.target.value;
		setProgressValue(100 / steps);
		setDisabledNextBtn(false);
		setDisabledSaveBtn(false);
		setTotalSteps(steps);
		setData({ ...data, loanType: newValue });
	};

	const radioHandler = (e, name) => {
		const newName = e?.target?.name ? e.target.name : name;
		const newValue = e?.target?.value ? e.target.value : typeof e === 'object' ? e.target.value : e;
		setData({ ...data, [newName]: newValue });
	};

	const propertyAddressHandler = e => {
		setData({ ...data, subjectPropertyAddr: { ...data.subjectPropertyAddr, [e.target.name]: e.target.value } });
	};

	const contactInputsHandle = e => {
		setData({ ...data, phoneNumber: e });
	};

	const handleNext = () => {
		setWarning({ message: '', type: '' });
		setCurrProgress(currProgress + progressValue);
		setCurrStep(currStep + 1);

		// setDisabledNextBtn(true)
		// setDisabledSaveBtn(true)
	};

	const handlePrevious = () => {
		setWarning({ message: '', type: '' });
		setCurrProgress(currProgress - progressValue);
		setCurrStep(currStep - 1);
		setDisabledNextBtn(false);
		setDisabledSaveBtn(false);
	};

	const handleSubmit = async e => {
		e.preventDefault();
		setOnLoad(true);
		let body = Object.assign({}, data);
		body['author'] = currentUserData?._id;
		body['status'] = 'pending';
		if (typeof body.phoneNumber === 'string') {
			body.phoneNumber = +body.phoneNumber;
		}
		axios.post('/api/inquiry/post/user/property', body).then(response => {
			if (response.data.success) {
				setOnLoad(false);
				props.history.push(`/completion/`);
			} else {
				setOnLoad(false);
				setWarning({ message: 'Failed to upload the inquiry.', type: 'danger' });
			}
		});
	};

	/* const handleSave = async e => {
		e.preventDefault();
		let body = Object.assign({}, data);
		body['author'] = currentUserData._id;
		body['status'] = 'save';
		if (typeof body.phoneNumber === 'string') {
			body.phoneNumber = +body.phoneNumber;
		}
		axios.post('/api/inquiry/post/user/property', body).then(response => {
			if (response.data.success && response.data.drafted) {
				setOnLoad(false);
				setWarning({ message: 'Saved successfully!', type: 'success' });
			} else {
				setOnLoad(false);
				setWarning({ message: 'Failed to save the data.', type: 'danger' });
			}
		});
	}; */

	const mortgageBalanceInputsHandle = (e, i, name) => {
		const newValue = e?.target?.value ? e.target.value : typeof e !== 'string' && typeof e !== 'number' ? '' : e;
		const newName = e?.target?.name ? e.target.name : typeof e !== 'string' && typeof e !== 'number' ? '' : name;
		setData(prevState => {
			/* console.log(typeof newValue) */

			prevState.mortgageBalance.index[i][newName] = newValue;
			return {
				...prevState,
			};
		});
	};

	return (
		<Layout currentUserData={currentUserData} history={props.history}>
			<Helmet>
				<meta data-react-helmet="true" charSet="utf-8" />
				<title data-react-helmet="true">Borrowers - Yoonimoni</title>
				<title
					data-react-helmet="true"
					property="og:description"
					content="Hard money lending made easy! At Yoonimoni, borrowers can submit their property on our page for loan inquiries. All you need is an estimated appraisal value, loan purpose, address, and you’re all done!"
				/>
				<link rel="canonical" data-react-helmet="true" href="https://yoonimoni.com/borrowers/" />
				<meta data-react-helmet="true" property="og:locale" content="en_US" />
				<meta data-react-helmet="true" property="og:type" content="article" />
				<meta data-react-helmet="true" property="og:title" content="Borrowers - Yoonimoni" />
				<title
					data-react-helmet="true"
					property="og:description"
					content="Hard money lending made easy! At Yoonimoni, borrowers can submit their property on our page for loan inquiries. All you need is an estimated appraisal value, loan purpose, address, and you’re all done!"
				/>
				<meta data-react-helmet="true" property="og:url" content="https://yoonimoni.com/borrowers/" />
				<meta data-react-helmet="true" property="og:site_name" content="Yoonimoni" />
				<meta data-react-helmet="true" property="og:image" content="https://yoonimoni.com/og_yoonimoni.png" />
			</Helmet>
			<ProgressBar now={currProgress} />
			{onLoad ? (
				<Spinner />
			) : (
				<Container id="borrower-form">
					<Row className="py-5 justify-content-center">
						<Col xs={12} className="pb-2">
							{Warning.message ? (
								<Alert className="form-input" variant={Warning.type}>
									{Warning.message}
								</Alert>
							) : (
								<Alert className="alert-placeholder" variant="light">
									&nbsp;
								</Alert>
							)}
						</Col>
						<Col xs={12} md={11} lg={10}>
							{/* 0 step */}
							{currStep === 0 ? <LoanType handle={loanTypeRadioHandle} data={data} /> : null}
							{currStep === 0 ? <BorrowingEntity handle={radioHandler} data={data} /> : null}

							{/* 1st step - global*/}
							{currStep === 1 && data.loanType !== 'construction/project' ? <PropertyType handle={radioHandler} data={data} /> : null}
							{currStep === 1 ? <SubjectPropertyAddr handle={propertyAddressHandler} data={data} /> : null}

							{currStep === 1 && data.loanType === 'construction/project' ? <Zoning data={data} handle={radioHandler} /> : null}
							{currStep === 2 && data.loanType === 'construction/project' ? <Construction data={data} handle={radioHandler} /> : null}

							{currStep === 1 && (data.loanType === 'purchase' || data.loanType === 'fix & flip/Rehab') ? <Purchase handle={radioHandler} data={data} /> : null}
							{currStep === 1 && data.loanType === 'fix & flip/Rehab' ? <FixFlip handle={radioHandler} data={data} /> : null}
							{/* 2nd step other than purchase & fix & flip/Rehab */}
							{currStep === 2 && (data.loanType === 'purchase' || data.loanType === 'fix & flip/Rehab') ? (
								<ContactInformation data={data} handle={radioHandler} handle2={contactInputsHandle} />
							) : null}
							{currStep === 3 && data.loanType === 'construction/project' ? (
								<ContactInformation data={data} handle={radioHandler} handle2={contactInputsHandle} />
							) : null}
							{currStep === 3 && data.loanType !== 'purchase' && data.loanType !== 'fix & flip/Rehab' && data.loanType !== 'construction/project' ? (
								<Judgements data={data} handle={radioHandler} />
							) : null}
							{currStep === 4 && data.loanType !== 'purchase' && data.loanType !== 'construction/project' ? (
								<ContactInformation data={data} handle={radioHandler} handle2={contactInputsHandle} />
							) : null}
							{currStep === 2 && data.loanType !== 'purchase' && data.loanType !== 'fix & flip/Rehab' && data.loanType !== 'construction/project' ? (
								<Purpose data={data} handle={radioHandler} />
							) : null}
							{currStep === 2 && data.loanType !== 'purchase' && data.loanType !== 'fix & flip/Rehab' && data.loanType !== 'construction/project' ? (
								<MortgageBalance handle={mortgageBalanceInputsHandle} data={data} number={3} />
							) : null}
							{/* working on the top */}
							{/* confirmData - last step */}
							{currStep === totalSteps ? <LastStep data={data}></LastStep> : null}
							<Row className="my-5">
								<Col className="text-center">
									<Button className="primary-button" onClick={handlePrevious} style={{ visibility: currStep === 0 ? `hidden` : `visible` }}>
										<span className="button-inner-text">PREVIOUS</span>
									</Button>
								</Col>
								<Col className="text-center">
									{/* <Button className="primary-button" onClick={handleSave} disabled={disabledSaveBtn}>
										<span className="button-inner-text">SAVE</span>
									</Button> */}
								</Col>
								<Col className="text-center">
									<Button className="primary-button" disabled={disabledNextBtn} onClick={currStep === totalSteps ? handleSubmit : handleNext}>
										<span className="button-inner-text">{currStep === totalSteps ? 'SUBMIT' : 'NEXT'}</span>
									</Button>
								</Col>
							</Row>
						</Col>
					</Row>
					{/* <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false} centered>
						<Modal.Header>
							<Modal.Title>We found your saved data!</Modal.Title>
						</Modal.Header>
						<Modal.Body>Do you want to continue?</Modal.Body>
						<Modal.Footer>
							<Button variant="primary-button" onClick={handleStartNew}>
								<span className="button-inner-text">Start a new form</span>
							</Button>
							<Button className="primary-button" onClick={handleContinue}>
								<span className="button-inner-text">Continue</span>
							</Button>
						</Modal.Footer>
					</Modal> */}
				</Container>
			)}
		</Layout>
	);
};
// }

export default BorrowersPage;
