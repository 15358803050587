import React from 'react';
import { Row, Col } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';

const LoanType = ({ handle, data }) => {
	const items = [
		{
			label: 'purchase',
			name: 'purchase',
			totalSteps: 3,
		},
		{
			label: 'refinance',
			name: 'refinance',
			totalSteps: 5,
		},
		{
			label: 'fix & flip/Rehab',
			name: 'fix & flip/Rehab',
			totalSteps: 3,
		},

		{
			label: '2nd',
			name: '2nd',
			totalSteps: 5,
		},
		{
			label: '3rd',
			name: '3rd',
			totalSteps: 5,
		},
		{
			label: 'construction/Project',
			name: 'construction/project',
			totalSteps: 4,
		},
		{
			label: 'bridge/mezz',
			name: 'bridge/mezz',
			totalSteps: 5,
		},
	];

	return (
		<Row>
			<Col sm={12}>
				<h3>
					Loan Type<sup className="asterisk">*</sup>
				</h3>
			</Col>
			{items.map((item, index) => {
				return (
					<Col key={index} sm={12}>
						<Form.Check
							inline
							checked={item.name === data.loanType ? true : false}
							label={item.label}
							value={item.name ? item.name : ''}
							type="radio"
							id={`inline-${item.name}`}
							className="d-block"
							onChange={e => handle(e, item.totalSteps)}
							name="typeOfLoanReq"
						/>
					</Col>
				);
			})}
		</Row>
	);
};

export default LoanType;
