import React from 'react';

export const RBStick = () => (
	<svg width="75" height="9" viewBox="0 0 75 9" fill="none" xmlns="http://www.w3.org/2000/svg">
		<rect width="50" height="3" fill="#03946d" />
		<rect y="6" width="75" height="3" fill="#303030" />
	</svg>
);

export const BRStick = () => (
	<svg width="50" height="9" viewBox="0 0 50 9" fill="none" xmlns="http://www.w3.org/2000/svg">
		<rect y="6" width="30" height="3" fill="#03946d" />
		<rect width="50" height="3" fill="#303030" />
	</svg>
);

export const CenterStick = () => (
	<svg width="50" height="9" viewBox="0 0 50 9" fill="none" xmlns="http://www.w3.org/2000/svg">
		<rect x="10" y="6" width="30" height="3" fill="#03946d" />
		<rect width="50" height="3" fill="#303030" />
	</svg>
);

export const Arrowhead = () => (
	<svg width="24" height="17" viewBox="0 0 24 17" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M1 8.5L7.15151 1.5L21.505 8.5L7.15151 15.5L1 8.5ZM1 8.5H13.9394" stroke="#666666" strokeWidth="1.5" />
	</svg>
);

export const OnelineStick = () => (
	<svg width="30" height="3" viewBox="0 0 30 3" fill="none" xmlns="http://www.w3.org/2000/svg">
		<rect width="30" height="3" fill="#03946d" />
	</svg>
);
