import React, { useState, useEffect } from 'react';
import Sidebar from './nav/Sidebar';
import DashboardNavbar from './nav/DashboardNavbar';
import { useLocation } from 'react-router-dom';
import useResize from '../utils/useResize';

const DashboardLayout = props => {
	const { children, currentUserData, history } = props;
	const [toggled, setToggled] = useState('');
	const { width } = useResize();

	const toggleHandler = () => {
		if (toggled === 'toggled') {
			setToggled('');
		} else {
			setToggled('toggled');
		}
	};

	useEffect(() => {
		if (width < 768) {
			setToggled('toggled');
		} else {
			setToggled('');
		}
	}, [width]);

	return (
		<div className="d-flex">
			<Sidebar toggleHandler={toggleHandler} toggled={toggled} currentUserData={currentUserData} history={history} currentUrl={useLocation().pathname} />
			<section id="dashboard">
				<DashboardNavbar toggled={toggled} toggleHandler={toggleHandler} currentUserData={currentUserData} history={history}></DashboardNavbar>
				{children}
			</section>
		</div>
	);
};

export default DashboardLayout;
