import React from 'react';
import { Row, Col } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';

const BorrowingEntity = ({ handle, data }) => {
	const items = [
		{
			name: 'individual',
		},
		{
			name: 'corporation',
		},
		{
			name: 'LLC',
		},
		{
			name: 'Trust',
		},
		{
			name: 'foreign',
		},
		{
			name: 'non-profit',
		},
		{
			name: 'other',
		},
	];
	return (
		<Row>
			<Col sm={12}>
				<h3>
					Borrowing Entity<sup className="asterisk">*</sup>
				</h3>
				{items.map((item, index) => {
					return (
						<Row key={index}>
							<Col sm={12}>
								<Form.Check
									key={item.name}
									inline
									checked={item.name === data.borrowingEntity ? true : false}
									label={item.name}
									value={item.name ? item.name : ''}
									type="radio"
									id={`inline-${item.name}`}
									className="d-block"
									onChange={handle}
									name="borrowingEntity"
								/>
							</Col>
						</Row>
					);
				})}
			</Col>
		</Row>
	);
};

export default BorrowingEntity;
