import React from 'react';
import { Row, Col } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import CurrencyInput from 'react-currency-input-field';
import { calculateLTV } from '../utils/functions';

const FixFlip = ({ handle, data }) => {
	return (
		<Row>
			<Col sm={12} md={4} lg={4}>
				<Form.Group>
					<Form.Label>fix up cost</Form.Label>
					<CurrencyInput
						name={'fixUpCost'}
						decimalsLimit={2}
						allowNegativeValue={false}
						maxLength={10}
						prefix="$"
						className="form-control"
						onValueChange={(value, name) => handle(value, name)}
						defaultValue={data.fixUpCost ? data.fixUpCost : ''}
					/>
				</Form.Group>
			</Col>
			<Col sm={12} md={4} lg={4}></Col>
			<Col sm={12} md={4} lg={4}></Col>
			<Col sm={12} md={4} lg={4}>
				<Form.Group>
					<Form.Label>total loan amount</Form.Label>
					<CurrencyInput
						name={'totalLoanAmt'}
						decimalsLimit={2}
						allowNegativeValue={false}
						maxLength={10}
						prefix="$"
						className="form-control"
						disabled
						value={data.fixUpCost && data.loanAmt ? +data.fixUpCost + +data.loanAmt : data.loanAmt ? +data.loanAmt : data.fixUpCost ? +data.fixUpCost : 0}
					/>
				</Form.Group>
			</Col>
			<Col sm={12} md={4} lg={4}>
				<Form.Group>
					<Form.Label>after remodeling value</Form.Label>
					<CurrencyInput
						name={'afterRemodelingValue'}
						decimalsLimit={2}
						allowNegativeValue={false}
						maxLength={10}
						prefix="$"
						className="form-control"
						onValueChange={(value, name) => handle(value, name)}
						defaultValue={data.afterRemodelingValue ? data.afterRemodelingValue : ''}
					/>
				</Form.Group>
			</Col>
			<Col sm={12} md={4} lg={4}>
				<Form.Group>
					<Form.Label>ARV</Form.Label>
					<InputGroup>
						{/* {console.log(data)} */}
						<Form.Control value={calculateLTV(data).ARV.toFixed(2)} type={'number'} name={'ARV'} disabled></Form.Control>
						<InputGroup.Append>
							<InputGroup.Text id="basic-addon2">%</InputGroup.Text>
						</InputGroup.Append>
					</InputGroup>
				</Form.Group>
			</Col>
			<Col sm={12} md={4} lg={4}>
				<Form.Group>
					<Form.Label>Estimated time to fix</Form.Label>
					<InputGroup>
						{/* {console.log(data.estTimeToFix)} */}
						<Form.Control value={data.estTimeToFix ? data.estTimeToFix : ''} type={'text'} name={'estTimeToFix'} onChange={handle}></Form.Control>
					</InputGroup>
				</Form.Group>
			</Col>
			<Col sm={12} md={4} lg={4}></Col>
			<Col sm={12} md={4} lg={4}></Col>
			<Col sm={12} md={4} lg={4}>
				<Form.Group>
					<Form.Label>Property Remodel/Fixing Experience</Form.Label>
					<Form.Group className="d-flex mt-10px radioBtns">
						<Form.Check
							type="radio"
							label="yes"
							value="true"
							checked={data.propertyRemodelFixingExp === 'true' ? true : false}
							onChange={handle}
							name={'propertyRemodelFixingExp'}
							className="mr-5 my-auto"
						/>
						<Form.Check
							type="radio"
							label="no"
							value="false"
							checked={data.propertyRemodelFixingExp === 'false' ? true : false}
							onChange={handle}
							name={'propertyRemodelFixingExp'}
							className="mr-5 my-auto"
						/>
					</Form.Group>
				</Form.Group>
			</Col>
			{!data.propertyRemodelFixingExp || data.propertyRemodelFixingExp === 'false' ? (
				''
			) : (
				<>
					<Col sm={12} md={4} lg={4}>
						<Form.Group>
							<Form.Label>How many properties?</Form.Label>
							<InputGroup>
								<Form.Control
									value={data.howManyProperties ? data.howManyProperties : ''}
									type={'number'}
									name={'howManyProperties'}
									onChange={handle}
								></Form.Control>
							</InputGroup>
						</Form.Group>
					</Col>
					<Col sm={12} md={4} lg={4}>
						<Form.Group>
							<Form.Label>When?</Form.Label>
							<InputGroup>
								<Form.Control
									value={data.howManyProperties_when ? data.howManyProperties_when : ''}
									type={'string'}
									name={'howManyProperties_when'}
									onChange={handle}
								></Form.Control>
							</InputGroup>
						</Form.Group>
					</Col>
				</>
			)}
		</Row>
	);
};

export default FixFlip;
