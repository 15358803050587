import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Maps from '../../components/SingleItem/Maps';
import Gallery from '../../components/SingleItem/Gallery';
import MainInfo from '../../components/SingleItem/MainInfo';
import SideInfo from '../../components/SingleItem/SideInfo';
import MorgageInfo from '../../components/SingleItem/MorgageInfo';
import PropertyDescription from '../../components/SingleItem/PropertyDescription';
import Spinner from '../../components/Spinner';
import Layout from '../../components/kr/global/Layout';
import axios from 'axios';

const KRSingleItemPage = props => {
	const currentUserData = props.user.data;
	const [propertyInfo, setPropertyInfo] = useState({});
	const postId = props.match.params.id;

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	useEffect(() => {
		axios.get(`/api/inquiry/get/singleproperty/${postId}`).then(res => {
			if (res.data && res.data.success) {
				setPropertyInfo(res.data.property);
			} else {
				alert('Failed to fetch property datas');
			}
		});
	}, [postId]);

	return (
		<Layout currentUserData={currentUserData} history={props.history}>
			<section id="single-item">
				<div className="bg-illust-wrapper">
					<figure className="top-spill" />
				</div>
				<Container>
					{propertyInfo && propertyInfo._id ? (
						<Row className="mt-5">
							<Col sm={12} lg={8}>
								{/* gallery */}
								<Gallery propertyInfo={propertyInfo} />
								{/* Information  */}
								<MainInfo propertyInfo={propertyInfo} />
								{/* Morgage Information */}
								{propertyInfo.loanType === 'refinance' ? <MorgageInfo propertyInfo={propertyInfo} /> : null}
								{propertyInfo.loanType === '2nd' ? <MorgageInfo propertyInfo={propertyInfo} /> : null}
								{propertyInfo.loanType === '3rd' ? <MorgageInfo propertyInfo={propertyInfo} /> : null}
								{propertyInfo.loanType === 'bridge/mezz' ? <MorgageInfo propertyInfo={propertyInfo} /> : null}

								{/* Description */}
								{propertyInfo && (propertyInfo.loandescription || propertyInfo.paybackPlan) ? (
									<PropertyDescription propertyInfo={propertyInfo} />
								) : (
									<div className="my-5"></div>
								)}
								{/* Map */}
								{propertyInfo.subjectPropertyAddr && propertyInfo.subjectPropertyAddr.location ? (
									<Maps propertyInfo={propertyInfo} />
								) : (
									<div className="py-3"></div>
								)}
							</Col>
							<Col sm={12} lg={4}>
								<div className="bg-illust-wrapper">
									<figure className="bottom-right-waffle" />
								</div>
								<SideInfo propertyInfo={propertyInfo} currentUserData={currentUserData} />
							</Col>
						</Row>
					) : (
						<Spinner />
					)}
				</Container>
			</section>
		</Layout>
	);
};

export default KRSingleItemPage;
