import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { BRStick } from '../global/svgs';
import { Link } from 'react-router-dom';

const AboutUs = ({ jumpToSectionRef }) => {
	return (
		<section id="home-about-us" ref={jumpToSectionRef}>
			<Container className="py-5">
				<Row className="pt-5">
					<Col xs={12} md={6} className="my-4 overflow-hidden d-flex align-items-center">
						<img alt="piggy bank illust" width="100%" height="auto" src="/banner/home-illust-setting-new-standard.png"></img>
					</Col>
					<Col xs={12} md={6} className="my-4">
						<h2 className="about-us-headline">About Us</h2>
						<span className="about-us-subhead">Your Preferred Lender</span>
						<div className="mt-2">
							<BRStick />
						</div>
						<div className="mt-3 pr-3 retina-font">
							<p>
								Yoonimoni is an expert in both <strong>Residential</strong> and <strong>Commercial</strong> real estate lending through its vast networks of investors
								and funding sources. Our experience, readiness, flexibility, and capability to meet your needs makes us your preferred lender.
							</p>
							<p>
								When you contact Yoonimoni, your requests and needs will be carefully listened to, and reviewed by industry expert with years of experiences and best
								possible solution will be presented to you.
							</p>
						</div>
						<div className="button-wrapper mt-4 row justify-content-center justify-content-sm-start p-3">
							<Link to="/about-us">
								<Button className="primary-button  mr-3 no-deco">
									<span className="button-inner-text">Read More</span>
								</Button>
							</Link>
						</div>
					</Col>
				</Row>
			</Container>
		</section>
	);
};

export default AboutUs;
