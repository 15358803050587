import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Layout from '../../components/kr/global/Layout';
import { Container, Row, Col, Button, Form, Alert, Modal, ButtonGroup } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import PhoneInput from 'react-phone-input-2';
import axios from 'axios';
import CurrencyInput from 'react-currency-input-field';

const KRBorrower = props => {
	const currentUserData = props.user.data;
	const [modalShow, setModalShow] = useState(false);
	const [Contact, setContact] = useState({
		address: '',
		firstName: currentUserData && currentUserData.firstName ? currentUserData.firstName : '',
		lastName: currentUserData && currentUserData.lastName ? currentUserData.lastName : '',
		email: currentUserData && currentUserData.email ? currentUserData.email : '',
		preferredContact: '',
		phoneNumber: '',
		loanAmount: '',
		appraisalValue: '',
		message: '',
	});
	const [alert, setAlert] = useState({
		status: '',
		message: '',
	});
	const [submitProcess, setSubmitProcess] = useState(false);

	const onContactHandler = e => {
		const { name, value } = e.target;
		setContact({ ...Contact, [name]: value });
	};

	const currencyHandler = (e, name) => {
		const newName = e?.target?.name ? e.target.name : name;
		const newValue = e?.target?.value ? e.target.value : typeof e === 'object' ? e.target.value : e;
		setContact({ ...Contact, [newName]: newValue });
	};

	const phoneNumberHandler = e => {
		setContact({ ...Contact, phoneNumber: e });
	};

	const onSubmitHandler = async e => {
		e.preventDefault();
		setSubmitProcess(true);
		setAlert({ status: 'secondary', message: 'Sending a message...' });
		let body = Object.assign({}, Contact);
		axios.post('/api/mail/borrowercontact', body).then(res => {
			/* console.log(res); */
			if (res.data.success) {
				setAlert({ status: 'success', message: 'We will be contacting you shortly!' });
			} else {
				// alert('Failed to send message!');
				setAlert({ status: 'warning', message: 'Failed to send message!' });
				setSubmitProcess(false);
			}
		});
	};
	return (
		<Layout currentUserData={currentUserData} history={props.history}>
			<Helmet>
				<meta data-react-helmet="true" charSet="utf-8" />
				<title data-react-helmet="true">Privacy Policy - Yoonimoni</title>
				<meta name="description" content="Privacy Policy" />
				<link rel="canonical" data-react-helmet="true" href="https://yoonimoni.com/kr/privacy-policy" />
				<meta data-react-helmet="true" property="og:locale" content="ko_KR" />
				<meta data-react-helmet="true" property="og:type" content="article" />
				<meta data-react-helmet="true" property="og:title" content="Privacy Policy - Yoonimoni" />
				<meta property="og:description" content="Privacy Policy" />
				<meta data-react-helmet="true" property="og:url" content="https://yoonimoni.com/kr/privacy-policy" />
				<meta data-react-helmet="true" property="og:site_name" content="Yoonimoni" />
				<meta data-react-helmet="true" property="og:image" content="https://yoonimoni.com/og_yoonimoni.png" />
			</Helmet>
			<Container style={{ minHeight: '50vh' }} className="d-flex is-content-justification-center m-auto">
				<Row className="m-auto w-100 text-center">
					<Col>
						<Row className="mb-4">
							<Col>
								<h1>투자및 융자신청</h1>
							</Col>
						</Row>
						<Row>
							<Col>
								<Button className="primary-button" onClick={() => setModalShow(true)}>
									작성 도움 받기
								</Button>
								<span className="d-block mt-4">
									저희 에이전트와 대화하고 싶습니까? <br />
									업무시간 기준 24시간 이내에 전문 에이전트가 연락을 드립니다.
								</span>
								<Modal show={modalShow} onHide={() => setModalShow(false)} centered id="borrower-contact-form">
									<Modal.Header closeButton>
										<Modal.Title>Need help with filling out the form?</Modal.Title>
									</Modal.Header>
									<Modal.Body>
										<Container className="bg-white px-0">
											<form onSubmit={onSubmitHandler}>
												<Row>
													<Col xs={12} className="mb-3">
														{alert.message ? <Alert variant={alert.status}>{alert.message}</Alert> : <></>}
													</Col>
													<Col xs={12} className="pr-sm-2 pl-sm-3 px-3 mb-3">
														<Form.Control
															required
															type="text"
															name="address"
															value={Contact.address}
															onChange={onContactHandler}
															placeholder="Address"
														/>
													</Col>
													<Col xs={12} sm={6} className="pr-sm-2 pl-sm-3 px-3 mb-3">
														<CurrencyInput
															name={'loanAmt'}
															decimalsLimit={2}
															allowNegativeValue={false}
															maxLength={10}
															prefix="$"
															className="form-control"
															onValueChange={(value, name) => currencyHandler(value, name)}
															defaultValue={Contact.loanAmount}
															placeholder="Loan Amount"
														/>
													</Col>
													<Col xs={12} sm={6} className="pl-sm-2 pr-sm-3 px-3 mb-3">
														<CurrencyInput
															name="appraisalValue"
															decimalsLimit={2}
															allowNegativeValue={false}
															maxLength={10}
															prefix="$"
															className="form-control"
															onValueChange={(value, name) => currencyHandler(value, name)}
															defaultValue={Contact.appraisalValue}
															placeholder="Estimated Appraisal Value"
														/>
													</Col>
													<Col xs={12} sm={6} className="pr-sm-2 pl-sm-3 px-3 mb-3">
														<Form.Control
															required
															type="text"
															name="firstName"
															value={Contact.firstName}
															onChange={onContactHandler}
															placeholder="First Name"
														/>
													</Col>
													<Col xs={12} sm={6} className="pl-sm-2 pr-sm-3 px-3 mb-3">
														<Form.Control
															required
															type="text"
															name="lastName"
															value={Contact.lastName}
															onChange={onContactHandler}
															placeholder="Last Name"
														/>
													</Col>
													<Col xs={12} className="px-3 mb-3">
														<Form.Control
															required
															type="email"
															name="email"
															autoComplete="email"
															value={Contact.email}
															onChange={onContactHandler}
															placeholder="Email Address"
														/>
													</Col>
													<Col xs={12} className="px-3 mb-3">
														<PhoneInput
															specialLabel={false}
															country="us"
															onlyCountries={['us']}
															disableDropdown
															disableCountryCode
															value={Contact?.phoneNumber ? Contact.phoneNumber.toString() : ''}
															placeholder={'Phone Number'}
															name="phoneNumber"
															onChange={phoneNumberHandler}
														/>
													</Col>

													<Col xs={12} className="px-3 mb-3">
														<Row className="ml-1">
															<Col className="p-0 m-auto">
																<span>Preferred&nbsp;Contact:</span>
																<Form.Check
																	inline
																	label="E-mail"
																	name="preferredContact"
																	type="radio"
																	value="email"
																	onChange={onContactHandler}
																	id="contact-pref-email"
																	className="d-none"
																/>
																<Form.Check
																	inline
																	label="Phone"
																	name="preferredContact"
																	type="radio"
																	value="phone"
																	onChange={onContactHandler}
																	id="contact-pref-phone"
																	className="d-none"
																/>
																<ButtonGroup aria-label="Preferred Contact" className="ml-4">
																	<Button
																		className={Contact.preferredContact === 'email' ? 'radio-button radio-button-selected' : 'radio-button'}
																		onClick={e => document.querySelector('#contact-pref-email').click()}
																	>
																		E-Mail
																	</Button>
																	<Button
																		className={Contact.preferredContact === 'phone' ? 'radio-button radio-button-selected' : 'radio-button'}
																		onClick={e => document.querySelector('#contact-pref-phone').click()}
																	>
																		Phone
																	</Button>
																</ButtonGroup>
															</Col>
														</Row>
													</Col>
													<Col xs={12} className="px-3 mb-3">
														<Form.Control
															required
															name="message"
															value={Contact.message}
															onChange={onContactHandler}
															as="textarea"
															rows="5"
															style={{ resize: 'none' }}
															placeholder="Your Message"
														/>
													</Col>
												</Row>

												<div className="button-wrapper mt-2">
													<Button type="submit" className="primary-button  mr-3" disabled={submitProcess}>
														<span className="button-inner-text">Send Message</span>
													</Button>
												</div>
											</form>
										</Container>
									</Modal.Body>
								</Modal>
							</Col>
							<Col>
								<Link to={'/kr/borrowers'}>
									<Button className="primary-button">자체 작성 하기</Button>
								</Link>
								<span className="d-block mt-4">
									Want to do it on your own? <br />
									Please fill out the form with as much information as possible.
								</span>
							</Col>
						</Row>
					</Col>
				</Row>
			</Container>
		</Layout>
	);
};

export default KRBorrower;
