import React from 'react';
import { calculateLTV, priceTag, trimAddress } from '../utils/functions';

const Chart = ({ obj }) => {
	return (
		<div className="info-table mt-4">
			<div className="info-left">
				<ul>
					{Object.keys(obj).map((name, index) => {
						if (obj[name]?.length > 0) {
							return (
								<li key={index} className="overflow-ellipsis text-capitalize ">
									{name}
								</li>
							);
						}
					})}
				</ul>
			</div>
			<div className="info-right">
				<ul>
					{Object.keys(obj).map((name, index) => {
						if (obj[name]?.length > 0) {
							return (
								<li key={index} className="text-capitalize overflow-ellipsis">
									<span className="text-left pl-3 w-100">{obj[name]}</span>
								</li>
							);
						}
					})}
				</ul>
			</div>
		</div>
	);
};

const MainInfo = props => {
	const p = props.propertyInfo;

	const all = {
		'Property Type': p.typeOfProperty,
		Address: `${p.subjectPropertyAddr.city + ' ' + p.subjectPropertyAddr.state?.toUpperCase() + ' ' + p.subjectPropertyAddr.country}`,
		Zoning: p.zoning,
		'Lot Size': p.lotSize,
		'Land State': `${p.landState === 'improvedLand' ? 'Improved Land' : ''}${p.landState === 'rawLand' ? 'Raw Land' : ''}`,
		Ownersihp: `${p.ownership === 'feeSimple' ? 'Fee Simple' : ''}${p.ownership === 'groundLease' ? 'Ground Lease' : ''}`,
		'Entitlement Process Completed': `${p.entitlementProcess ? p.entitlementProcess : ''}`,
		'Including Land Cost': `${p.includingLandCost === 'true' ? 'Yes' : ''}${p.includingLandCost === 'false' ? 'No' : ''}`,
		'Current Estimated Appraisal Value': `${p.currentEstValue ? priceTag(p.currentEstValue) : ''} `,
		[p.loanType === 'fix & flip/Rehab' ? 'Total Loan Amount:' : 'Loan Amount:']: p.loanType === 'fix & flip/Rehab' ? priceTag(p.loanAmt + p.fixUpCost) : priceTag(p.loanAmt),
		'Loan to value(LTV)': `${p.loanType === 'fix & flip/Rehab'
			? calculateLTV(p).LTV > 100 || isNaN(calculateLTV(p).LTV) || calculateLTV(p).LTV === 0
				? '-'
				: calculateLTV(p).LTV.toFixed(2)
			: calculateLTV(p) > 100 || isNaN(calculateLTV(p)) || calculateLTV(p) === 0
				? '-'
				: calculateLTV(p).toFixed(2)
			}%`,
		'Interest Rate Offered': `${p.interestRate ? p.interestRate + '%' : ''}`,
		'Length of Term': `${p.term ? p.term : ''}`,
		'Purchase Price': priceTag(p.purchasedPrice),
		'Down Payment': priceTag(p.downPayment),
		'Seller Carry': priceTag(p.sellerCarry),
		'Recent Appraisal': `${p.appraisalReport === 'true' ? 'Yes' : ''}${p.appraisalReport === 'false' ? 'No' : ''}`,
		'Recent Environmental Report': `${p.environmentalReport === 'true' ? 'Yes' : ''}${p.environmentalReport === 'false' ? 'No' : ''}`,
		'Judgements, Liens, NOD, NTS': `${p.judgementsOrLiensRecoreded ? p.judgementsOrLiensRecoreded + ', ' + priceTag(p.totalAmtRecoreded) + ' on ' + p.dateRecorded : ''}`,
		'Escrow Opend?': `${p.escrowOpened === 'true' ? 'Yes' : ''}${p.escrowOpened === 'false' ? 'No' : ''}`,
		'Fix Up Cost': priceTag(p.fixUpCost),
		'After Remodeling Value': priceTag(p.afterRemodelingValue),
		'ARV:': `${p.loanAmt && p.fixUpCost && !isNaN(calculateLTV(p)) ? calculateLTV(p).ARV.toFixed(2) + '%' : ''}`,
		'Estimated Time To Fix': p.estTimeToFix,
	};

	return (
		<div id="mainInfo">
			<section className="info mt-4">
				<span className="info-type text-capitalize">{p.loanType} Loan</span>, <span className="info-city">{p.borrowingEntity ? p.borrowingEntity : ''}</span>
				{p.subjectPropertyAddr.line1 ? <h3 className="info-address">{p.status === 'complete' ? p.subjectPropertyAddr.line1 : trimAddress(p.subjectPropertyAddr)}</h3> : 'null'}
			</section>
			<Chart obj={all} />
		</div>
	);
};

export default MainInfo;
