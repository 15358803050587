import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Redirect, Link } from 'react-router-dom';
import clsx from 'clsx';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import DeleteIcon from '@material-ui/icons/Delete';
import FilterListIcon from '@material-ui/icons/FilterList';
import Spinner from '../../components/Spinner';
import { Helmet } from 'react-helmet';
import { getComparator, stableSort, useToolbarStyles } from '../../components/board/utils';
import { Container, Row, Col, Button } from 'react-bootstrap';
// id, email, firstName, lastName, role
import DashboardLayout from '../../components/dashboard/DashboardLayout';

const headCells = [
	{
		id: 'id',
		align: 'left',
		disablePadding: false,
		label: 'ID',
	},
	{
		id: 'email',
		align: 'left',
		disablePadding: false,
		label: 'Email Address',
	},
	{
		id: 'firstName',
		align: 'left',
		disablePadding: false,
		label: 'First Name',
	},
	{
		id: 'lastName',
		align: 'left',
		disablePadding: false,
		label: 'Last Name',
	},
	{
		id: 'role',
		align: 'left',
		disablePadding: false,
		label: 'Role',
	},
];

const userRoles = ['all', 'subscriber', 'admin', 'borrower', 'investor'];

const UsersPageHead = props => {
	const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
	const createSortHandler = property => event => {
		onRequestSort(event, property);
	};

	return (
		<TableHead>
			<TableRow>
				<TableCell padding="checkbox" className="checkbox-white">
					<Checkbox
						indeterminate={numSelected > 0 && numSelected < rowCount}
						checked={rowCount > 0 && numSelected === rowCount}
						onChange={onSelectAllClick}
						inputProps={{
							'aria-label': 'select all desserts',
						}}
					/>
				</TableCell>
				{headCells.map(headCell => (
					<TableCell
						key={headCell.id}
						align={headCell.align}
						padding={headCell.disablePadding ? 'none' : 'default'}
						sortDirection={orderBy === headCell.id ? order : false}
					>
						<TableSortLabel active={orderBy === headCell.id} direction={orderBy === headCell.id ? order : 'asc'} onClick={createSortHandler(headCell.id)}>
							<span className="white-font-color">{headCell.label}</span>
							{orderBy === headCell.id ? <span className="visuallyHidden">{order === 'desc' ? 'sorted descending' : 'sorted ascending'}</span> : null}
						</TableSortLabel>
					</TableCell>
				))}
			</TableRow>
		</TableHead>
	);
};

const UsersPageToolbar = props => {
	const classes = useToolbarStyles();
	const { numSelected, handleDelete, selected, fetch } = props;

	return (
		<Toolbar
			className={`${clsx({
				[classes.highlight]: numSelected > 0,
			})} + toolbar`}
		>
			{numSelected > 0 ? (
				<Typography className={classes.title} color="inherit" variant="subtitle1" component="div">
					{numSelected} selected
				</Typography>
			) : (
				<span className="d-block font-weight-500 w-100">Users</span>
			)}
			{/* <Tooltip title="Add new request">
				<Link to="/dashboard/addNewRequest">
					<IconButton aria-label="Add new request">
						<PostAddIcon />
					</IconButton>
				</Link>
			</Tooltip> */}
			{/* USEROLE BUTTONS */}
			{userRoles.map(userRole => (
				<Button onClick={e => fetch(userRole)} key={userRole} className="primary-button mr-1 p-2">
					{userRole}
				</Button>
			))}

			{numSelected > 0 ? (
				<Tooltip title="Delete">
					<IconButton aria-label="delete">
						<DeleteIcon onClick={handleDelete} />
					</IconButton>
				</Tooltip>
			) : (
				<Tooltip title="Filter list">
					<IconButton aria-label="filter list">
						<FilterListIcon />
					</IconButton>
				</Tooltip>
			)}
		</Toolbar>
	);
};

const UsersPage = props => {
	const [order, setOrder] = useState('asc');
	const [orderBy, setOrderBy] = useState('calories');
	const [selected, setSelected] = useState([]);
	const [page, setPage] = useState(0);
	const [dense, setDense] = useState(true);
	const [rowsPerPage, setRowsPerPage] = useState(20);
	const currentUserData = props.user.data;
	const [rows, setRows] = useState([]);

	const fetch = async userRole => {
		return await axios.get(`/api/users/users/${userRole}`).then(res => {
			if (res.data.success) {
				setRows(
					res.data.users
						.map((user, i) => ({
							id: i + 1,
							email: user.email,
							firstName: user.firstName,
							lastName: user.lastName,
							role: user.role,
							uid: user._id,
						}))
						.reverse()
				);
			} else {
				alert('Failed to fetch property datas');
			}
		});
	};

	useEffect(() => {
		if (currentUserData && currentUserData._id) {
			fetch('all');
		}
	}, [currentUserData]);

	// id, email, firstName, lastName, role

	const handleRequestSort = (event, property) => {
		const isAsc = orderBy === property && order === 'asc';
		setOrder(isAsc ? 'desc' : 'asc');
		setOrderBy(property);
	};

	const handleDelete = e => {
		e.preventDefault();
		const list = selected.map(index => {
			return rows[index].email;
		});
		console.log(list);
		const queryObj = { data: { list } };
		axios.delete('/api/users/delete/users/', queryObj).then(response => {
			if (response.data.success) {
				window.location.reload();
			} else {
				alert('Failed to delete property');
			}
		});
	};

	const handleSelectAllClick = event => {
		if (event.target.checked) {
			const newSelecteds = rows.map((n, index) => index);
			setSelected(newSelecteds);
			return;
		}
		setSelected([]);
	};

	const handleClick = (event, index) => {
		const selectedIndex = selected.indexOf(index);
		let newSelected = [];

		if (selectedIndex === -1) {
			newSelected = newSelected.concat(selected, index);
		} else if (selectedIndex === 0) {
			newSelected = newSelected.concat(selected.slice(1));
		} else if (selectedIndex === selected.length - 1) {
			newSelected = newSelected.concat(selected.slice(0, -1));
		} else if (selectedIndex > 0) {
			newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
		}
		setSelected(newSelected);
	};

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = event => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	const handleChangeDense = event => {
		setDense(event.target.checked);
	};

	const isSelected = index => selected.indexOf(index) !== -1;

	const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

	if (currentUserData === null) {
		return <Spinner />;
	} else if (currentUserData && currentUserData.isAuth && currentUserData.role !== 'admin') {
		return <Redirect to="/"></Redirect>;
	} else {
		return (
			<DashboardLayout currentUserData={currentUserData} history={props.history}>
				<Helmet>
					<meta data-react-helmet="true" charSet="utf-8" />
					<title data-react-helmet="true">My Requests - Yoonimoni</title>
					<meta data-react-helmet="true" name="description" content="" />
					<link rel="canonical" data-react-helmet="true" href="https://yoonimoni.com/my-inquiries" />
					<meta data-react-helmet="true" property="og:locale" content="en_US" />
					<meta data-react-helmet="true" property="og:type" content="article" />
					<meta data-react-helmet="true" property="og:title" content="My Requests - Yoonimoni" />
					<meta data-react-helmet="true" property="og:description" content="" />
					<meta data-react-helmet="true" property="og:url" content="https://yoonimoni.com/my-inquiries" />
					<meta data-react-helmet="true" property="og:site_name" content="Yoonimoni" />
					<meta data-react-helmet="true" property="og:image" content="https://yoonimoni.com/og_yoonimoni.png" />
				</Helmet>
				<Container fluid className="bg-white m-26px p-0" id="dashboard-board">
					<Row className="p-0 m-0 ">
						<Col xs={12} className="px-20px">
							<UsersPageToolbar fetch={fetch} numSelected={selected.length} selected={selected} handleDelete={handleDelete} />
							<TableContainer>
								<Table aria-labelledby="tableTitle" size={dense ? 'small' : 'medium'} aria-label="enhanced table">
									<UsersPageHead
										numSelected={selected.length}
										order={order}
										orderBy={orderBy}
										onSelectAllClick={handleSelectAllClick}
										onRequestSort={handleRequestSort}
										rowCount={rows.length}
									/>
									<TableBody>
										{stableSort(rows, getComparator(order, orderBy))
											.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
											.map((row, index) => {
												const isItemSelected = isSelected(index);
												const labelId = `enhanced-table-checkbox-${index}`;

												return (
													<TableRow
														hover
														role="checkbox"
														aria-checked={isItemSelected}
														tabIndex={-1}
														key={row.id}
														selected={isItemSelected}
														className={+index % 2 ? 'bg-light-dark' : 'bg-body-back'}
													>
														<TableCell padding="checkbox">
															<Checkbox
																checked={isItemSelected}
																inputProps={{
																	'aria-labelledby': labelId,
																}}
																onClick={event => handleClick(event, index)}
															/>
														</TableCell>
														<TableCell align="left" id={labelId} scope="row">
															<Link className="no-deco" to={`/dashboard/user/${row.uid}`}>
																{row.id}
															</Link>
														</TableCell>
														<TableCell align="left">
															<Link className="no-deco" to={`/dashboard/user/${row.uid}`}>
																{row.email}
															</Link>
														</TableCell>
														<TableCell align="left">
															<Link className="no-deco" to={`/dashboard/user/${row.uid}`}>
																{row.firstName}
															</Link>
														</TableCell>
														<TableCell align="left">
															<Link className="no-deco" to={`/dashboard/user/${row.uid}`}>
																{row.lastName}
															</Link>
														</TableCell>
														<TableCell align="left">
															<Link className="no-deco" to={`/dashboard/user/${row.uid}`}>
																{row.role}
															</Link>
														</TableCell>
													</TableRow>
												);
											})}
										{emptyRows > 0 && (
											<TableRow
												style={{
													height: (dense ? 33 : 53) * emptyRows,
												}}
											>
												<TableCell colSpan={6} />
											</TableRow>
										)}
									</TableBody>
								</Table>
							</TableContainer>
							<TablePagination
								rowsPerPageOptions={[10, 20, 50]}
								component="div"
								count={rows.length}
								rowsPerPage={rowsPerPage}
								page={page}
								onPageChange={handleChangePage}
								onRowsPerPageChange={handleChangeRowsPerPage}
							/>
							<FormControlLabel control={<Switch checked={dense} onChange={handleChangeDense} />} label="Dense padding" />
						</Col>
					</Row>
				</Container>
			</DashboardLayout>
		);
	}
};
export default UsersPage;
