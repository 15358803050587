import React, { useEffect } from 'react';
import { Container, Row, Col, Card, CardColumns } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Masonry } from 'masonic';
import Spinner from '../../components/Spinner';
import imagesLoaded from 'imagesloaded';

const BlogCards = ({ posts }) => {
	const stripHTMLtag = str => {
		const tmp = document.createElement('DIV');
		tmp.innerHTML = str;
		return tmp.textContent || tmp.innerText || '';
	};

	const formatDate = date => {
		const postDate = new Date(date);
		const Months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];
		return `${Months[postDate.getMonth()]} ${postDate.getDate()}, ${postDate.getFullYear()}`;
	};

	const resizeMasonryItem = item => {
		const grid = document.getElementsByClassName('masonry')[0];
		if (grid) {
			const rowGap = parseInt(window.getComputedStyle(grid).getPropertyValue('grid-row-gap')),
				rowHeight = parseInt(window.getComputedStyle(grid).getPropertyValue('grid-auto-rows')),
				gridImagesAsContent = item.querySelector('.card.masonry-content');
			const rowSpan = Math.ceil((item.querySelector('.masonry-content').getBoundingClientRect().height + rowGap) / (rowHeight + rowGap));
			/* console.log(item.getBoundingClientRect()); */
			item.style.gridRowEnd = 'span ' + rowSpan;
			if (gridImagesAsContent) {
				item.querySelector('.card.masonry-content').style.height = item.getBoundingClientRect().height + 'px';
			}
		}
	};

	const resizeAllMasonryItems = () => {
		const allItems = document.querySelectorAll('.masonry-item');
		if (allItems) {
			for (let i = 0; i < allItems.length; i++) {
				resizeMasonryItem(allItems[i]);
			}
		}
	};

	const waitForImages = () => {
		const allItems = document.querySelectorAll('.masonry-item');

		if (allItems) {
			for (let i = 0; i < allItems.length; i++) {
				imagesLoaded(allItems[i], instance => {
					const item = instance.elements[0];
					resizeMasonryItem(item);
				});
			}
		}
	};

	useEffect(() => {
		const masonryEvents = ['load', 'resize'];
		masonryEvents.forEach(event => {
			window.addEventListener(event, resizeAllMasonryItems);
		});
		return () => {
			masonryEvents.forEach(event => {
				window.removeEventListener(event, resizeAllMasonryItems);
			});
		};
	}, []);

	useEffect(() => {
		waitForImages();
	}, [posts]);

	return posts ? (
		<div className="masonry-wrapper">
			<div className="masonry">
				{posts.map((post, i) => {
					return (
						<div className="masonry-item" key={i}>
							<Card as={Link} to={`/post/${post.slug}`} className="no-deco masonry-content">
								{post._embedded['wp:featuredmedia'] && post._embedded['wp:featuredmedia'][0]?.media_details.sizes['portfolio-thumb'].source_url ? (
									<Card.Img variant="top" src={post._embedded['wp:featuredmedia'][0].media_details.sizes['portfolio-thumb'].source_url} />
								) : (
									<></>
								)}

								<Card.Body>
									<Card.Title>{post.title.rendered}</Card.Title>
									<Card.Subtitle className="mb-2 text-muted">
										{post._embedded['wp:term'][0].map((category, i) => {
											return (i > 0 ? ', ' : '') + category.name;
										})}
									</Card.Subtitle>
									<Card.Text className="card-description">{stripHTMLtag(post.content.rendered)}</Card.Text>
								</Card.Body>
								<Card.Footer className="text-muted">
									<Row>
										<Col className="text-left">
											<span>{formatDate(post.date)}</span>
										</Col>
										<Col className="text-right">
											<span>YOONIMOMI</span>
										</Col>
									</Row>
								</Card.Footer>
							</Card>
						</div>
					);
				})}
			</div>
		</div>
	) : (
		<Spinner />
	);
};

export default BlogCards;
