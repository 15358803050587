import React from 'react';
import { Row, Col } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import PhoneInput from 'react-phone-input-2';

const ContactInformation = ({ data, handle, handle2 }) => {
	const items = [
		{
			placeholder: 'first name',
			name: 'firstName',
			type: 'text',
			grid: {
				sm: '12',
				md: '3',
				lg: '3',
			},
			required: true,
		},
		{
			placeholder: 'last name',
			name: 'lastName',
			type: 'text',
			grid: {
				sm: '12',
				md: '3',
				lg: '3',
			},
			required: true,
		},
		{
			placeholder: 'email',
			name: 'email',
			type: 'email',
			grid: {
				sm: '12',
				md: '3',
				lg: '3',
			},
			required: true,
		},
		{
			placeholder: 'phone number',
			name: 'phoneNumber',
			type: 'tel',
			grid: {
				sm: '12',
				md: '3',
				lg: '3',
			},
			required: true,
		},
	];

	return (
		<>
			<h3>Contact Information</h3>
			<Row>
				{items.map((item, index) => {
					if (item.name !== 'phoneNumber') {
						return (
							<Col key={index} sm={item.grid.sm ? item.grid.sm : null} md={item.grid.md ? item.grid.md : null} lg={item.grid.lg ? item.grid.lg : null}>
								<Form.Group key={index}>
									<Form.Label>
										{item.placeholder}
										{item.required === true ? <sup className="asterisk red">*</sup> : ''}
									</Form.Label>
									<Form.Control type={item.type} value={data[item.name] ? data[item.name] : ''} name={item.name} onChange={handle}></Form.Control>
								</Form.Group>
							</Col>
						);
					} else {
						return (
							<Col key={index} sm={item.grid.sm ? item.grid.sm : null} md={item.grid.md ? item.grid.md : null} lg={item.grid.lg ? item.grid.lg : null}>
								<Form.Group key={index}>
									<PhoneInput
										specialLabel={
											<span className="text-capitalize">
												{item.placeholder}
												{item.required === true ? <sup className="asterisk red">*</sup> : ''}
											</span>
										}
										country="us"
										onlyCountries={['us']}
										disableDropdown
										disableCountryCode
										value={data.phoneNumber ? data.phoneNumber.toString() : ''}
										placeholder={''}
										name={item.name}
										onChange={handle2}
									/>
								</Form.Group>
							</Col>
						);
					}
				})}
			</Row>
		</>
	);
};

export default ContactInformation;
