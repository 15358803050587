import React, { useEffect } from 'react';
import axios from 'axios';
import Maps from '../../components/Investors/Maps';
import Properties from '../../components/Investors/Properties';
import { Helmet } from 'react-helmet';
import useHistoryState from '../../components/utils/useHistoryState';
import { Container, Row, Col } from 'react-bootstrap';
import Layout from '../../components/kr/global/Layout';

const KRInvestorsPage = props => {
	// const [Skip, setSkip] = useState(0);
	// const [Limit, setLimit] = useState(8);
	const currentUserData = props.user.data;
	const [PostSize, setPostSize] = useHistoryState(0, 'PostSize');
	const [properties, setProperties] = useHistoryState([], 'properties');
	const [nearbyProperties, setNearbyProperties] = useHistoryState([], 'nearbyProperties');
	const [totalCount, setTotalCount] = useHistoryState(0, 'totalCount');
	const [propHover, setPropHover] = useHistoryState(false, 'propHover');
	const [propClick, setPropClick] = useHistoryState(null, 'propClick');
	const [cityList, setCityList] = useHistoryState([], 'cityList');
	const [mapBounds, setMapBounds] = useHistoryState(
		{
			ne: { lat: 34.32288410889255, lng: -117.903106828125 },
			nw: { lat: 34.32288410889255, lng: -118.584259171875 },
			se: { lat: 33.78071908966692, lng: -117.903106828125 },
			sw: { lat: 33.78071908966692, lng: -118.584259171875 },
		},
		'mapBounds'
	);
	const [Center, setCenter] = useHistoryState(
		{
			lat: 34.5414563,
			lng: -118.6166781,
		},
		'Center'
	);

	const [search, setSearch] = useHistoryState(
		{
			requested: false,
			city: '',
			minPrice: '',
			maxPrice: '',
			loanType: '',
			typeOfProperty: '',
		},
		'search'
	);

	const [cityText, setCityText] = useHistoryState('', 'cityText');

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	const findFromArray = (arr, cityName) => {
		for (let i = 0; i < arr.length; i++) {
			if (arr[i] === cityName) {
				return true;
			}
		}
		return false;
	};

	const getProperties = status => {
		let variables = {
			limit: 50,
			totalCount,
			...search,
		};

		if (status === 'search') {
			setProperties([]);
			setNearbyProperties([]);
			setPostSize('');
			setTotalCount('');
			variables = {
				...variables,
				requested: true,
			};
			if (!search.city && cityText) {
				variables.city = cityText;
			}
		}
		axios.get('/api/inquiry/get/properties', { params: variables }).then(res => {
			if (res.data.success) {
				if (variables.loadMore) {
					setProperties([...properties, ...res.data.currentProperties]);
					setNearbyProperties([...nearbyProperties, ...res.data.nearbyProperties]);
					setTotalCount(res.data.totalCount);
				} else {
					setProperties(res.data.currentProperties);
					setTotalCount(res.data.totalCount);
					setNearbyProperties(res.data.nearbyProperties);
				}
				setPostSize(res.data.postSize);
				if (status !== 'search') {
					let tmpList = [];
					res.data.currentProperties.forEach(properties => {
						if (properties.subjectPropertyAddr.city && !findFromArray(tmpList, properties.subjectPropertyAddr.city)) {
							tmpList.push(properties.subjectPropertyAddr.city);
						}
					});
					setCityList([...tmpList]);
				}
			} else {
				alert('Failed to fetch property datas');
			}
		});
	};

	useEffect(() => {
		getProperties();
	}, []);

	return (
		<Layout currentUserData={currentUserData} history={props.history}>
			<Helmet>
				<meta data-react-helmet="true" charSet="utf-8" />
				<title data-react-helmet="true">Inventory - Yoonimoni</title>
				<meta
					name="description"
					content="Yoonimoni brings out the best inventory for commercial real estate properties for investors in California. We make first trust deed loans secured by properties. All income goes directly to you with the borrower paying all the interest."
				/>
				<link rel="canonical" data-react-helmet="true" href="https://yoonimoni.com/kr/investors" />
				<meta data-react-helmet="true" property="og:locale" content="ko_KR" />
				<meta data-react-helmet="true" property="og:type" content="article" />
				<meta data-react-helmet="true" property="og:title" content="Inventory - Yoonimoni" />
				<meta
					property="og:description"
					content="Yoonimoni brings out the best inventory for commercial real estate properties for investors in California. We make first trust deed loans secured by properties. All income goes directly to you with the borrower paying all the interest."
				/>
				<meta data-react-helmet="true" property="og:url" content="https://yoonimoni.com/kr/investors" />
				<meta data-react-helmet="true" property="og:site_name" content="Yoonimoni" />
				<meta data-react-helmet="true" property="og:image" content="https://yoonimoni.com/og_yoonimoni.png" />
			</Helmet>
			<main>
				<section id="inventory">
					<Container fluid id="inner-inventory">
						<div className="bg-illust-spill-wrapper">
							<figure className="left-spill" />
						</div>
						<div className="bg-illust-waffle-wrapper">
							<figure className="left-bot-waffle" />
							<figure className="right-bot-waffle" />
						</div>
						<Row>
							<Col className="p-0" id="list">
								<Properties
									properties={properties}
									nearbyProperties={nearbyProperties}
									PostSize={PostSize}
									propHover={propHover}
									setPropHover={setPropHover}
									search={search}
									setSearch={setSearch}
									cityText={cityText}
									setCityText={setCityText}
									cityList={cityList}
									getProperties={getProperties}
								/>
							</Col>
							<Col className="p-0" id="map">
								<Maps
									setMapBounds={setMapBounds}
									properties={properties}
									propHover={propHover}
									propClick={propClick}
									setPropHover={setPropHover}
									setPropClick={setPropClick}
									defaultStreetView={false}
									streetView={false}
									Center={Center}
									setCenter={setCenter}
								/>
							</Col>
						</Row>
					</Container>
				</section>
			</main>
		</Layout>
	);
};

export default KRInvestorsPage;
