import React, { useState, useRef, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { loginUser } from '../../_actions/user_action';
import { Link } from 'react-router-dom';
import { auth } from '../../_actions/user_action';
import ReCAPTCHA from 'react-google-recaptcha';
import Layout from '../../components/kr/global/Layout';

/* material UI */
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import MuiContainer from '@material-ui/core/Container';
import Alert from '@material-ui/lab/Alert';

const REACT_APP_RECAPTCHA_KEY = process.env.REACT_APP_RECAPTCHA_KEY;

const Container = withStyles(theme => ({
	root: {
		[theme.breakpoints.down('xs')]: {
			minHeight: 'unset',
		},
		minHeight: 'calc(100vh - 4rem)',
		alignItems: 'center',
		justifyContent: 'center',
		display: 'flex',
	},
}))(MuiContainer);

const useStyles = makeStyles(theme => ({
	paper: {
		[theme.breakpoints.down('xs')]: {
			paddingTop: theme.spacing(10),
			paddingBottom: theme.spacing(20),
		},
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		backgroundColor: '#fafafa',
	},
	form: {
		width: '100%', // Fix IE 11 issue.
		marginTop: theme.spacing(1),
	},
	submit: {
		margin: theme.spacing(3, 0, 2),
		backgroundColor: '#03946d',
		border: '1px solid #03946d',
		borderRadius: '0',
		'&:hover': {
			backgroundColor: '#fafafa',
			color: '#03946d',
		},
	},
}));

const KRLoginPage = props => {
	const currentUserData = props.user.data;
	const classes = useStyles();
	const dispatch = useDispatch();
	const reRef = useRef();
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	const [User, setUser] = useState({
		email: '',
		password: '',
	});
	const [Warning, setWarning] = useState({
		message: '',
		type: '',
	});
	const onUserHandler = e => {
		const { name, value } = e.target;
		setUser({ ...User, [name]: value });
	};

	const onSubmitHandler = async e => {
		e.preventDefault();
		const token = await reRef.current.executeAsync();
		reRef.current.reset();

		let body = {
			email: User.email,
			password: User.password,
			token,
		};

		dispatch(loginUser(body)).then(response => {
			if (response.payload.loginSuccess) {
				dispatch(auth()).then(response => {
					props.history.push('/kr/');
				});
			} else {
				setWarning({ message: response.payload.message, type: 'error' });
			}
		});
	};

	return (
		<Layout currentUserData={currentUserData} history={props.history}>
			<Container component="main" maxWidth="xs">
				<div className={classes.paper}>
					<Typography component="h1" variant="h5">
						Sign in
					</Typography>
					<form className={classes.form} onSubmit={onSubmitHandler}>
						{Warning.message ? <Alert severity={Warning.type}>{Warning.message}</Alert> : null}
						<TextField
							variant="outlined"
							margin="normal"
							required
							fullWidth
							id="email"
							label="Email Address"
							name="email"
							autoComplete="email"
							autoFocus
							value={User.email}
							onChange={onUserHandler}
						/>
						<TextField
							variant="outlined"
							margin="normal"
							required
							fullWidth
							name="password"
							label="Password"
							type="password"
							id="password"
							autoComplete="current-password"
							value={User.password}
							onChange={onUserHandler}
						/>
						<Button type="submit" fullWidth variant="contained" color="primary" className={classes.submit}>
							Sign In
						</Button>
						<Grid container>
							<Grid item xs>
								<Link to={'/kr/forgotpassword'} variant="body2">
									Forgot password?
								</Link>
							</Grid>
							<Grid item>
								Don't have an account?&nbsp;&nbsp;
								<Link to="/kr/register" variant="body2">
									Sign Up
								</Link>
							</Grid>
						</Grid>
					</form>
					<ReCAPTCHA sitekey={REACT_APP_RECAPTCHA_KEY} size="invisible" ref={reRef} />
				</div>
			</Container>
		</Layout>
	);
};

export default KRLoginPage;
