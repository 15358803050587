import React, { useState, useEffect } from 'react';
import Form from 'react-bootstrap/Form';

import axios from 'axios';
import DashboardLayout from '../../../components/dashboard/DashboardLayout';
import { Container, Row, Col, Button } from 'react-bootstrap';

const KRReferralPage = props => {
	const currentUserData = props.user.data;
	const [ReferralLink, setReferralLink] = useState();
	const [ButtonLabel, setButtonLabel] = useState('Copy to clipboard');

	const fetch = () => {
		if (currentUserData._id) {
			axios.get(`/api/url/get/referral/`).then(res => {
				if (res.data.success) {
					setReferralLink(res.data.referralLink);
				} else {
					axios.post('/api/url/post/referral').then(result => {
						if (result.data.success) {
							setReferralLink(result.data.referralLink);
						} else {
							alert('Failed to create referral link');
						}
					});
				}
			});
		}
	};

	useEffect(() => {
		if (currentUserData && currentUserData._id) {
			fetch();
		}
	}, [currentUserData, fetch]);

	return (
		<DashboardLayout currentUserData={currentUserData} history={props.history}>
			<Container fluid className="m-26px p-0" id="dashboard-board">
				<Row className="p-0 m-0">
					<Col xs={12} className="p-0">
						<div id="dashboard-form">
							<Row className="p-0 m-0">
								<Col xs={12} className="p-0">
									<span className="d-block font-weight-500">Refer a Friend</span>
								</Col>
								<Col xs={12} className="p-0">
									<Form.Control placeholder="Referral Link" type="text" value={ReferralLink || ''} readOnly />

									<Button
										className="primary-button mt-20px"
										onClick={() => {
											navigator.clipboard.writeText(ReferralLink);
											setButtonLabel('Link Copied!');
										}}
									>
										{ButtonLabel}
									</Button>
								</Col>
							</Row>
						</div>
					</Col>
				</Row>
			</Container>
		</DashboardLayout>
	);
};

export default KRReferralPage;
