import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Redirect } from 'react-router-dom';

const RedirectPage = props => {
	const id = props.match.params.id;
	const [Message, setMessage] = useState('Redirecting');
	const [LongUrl, setLongUrl] = useState();
	const fetch = () => {
		axios.get(`/api/url/get/longUrl/${id}`).then(res => {
			if (res.data.success) {
				setLongUrl(res.data.longUrl);
			} else {
				// No referral Link found
				setMessage('No link found');
			}
		});
	};
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	useEffect(fetch, []);
	if (LongUrl) {
		return <Redirect to={LongUrl}></Redirect>;
	} else {
		return <div>{Message}</div>;
	}
};

export default RedirectPage;
