import React from 'react';
import { Row, Col } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import CurrencyInput from 'react-currency-input-field';
import { calculateLTV } from '../utils/functions';

const Purchase = ({ handle, data }) => {
	const items = [
		{
			placeholder: 'loan amount',
			name: 'loanAmt',
			type: 'number',
			prepend: '$',
			grid: {
				sm: '12',
				md: '4',
				lg: '4',
			},
			required: true,
		},
		{
			placeholder: 'purchase price',
			name: 'purchasedPrice',
			type: 'number',
			prepend: '$',
			grid: {
				sm: '12',
				md: '4',
				lg: '4',
			},
			required: true,
		},
		{
			placeholder: 'LTV',
			name: 'ltv',
			type: 'number',
			append: '%',
			grid: {
				sm: '12',
				md: '4',
				lg: '4',
			},
		},
		{
			placeholder: 'down payment',
			name: 'downPayment',
			type: 'number',
			prepend: '$',
			grid: {
				sm: '12',
				md: '4',
				lg: '4',
			},
			required: true,
		},
		{
			placeholder: 'Interest Rate Offered',
			name: 'interestRate',
			type: 'number',
			append: '%',
			grid: {
				sm: '12',
				md: '4',
				lg: '4',
			},
			required: true,
		},
		{
			placeholder: 'Requsted Term',
			name: 'term',
			type: 'text',
			grid: {
				sm: '12',
				md: '4',
				lg: '4',
			},
			required: true,
		},
		{
			placeholder: 'any seller carry?',
			name: 'sellerCarry',
			type: 'number',
			prepend: '$',
			grid: {
				sm: '12',
				md: '4',
				lg: '4',
			},
		},
		{
			placeholder: 'escrow opened?',
			name: 'escrowOpened',
			items: [
				{ type: 'radio', placeholder: 'yes', name: 'true' },
				{ type: 'radio', placeholder: 'no', name: 'false' },
			],
			grid: {
				sm: '12',
				md: '4',
				lg: '4',
			},
		},
		{
			placeholder: 'Current Estimated Appraisal Value',
			name: 'currentEstValue',
			prepend: '$',
			grid: {
				sm: '12',
				md: '4',
				lg: '4',
			},
		},
	];
	return (
		<Row>
			<Col sm={12}>
				<h3 className="text-capitalize">{data.loanType} Loan</h3>
			</Col>
			{/* {console.log(data.loanType)} */}
			{items.map((item, index) => {
				return (
					<Col key={index} sm={item.grid.sm ? item.grid.sm : null} md={item.grid.md ? item.grid.md : null} lg={item.grid.lg ? item.grid.lg : null}>
						<Form.Group>
							<Form.Label>
								{item.name === 'loanAmt' && data.loanType === 'fix & flip/Rehab'
									? 'Existing Loan Amount'
									: item.name === 'purchasedPrice' && data.loanType === 'fix & flip/Rehab'
										? 'Original Purchase Price'
										: item.placeholder}
								{item.required === true ? <sup className="asterisk red">*</sup> : ''}
							</Form.Label>
							{item.name === 'escrowOpened' ? (
								<Form.Group className="d-flex mt-10px radioBtns">
									{item.items.map((checkbox, i) => {
										return (
											<Form.Check
												key={i}
												type={checkbox.type}
												label={checkbox.placeholder}
												value={checkbox.name ? checkbox.name : ''}
												checked={data.escrowOpened === checkbox.name ? true : false}
												onChange={handle}
												name={'escrowOpened'}
												className="mr-5 my-auto"
											/>
										);
									})}
								</Form.Group>
							) : item.name === 'ltv' ? (
								<InputGroup key={index}>
									{console.log(data.loanAmt, data.purchasedPrice)}
									<Form.Control
										value={
											data.loanAmt && data.purchasedPrice ? (calculateLTV(data).LTV && calculateLTV(data).LTV !== undefined ? calculateLTV(data).LTV.toFixed(2) : calculateLTV(data).toFixed(2)) : ''
										}
										type={item.type}
										name={item.name}
										disabled
									></Form.Control>
									<InputGroup.Append>
										<InputGroup.Text id="basic-addon2">{item.append}</InputGroup.Text>
									</InputGroup.Append>
								</InputGroup>
							) : item.name === 'downPayment' ? (
								<CurrencyInput
									name={item.name}
									key={index}
									decimalsLimit={2}
									allowNegativeValue={true}
									maxLength={10}
									prefix={item.prepend}
									className="form-control"
									onValueChange={(value, name) => handle(value, name)}
									defaultValue={data[item.name] ? data[item.name] : ''}
								/>
							) : item.name === 'interestRate' || item.name === 'term' ? (
								<InputGroup>
									<Form.Control value={data[item.name]} type={item.type} name={item.name} onChange={handle}></Form.Control>
									{item.append ? (
										<InputGroup.Append>
											<InputGroup.Text id="basic-addon2">{item.append}</InputGroup.Text>
										</InputGroup.Append>
									) : (
										''
									)}
								</InputGroup>
							) : (
								<CurrencyInput
									name={item.name}
									key={index}
									decimalsLimit={2}
									allowNegativeValue={false}
									maxLength={10}
									prefix={item.prepend}
									className="form-control"
									onValueChange={(value, name) => handle(value, name)}
									defaultValue={data[item.name] ? data[item.name] : ''}
								/>
							)}
						</Form.Group>
					</Col>
				);
			})}
		</Row>
	);
};

export default Purchase;
