import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Pagination from 'react-bootstrap/Pagination';
import Spinner from '../Spinner';
import useHistoryState from '../utils/useHistoryState';
import { Container, Row, Col, Carousel, DropdownButton, Form, Dropdown, Button } from 'react-bootstrap';
import useResize from '../utils/useResize';
import { Typeahead } from 'react-bootstrap-typeahead';
import CurrencyInput from 'react-currency-input-field';

import { DisplayLTV, showWhichTD, priceTag, trimAddress } from '../utils/functions';

const SearchBar = ({ search, setSearch, cityList, getProperties, setPage, cityText, setCityText }) => {
	const handleSubmit = e => {
		e.preventDefault();
		getProperties('search');
	};

	return (
		<div id="searchbar" className="mt-3">
			<Container fluid className="mb-8px w-100 p-0">
				<Row>
					<Col xs={12} sm={6} className="search-left">
						<Typeahead
							id="city-search"
							labelKey="name"
							onChange={cityVal => setSearch({ ...search, city: cityVal[0] })}
							onInputChange={cityInput => setCityText(cityInput.replace(/[^\w\s]/gi, ''))}
							options={cityList}
							placeholder="City"
							selected={search.city ? [search.city] : []}
						/>
					</Col>
					<Col xs={12} sm={6} className="search-right">
						<DropdownButton
							className="w-100"
							id="dropdown-item-button"
							title={<span className="text-capitalize">{search.loanType ? search.loanType : 'All Loan Types'}</span>}
						>
							{['all', 'purchase', 'refinance', 'fix & flip/Rehab', '2nd', '3rd', 'construction/project', 'bridge/mezz'].map((type, i) => {
								return (
									<Dropdown.Item
										key={i}
										as="button"
										className="text-capitalize w-100"
										onClick={e => {
											if (type === 'all') {
												setSearch({ ...search, loanType: '' });
											} else {
												setSearch({ ...search, loanType: type });
											}
										}}
									>
										{type}
									</Dropdown.Item>
								);
							})}
						</DropdownButton>
					</Col>
				</Row>
				<Row className="mb-8px w-100 p-0">
					<Col xs={12} sm={6} className="search-left">
						<Row>
							<Col xs={6} className="search-left search-subsection">
								<CurrencyInput
									name="minPrice"
									placeholder="Min Price"
									decimalsLimit={2}
									allowNegativeValue={false}
									maxLength={10}
									prefix="$"
									className="form-control"
									onValueChange={(value, name) => setSearch({ ...search, minPrice: value })}
								/>
							</Col>
							<Col xs={6} className="search-right search-subsection">
								<CurrencyInput
									name="maxPrice"
									placeholder="Max Price"
									decimalsLimit={2}
									allowNegativeValue={false}
									maxLength={10}
									prefix="$"
									className="form-control"
									onValueChange={(value, name) => setSearch({ ...search, maxPrice: value })}
								/>
							</Col>
						</Row>
					</Col>
					<Col xs={12} sm={6} className="search-right">
						<Row>
							<Col xs={8} className="search-left search-subsection">
								<DropdownButton
									className="w-100"
									id="dropdown-item-button"
									title={<span className="text-capitalize">{search.typeOfProperty ? search.typeOfProperty : 'Property Types'}</span>}
								>
									{['all', 'commercial', 'multi-family', 'industrial', 'res. (1-4)', 'land', 'other'].map(type => {
										return (
											<Dropdown.Item
												as="button"
												className="text-capitalize w-100"
												onClick={e => {
													if (type === 'all') {
														setSearch({ ...search, typeOfProperty: '' });
													} else {
														setSearch({ ...search, typeOfProperty: type });
													}
												}}
											>
												{type}
											</Dropdown.Item>
										);
									})}
								</DropdownButton>
							</Col>
							<Col xs={4} className="search-right search-subsection">
								<Button className="w-100 h-100 primary-button button-square py-1" style={{ borderRadius: '5px' }} onClick={handleSubmit}>
									<span>Search</span>
								</Button>
							</Col>
						</Row>
					</Col>
				</Row>
			</Container>
		</div>
	);
};

const Properties = props => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const Properties = props.properties;
	const { width } = useResize();

	const { nearbyProperties, PostSize, propHover, setPropHover, search, setSearch, cityText, setCityText, cityList, getProperties } = props;

	const [page, setPage] = useHistoryState(1, 'page');

	const changePage = (e, pNum) => {
		setPage(pNum);
	};

	const paginationVal = total => {
		return total / 50 === 1 ? 1 : Math.floor(total / 50) === 0 ? 1 : Math.floor(total / 50) && total % 50 === 0 ? total / 50 : Math.floor(total / 50) + 1;
	};

	const pageArray = pNum => {
		let pArr = [];
		for (let i = 1; i <= pNum; i++) {
			pArr = [...pArr, i];
		}
		return pArr;
	};
	const mobileView = p => (
		<Link to={`/property/${p._id}`}>
			<Container fluid className="property bg-white my-5px p-0 ">
				<Row className="p-15px">
					<Col xs={12} className="d-flex" onClick={e => e.preventDefault()}>
						{console.log(p)}
						<Carousel
							indicators={p.images.length > 1 ? true : false}
							interval={null}
							className="w-100 m-auto"
							nextIcon={
								p.images.length > 1 ? (
									<div className="nextIcon">
										<svg width="5" height="8" viewBox="0 0 5 8" fill="none" xmlns="http://www.w3.org/2000/svg">
											<path d="M1 0.999969L4 3.99997L1 6.99997" stroke="#303030" />
										</svg>
									</div>
								) : (
									false
								)
							}
							prevIcon={
								p.images.length > 1 ? (
									<div className="prevIcon">
										<svg width="5" height="8" viewBox="0 0 5 8" fill="none" xmlns="http://www.w3.org/2000/svg">
											<path d="M4 0.999969L1 3.99997L4 6.99997" stroke="#303030" />
										</svg>
									</div>
								) : (
									false
								)
							}
							onClick={e => e.preventDefault()}
						>
							{p.images.length > 0 ? (
								p.images.map((image, index) => (
									<Carousel.Item key={index}>
										<Link to={`/property/${p._id}`}>
											<Container fluid>
												<Row className="header">
													<Col>
														<img
															src={image ? image : '/property/image-from-rawpixel-id-558306-jpeg.jpg'}
															alt="invest property"
															width={width - 100}
															height={((width - 100) / 16) * 11}
															className="invest-property-img"
															onError={e => {
																e.target.onerror = null;
																e.target.src = '/property/image-from-rawpixel-id-558306-jpeg.jpg';
															}}
														/>
													</Col>
												</Row>
											</Container>
										</Link>
									</Carousel.Item>
								))
							) : (
								<Carousel.Item key="no-image">
									<Link to={`/property/${p._id}`}>
										<Container fluid>
											<Row className="header">
												<Col>
													<img
														src="/property/image-from-rawpixel-id-558306-jpeg.jpg"
														alt="invest property"
														width={width - 100}
														height={((width - 100) / 16) * 11}
														className="invest-property-img"
														onError={e => {
															e.target.onerror = null;
															e.target.src = '/property/image-from-rawpixel-id-558306-jpeg.jpg';
														}}
													/>
												</Col>
											</Row>
										</Container>
									</Link>
								</Carousel.Item>
							)}
						</Carousel>
						{p.currentEstValue && p.currentEstValue > 0 ? <div className="apv">Est. Value: {priceTag(p.currentEstValue)}</div> : ''}
					</Col>
					<Col xs={12}>
						<div className="funded-label">
							<img
								src="/icons/funded.png"
								alt="Funded"
								width="90px"
								height="90px"
								onError={e => {
									e.target.onerror = null;
									e.target.src = '/icons/funded.png';
								}}
							></img>
						</div>
						<div className="content px-15px">
							<div className="left-col">
								{/* <span className="d-block invest-type primary-font-color text-capitalize text-no-hover">{p.loanType} loan</span> */}
								<span className="d-block invest-city dark-font-color text-no-hover">
									{p.subjectPropertyAddr.line1 ? p.subjectPropertyAddr.line1 + ', ' : ''}
									{p.subjectPropertyAddr.city ? p.subjectPropertyAddr.city + ', ' : ''}
									{p.subjectPropertyAddr.state ? <span className="text-uppercase">{p.subjectPropertyAddr.state + ' '}</span> : ''}
									{p.subjectPropertyAddr.zip ? p.subjectPropertyAddr.zip : ''}
								</span>
								<img alt="gradient" className="gradient" src="/icons/inventory-gradient.png"></img>
								{/* {p.borrowingEntity ? (
									<span className="d-block invest-details text-no-hover">
										BORROWING&nbsp;ENTITY:&nbsp;<span className="primary-font-color fw-600 text-no-hover text-capitalize">{p.borrowingEntity}</span>
									</span>
								) : null} */}
								{/* {p.purchasedPrice ? (
									<span className="d-block invest-details text-no-hover">
										PURCHASE&nbsp;PRICE:&nbsp;<span className="primary-font-color fw-600 text-no-hover text-capitalize">{priceTag(p.purchasedPrice)}</span>
									</span>
								) : null} */}
								{/* {p.downPayment ? (
									<span className="d-block invest-details text-no-hover">
										DOWN&nbsp;PAYMENT:&nbsp;<span className="primary-font-color fw-600 text-no-hover text-capitalize">{priceTag(p.downPayment)}</span>
									</span>
								) : null} */}
								<Container fluid>
									<Row>
										{/* <Col xs={4} className="p-0">
											<span className="d-block invest-details text-no-hover">PROP. TYPE:&nbsp;</span>
										</Col>
										<Col xs={7} className="p-0">
											<span className="primary-font-color fw-600 text-no-hover text-capitalize">
												{p.typeOfProperty === 'commercial' ? 'comm.' : p.typeOfProperty}
											</span>
										</Col> */}
										<Col xs={4} className="p-0">
											<span className="d-block invest-details text-no-hover">Loan Req:&nbsp;</span>
										</Col>
										<Col xs={7} className="p-0">
											<span className="fw-600 text-no-hover text-capitalize">
												{p.loanType === 'fix & flip/Rehab' ? priceTag(p.loanAmt + p.fixUpCost) : p.loanAmt ? priceTag(p.loanAmt) : '-'}
											</span>
										</Col>
										<Col xs={4} className="p-0">
											<span className="d-block invest-details primary-font-color text-no-hover">Rate Req.:&nbsp;</span>
										</Col>
										<Col xs={7} className="p-0">
											<span className="fw-600 text-no-hover primary-font-color text-capitalize">{p.interestRate ? p.interestRate + '%' : ''}</span>
										</Col>
										<Col xs={4} className="p-0">
											<span className="d-block invest-details text-no-hover">Term Req.:&nbsp;</span>
										</Col>
										<Col xs={7} className="p-0">
											<span className="fw-600 text-no-hover text-capitalize">{p.term ? `${p.term}` : ''}</span>
										</Col>{' '}
										<DisplayLTV singleProp={p} color="black" formatView={true} />
										{p.loanType === '2nd' || p.loanType === '3rd' ? (
											''
										) : (
											<>
												<Col xs={4} className="p-0">
													<span className="d-block invest-details text-no-hover">Lien Pos.:&nbsp;</span>
												</Col>
												<Col xs={7} className="p-0">
													<span className="fw-600 text-no-hover">{p.lienPos ? p.lienPos : showWhichTD(p.mortgageBalance.index)}</span>
												</Col>
											</>
										)}
										<Col xs={4} className="p-0">
											<span className="d-block invest-details text-no-hover">Loan Type:&nbsp;</span>
										</Col>
										<Col xs={7} className="p-0">
											<span className="primary-font-color fw-600 text-no-hover text-capitalize">{p.loanType}</span>
										</Col>
										{p.loanType === '2nd' || p.loanType === '3rd' ? (
											<Col xs={12} className="p-0">
												<span className="fw-600 text-no-hover text-capitalize">&nbsp;</span>
											</Col>
										) : (
											''
										)}
									</Row>
								</Container>
								{/* <span className="d-block invest-details text-no-hover">
									PROPERTY TYPE:&nbsp;
									<span className="primary-font-color fw-600 text-no-hover text-capitalize">
										{p?.typeOfProperty === 'commercial' ? 'comm.' : p.typeOfProperty}
									</span>
								</span>
								<span className="d-block invest-details text-no-hover">
									Lien PosITION: <span className="primary-font-color fw-600 text-no-hover">{showWhichTD(p.mortgageBalance.index)}</span>
								</span>
								<span className="d-block invest-details text-no-hover">
									RATE & Term:&nbsp;<span className="primary-font-color fw-600 text-no-hover text-capitalize">{p.interestRate ? p.interestRate : '-'}%{p.term ? `, ${p.term}` : ''}</span>
								</span>
								<span className="d-block invest-details text-no-hover">
									<DisplayLTV singleProp={p} color="black" formatView={true} />
								</span> */}
							</div>
							{/* <div className="right-col">
								<div className="loan">
									<span className="d-block invest-details text-right text-no-hover">LOAN&nbsp;AMT.</span>
									<span className="d-block  primary-font-color invest-amt-val text-center fw-600 text-no-hover">
										{p.loanAmt ? `${priceTag(p.loanAmt)}` : '-'}
									</span>
								</div>
							</div> */}
						</div>
					</Col>
				</Row>
			</Container>
		</Link>
	);

	const desktopView = p => (
		<Link to={`/property/${p._id}`}>
			<Container fluid className="property bg-white my-5px p-0">
				<Row className="p-15px max-h-211px">
					<Col className="d-flex">
						<Carousel
							indicators={p.images.length > 1 ? true : false}
							interval={null}
							nextIcon={
								p.images.length > 1 ? (
									<div className="nextIcon">
										<svg width="5" height="8" viewBox="0 0 5 8" fill="none" xmlns="http://www.w3.org/2000/svg">
											<path d="M1 0.999969L4 3.99997L1 6.99997" stroke="#303030" />
										</svg>
									</div>
								) : (
									false
								)
							}
							prevIcon={
								p.images.length > 1 ? (
									<div className="prevIcon">
										<svg width="5" height="8" viewBox="0 0 5 8" fill="none" xmlns="http://www.w3.org/2000/svg">
											<path d="M4 0.999969L1 3.99997L4 6.99997" stroke="#303030" />
										</svg>
									</div>
								) : (
									false
								)
							}
							onClick={e => e.preventDefault()}
						>
							{p.images.length > 0 ? (
								p.images.map((image, index) => (
									<Carousel.Item key={index}>
										<Link to={`/property/${p._id}`}>
											<Container fluid>
												<Row className="header">
													<Col>
														<img
															src={image ? image : '/property/image-from-rawpixel-id-558306-jpeg.jpg'}
															width="251"
															height="180"
															alt="invest property"
															className="invest-property-img"
															onError={e => {
																e.target.onerror = null;
																e.target.src = '/property/image-from-rawpixel-id-558306-jpeg.jpg';
															}}
														/>
													</Col>
												</Row>
											</Container>
										</Link>
									</Carousel.Item>
								))
							) : (
								<Carousel.Item key="no-image">
									<Link to={`/property/${p._id}`}>
										<Container fluid>
											<Row className="header">
												<Col>
													<img
														src="/property/image-from-rawpixel-id-558306-jpeg.jpg"
														width="251"
														height="180"
														alt="invest property"
														className="invest-property-img"
														onError={e => {
															e.target.onerror = null;
															e.target.src = '/property/image-from-rawpixel-id-558306-jpeg.jpg';
														}}
													/>
												</Col>
											</Row>
										</Container>
									</Link>
								</Carousel.Item>
							)}
							{p.currentEstValue && p.currentEstValue > 0 ? <div className="apv">Est. Value: {priceTag(p.currentEstValue)}</div> : ''}
						</Carousel>
						<div className="funded-label">
							<img
								src="/icons/funded.png"
								alt="Funded"
								width="90px"
								height="90px"
								onError={e => {
									e.target.onerror = null;
									e.target.src = '/icons/funded.png';
								}}
							></img>
						</div>
						<div className="content px-15px">
							<div className="left-col">
								{/* <span className="d-block invest-type primary-font-color text-capitalize text-no-hover text-nowrap">
									{p.subjectPropertyAddr.line1 ? trimAddress(p.subjectPropertyAddr) + ', ' : ''}
									{p.subjectPropertyAddr.city ? p.subjectPropertyAddr.city + ', ' : ''}
									{p.subjectPropertyAddr.state ? p.subjectPropertyAddr.state + ' ' : ''}
									{p.subjectPropertyAddr.zip ? p.subjectPropertyAddr.zip : ''}
								</span> */}
								<span className="d-block invest-city dark-font-color text-capitalize text-no-hover text-nowrap">
									{p.subjectPropertyAddr.line1 ? p.subjectPropertyAddr.line1 + ', ' : ''}
									{p.subjectPropertyAddr.city ? p.subjectPropertyAddr.city + ', ' : ''}
									{p.subjectPropertyAddr.state ? <span className="text-uppercase">{p.subjectPropertyAddr.state + ' '}</span> : ''}
									{p.subjectPropertyAddr.zip ? p.subjectPropertyAddr.zip : ''}
								</span>
								{/* <span className="d-block invest-city dark-font-color text-capitalize text-no-hover text-nowrap">{p.loanType} loan</span> */}

								<img alt="gradient" className="gradient" src="/icons/inventory-gradient.png"></img>

								{/* {p.borrowingEntity ? (
									<span className="d-block invest-details text-no-hover">
										BORROWING&nbsp;ENTITY:&nbsp;<span className="primary-font-color fw-600 text-no-hover text-capitalize">{p.borrowingEntity}</span>
									</span>
								) : null} */}
								{/* {p.purchasedPrice ? (
									<span className="d-block invest-details text-no-hover">
										PURCHASE&nbsp;PRICE:&nbsp;<span className="primary-font-color fw-600 text-no-hover text-capitalize">{priceTag(p.purchasedPrice)}</span>
									</span>
								) : null} */}
								{/* {p.interestRate ? (
									<span className="d-block invest-details text-no-hover">
										Rate:&nbsp;<span className="primary-font-color fw-600 text-no-hover text-capitalize">{p.interestRate}%</span>
									</span>
								) : null} */}
								<Container fluid>
									<Row>
										<Col xs={4} className="p-0">
											<span className="d-block invest-details text-no-hover">Loan Req:&nbsp;</span>
										</Col>
										<Col xs={7} className="p-0">
											<span className="fw-600 text-no-hover text-capitalize">
												{p.loanType === 'fix & flip/Rehab' ? priceTag(p.loanAmt + p.fixUpCost) : p.loanAmt ? priceTag(p.loanAmt) : '-'}
											</span>
										</Col>

										<Col xs={4} className="p-0">
											<span className="d-block invest-details primary-font-color text-no-hover">Rate Req.:&nbsp;</span>
										</Col>
										<Col xs={7} className="p-0">
											<span className=" fw-600 primary-font-color text-no-hover text-capitalize">{p.interestRate ? p.interestRate + '%' : ''}</span>
										</Col>
										<Col xs={4} className="p-0">
											<span className="d-block invest-details text-no-hover">Term Req.:&nbsp;</span>
										</Col>
										<Col xs={7} className="p-0">
											<span className="fw-600 text-no-hover text-capitalize">{p.term ? `${p.term}` : ''}</span>
										</Col>
										<DisplayLTV singleProp={p} color="black" formatView={true} />
										{p.loanType === '2nd' || p.loanType === '3rd' ? (
											''
										) : (
											<>
												<Col xs={4} className="p-0">
													<span className="d-block invest-details text-no-hover">Lien Pos.:&nbsp;</span>
												</Col>
												<Col xs={7} className="p-0">
													<span className="fw-600 text-no-hover">{p.lienPos ? p.lienPos : showWhichTD(p.mortgageBalance.index)}</span>
												</Col>
											</>
										)}
										<Col xs={4} className="p-0">
											<span className="d-block invest-details text-no-hover">Loan Type:&nbsp;</span>
										</Col>
										<Col xs={7} className="p-0">
											<span className="fw-600 text-no-hover text-capitalize">{p.loanType}</span>
										</Col>
										{p.loanType === '2nd' || p.loanType === '3rd' ? (
											<Col xs={12} className="p-0">
												<span className="fw-600 text-no-hover text-capitalize">&nbsp;</span>
											</Col>
										) : (
											''
										)}
									</Row>
								</Container>
								{/* 
								<span className="d-block invest-details text-no-hover">
									PROPERTY TYPE:&nbsp;
									<span className="primary-font-color fw-600 text-no-hover text-capitalize">
										{p.typeOfProperty === 'commercial' ? 'comm.' : p.typeOfProperty}
									</span>
								</span>
								<span className="d-block invest-details text-no-hover">
									Lien PosITION: <span className="primary-font-color fw-600 text-no-hover">{showWhichTD(p.mortgageBalance.index)}</span>
								</span>
								<span className="d-block invest-details text-no-hover">
									RATE & Term:&nbsp;<span className="primary-font-color fw-600 text-no-hover text-capitalize">{p.interestRate ? p.interestRate : '-'}%{p.term ? `, ${p.term}` : ''}</span>
								</span>
								<span className="d-block invest-details text-no-hover">
									<DisplayLTV singleProp={p} color="black" />
								</span>
								 */}
							</div>
						</div>
					</Col>
				</Row>
			</Container>
		</Link>
	);

	const properties = Properties.slice((page - 1) * 50, page * 50).map(p => {
		return (
			<div
				key={p._id}
				className="no-deco hover-effect"
				onMouseEnter={() => {
					setPropHover(p._id);
				}}
				onMouseLeave={() => {
					setPropHover(false);
				}}
			>
				{width >= 660 ? desktopView(p) : mobileView(p)}
			</div>
		);
	});
	return (
		<>
			<Row>
				<Col id="inner-list">
					{/* 
					<Row>
						<Col>
							<BRStick />
						</Col>
						<Col className="d-flex justify-content-end">
							<DropdownButton className="w-50" id="dropdown-item-button" title="Newest to Oldest">
								<Dropdown.Item as="button">Oldest to Newest</Dropdown.Item>
							</DropdownButton>
						</Col>
					</Row> */}
					<SearchBar
						search={search}
						setSearch={setSearch}
						cityList={cityList}
						cityText={cityText}
						setCityText={setCityText}
						getProperties={getProperties}
						setPage={setPage}
					/>
					{properties && properties.length ? (
						<>
							{properties}
							<Pagination>
								<Pagination.Prev disabled={page === 1} onClick={e => changePage(e, page - 1)}>
									Prev
								</Pagination.Prev>

								{pageArray(paginationVal(Properties.length)).map((i, index) => (
									<Pagination.Item key={index} onClick={e => changePage(e, i)} active={i === page}>
										{i}
									</Pagination.Item>
								))}

								<Pagination.Next disabled={page === paginationVal(Properties.length)} onClick={e => changePage(e, page + 1)}>
									Next
								</Pagination.Next>
							</Pagination>
						</>
					) : PostSize === 'empty' ? (
						<div
							style={{
								height: 'calc(50vh - 4rem)',
								fontSize: '20pt',
								display: 'flex',
								textAlign: 'center',
								alignContent: 'center',
								alignItems: 'center',
								justifyContent: 'center',
							}}
						>
							No search results found.
						</div>
					) : (
						<Spinner />
					)}
				</Col>
			</Row>
		</>
	);
};

export default Properties;
