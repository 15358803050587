import React from 'react';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Layout from '../components/global/Layout';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

const PostPage = props => {
	const currentUserData = props.user.data;
	const postId = props.match.params.id;
	const [post, setPost] = useState({});
	const [media, setMedia] = useState();

	const fetchPost = async () => {
		await axios.get(`https://cozmous.com/wp-json/wp/v2/posts?slug=${postId}&_embed`).then(res => {
			/* console.log(res.data); */
			if (res.status === 200) {
				setPost(res.data[0]);
			} else {
				console.log('Error response code: ', res.status);
			}
		});
	};

	const formatDate = date => {
		const postDate = new Date(date);
		const Months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];
		return `${Months[postDate.getMonth()]} ${postDate.getDate()}, ${postDate.getFullYear()}`;
	};

	useEffect(() => {
		fetchPost();
	}, [postId]);

	if (!post.id) {
		return <div>loading</div>;
	} else {
		return (
			<Layout currentUserData={currentUserData} history={props.history}>
				<Helmet>
					<meta data-react-helmet="true" charSet="utf-8" />
					<title data-react-helmet="true">Blog - Yoonimoni</title>
					<link data-react-helmet="true" rel="canonical" href="https://yoonimoni.com/blog/" />
					<meta data-react-helmet="true" property="og:locale" content="en_US" />
					<meta data-react-helmet="true" property="og:type" content="article" />
					<meta data-react-helmet="true" property="og:title" content="Blog - Yoonimoni" />
					<meta
						data-react-helmet="true"
						property="og:description"
						content="The mission of Yoonimoni is to provide investors and beneficiaries with the best possible trust deed investment opportunities. We can bring you the best deals in high return investment funds, commercial real estate loans, and investment properties in California."
					/>
					<meta data-react-helmet="true" property="og:url" content="/blog/" />
					<meta data-react-helmet="true" property="og:site_name" content="Yoonimoni" />
					<meta data-react-helmet="true" property="og:image" content="/about/ezgif.com-webp-to-jpg.jpg" />
					<meta data-react-helmet="true" property="og:image" content="https://yoonimoni.com/og_yoonimoni.png" />
				</Helmet>
				<section id="post">
					<Container fluid>
						<Row
							id="featured-bg"
							style={{
								background: `linear-gradient(rgba(0,0,0,.2), rgba(0,0,0,.2)), url(${
									post._embedded['wp:featuredmedia'] && post._embedded['wp:featuredmedia'][0]?.source_url ? post._embedded['wp:featuredmedia'][0].source_url : ''
								})`,
								backgroundColor: 'rgba(0, 0, 0, 1)',
							}}
						>
							<Col>
								<Container className="bg-transparent">
									<Row className="py-5">
										<Col>
											<Row>
												<Col className="text-center mb-4">
													{post._embedded['wp:term'][0].map((category, i) => {
														return (
															<Link className="no-deco white-font-color category " index={i}>
																{category.name}
															</Link>
														);
													})}
												</Col>
											</Row>
											<h1 className="white-font-color text-center text-capitalize">{post.title.rendered}</h1>
											<Row className="mt-4 post_details white-font-color">
												<Col className="text-center">
													<span className="author">YOONIMOMI</span>
													<span className="date">{formatDate(post.date)}</span>
													<span className="comment">No Comments</span>
												</Col>
											</Row>
										</Col>
									</Row>
								</Container>
							</Col>
						</Row>
					</Container>
					<Container>
						<Row>
							<Col>
								<div className="post-wrapper">
									<div dangerouslySetInnerHTML={{ __html: post.content.rendered }}></div>
								</div>
							</Col>
						</Row>
					</Container>
				</section>
			</Layout>
		);
	}
};

export default PostPage;
