import React from 'react';
import { useLocation, useHistory } from 'react-router-dom';

const Translation = () => {
	let history = useHistory();
	const location = useLocation();
	console.log(location.pathname);

	const changeToKorean = () => {
		let url = location.pathname;
		if (!url.includes('/kr')) {
			url = 'kr' + url;
		}
		history.push(url);
	};
	const changeToEnglish = () => {
		let url = location.pathname;
		if (url.includes('/kr')) {
			history.push(url.replace('/kr', ''));
		}
	};
	return (
		<div id="translation">
			<div id="country">
				<a onClick={changeToEnglish} className={!location.pathname.includes('/kr') ? 'active' : 'deactive'}>
					English <img src="/translation/united-states.png" />
				</a>
				<div style={{ width: '10px' }} />
				<a onClick={changeToKorean} className={location.pathname.includes('/kr') ? 'active' : 'deactive'}>
					한국어 <img src="/translation/south-korea.png" />
				</a>{' '}
				<div style={{ width: '10px' }} />
			</div>
		</div>
	);
};

export default Translation;
