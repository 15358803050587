import React from 'react';
import { Row, Col } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';

const Zoning = ({ data, handle }) => {
	const items = [
		{
			placeholder: 'Zoning',
			name: 'zoning',
			type: 'text',
			grid: {
				sm: '12',
				md: '4',
				lg: '4',
			},
			loanType: 'construction/project',
		},
		{
			placeholder: 'Lot Size',
			name: 'lotSize',
			type: 'text',
			grid: {
				sm: '12',
				md: '4',
				lg: '4',
			},
			loanType: 'construction/project',
		},
	];

	return (
		<Row>
			{items.map((item, index) => {
				return (
					<Col key={index} sm={item.grid.sm ? item.grid.sm : null} md={item.grid.md ? item.grid.md : null} lg={item.grid.lg ? item.grid.lg : null}>
						<Form.Group key={index}>
							<Form.Label>{item.placeholder}</Form.Label>
							<Form.Control value={data[item.name] ? data[item.name] : ''} type="text" name={item.name} onChange={handle} />
						</Form.Group>
					</Col>
				);
			})}
			<Col sm={12} md={4} lg={4}>
				<Form.Label>Land State</Form.Label>
				<Form.Group className="d-flex mt-10px radioBtns">
					<Form.Check
						type="radio"
						label="Improved Land"
						value="improvedLand"
						checked={data.landState === 'improvedLand' ? true : false}
						onChange={handle}
						name={'landState'}
						className="mr-5 my-auto"
					/>
					<Form.Check
						type="radio"
						label="Raw Land"
						value="rawLand"
						checked={data.landState === 'rawLand' ? true : false}
						onChange={handle}
						name={'landState'}
						className="mr-5 my-auto"
					/>
				</Form.Group>
			</Col>
			<Col sm={12} md={4} lg={4}>
				<Form.Label>Ownership</Form.Label>
				<Form.Group className="d-flex mt-10px radioBtns">
					<Form.Check
						type="radio"
						label="Fee Simple"
						value="feeSimple"
						checked={data.ownership === 'feeSimple' ? true : false}
						onChange={handle}
						name={'ownership'}
						className="mr-5 my-auto"
					/>
					<Form.Check
						type="radio"
						label="Ground Lease"
						value="groundLease"
						checked={data.ownership === 'groundLease' ? true : false}
						onChange={handle}
						name={'ownership'}
						className="mr-5 my-auto"
					/>
				</Form.Group>
			</Col>
			<Col sm={12} md={4} lg={4}>
				<Form.Group>
					<Form.Label>Entitlement process completed</Form.Label>
					<Form.Control value={data.entitlementProcess ? data.entitlementProcess : ''} type="text" name={'entitlementProcess'} onChange={handle} />
				</Form.Group>
			</Col>
		</Row>
	);
};

export default Zoning;
