import React from 'react';
import { Row, Col } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';

const Judgements = ({ data, handle }) => {
	return (
		<>
			<Row className="mt-20px">
				<Col sm={12} md={6} lg={6}>
					<Form.Label className='textTransformUnset'>Do You Have a Recent Appraisal?</Form.Label>
					<Form.Group className="d-flex mt-10px radioBtns">
						<Form.Check
							type="radio"
							label="yes"
							value="true"
							checked={data.appraisalReport === 'true' ? true : false}
							onChange={handle}
							name={'appraisalReport'}
							className="mr-5 my-auto"
						/>
						<Form.Check
							type="radio"
							label="no"
							value="false"
							checked={data.appraisalReport === 'false' ? true : false}
							onChange={handle}
							name={'appraisalReport'}
							className="mr-5 my-auto"
						/>
					</Form.Group>
				</Col>
				<Col sm={12} md={6} lg={6}>
					<Form.Label className='textTransformUnset'>Do You Have a Recent Environmental Report?</Form.Label>
					<Form.Group className="d-flex mt-10px radioBtns">
						<Form.Check
							type="radio"
							label="yes"
							value="true"
							checked={data.environmentalReport === 'true' ? true : false}
							onChange={handle}
							name={'environmentalReport'}
							className="mr-5 my-auto"
						/>
						<Form.Check
							type="radio"
							label="no"
							value="false"
							checked={data.environmentalReport === 'false' ? true : false}
							onChange={handle}
							name={'environmentalReport'}
							className="mr-5 my-auto"
						/>
					</Form.Group>
				</Col>
			</Row>
			<Row>
				<Col sm={12}>
					<Form.Group>
						<Form.Label>Exit plan to pay back the loan</Form.Label>
						<InputGroup>
							<Form.Control value={data.paybackPlan ? data.paybackPlan : ''} type={'text'} name={'paybackPlan'} onChange={handle}></Form.Control>
						</InputGroup>
					</Form.Group>
				</Col>
			</Row>
		</>
	);
};

export default Judgements;
