import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { commafy, formatPhoneNumber, calculateLTV } from '../../components/utils/functions';

const Global = ({ data }) => {
	return (
		<Row>
			<Col>
				<span className="text-capitalize">
					<strong>Loan Type: </strong>
					{data.loanType ? data.loanType : ''}
				</span>
			</Col>
			<Col>
				<span className="text-capitalize">
					<strong>Borrowing Entity:</strong> {data.borrowingEntity ? data.borrowingEntity : ''}
				</span>
			</Col>
			<Col>
				<span className="text-capitalize">
					<strong>Property Type:</strong> {data.typeOfProperty ? data.typeOfProperty : ''}
				</span>
			</Col>
		</Row>
	);
};

const Address = ({ data }) => {
	return (
		<Row>
			<Col>
				<span>
					<strong>Subject Property Address: </strong>
					<br />
					{data.subjectPropertyAddr.line1 ? data.subjectPropertyAddr.line1 + ', ' : ''}
					{data.subjectPropertyAddr.line2 ? data.subjectPropertyAddr.line2 + ', ' : ''}
					{data.subjectPropertyAddr.city ? data.subjectPropertyAddr.city + ', ' : ''}
					<span className="text-uppercase">{data.subjectPropertyAddr.state}</span>&nbsp;{data.subjectPropertyAddr.zip} <span className="text-nowrap">{data.subjectPropertyAddr.country}</span>
				</span>
			</Col>
			<Col>
				<span>
					<strong>APN: </strong>
					{data.subjectPropertyAddr.APN}
				</span>
			</Col>
		</Row>
	);
};

const Purchase = ({ data }) => {
	return (
		<>
			<Global data={data} />
			<Address data={data} />
			<Row>
				<Col>
					<span>
						<strong>Loan Amount:</strong> {data.loanAmt ? commafy(data.loanAmt) : ''}
					</span>
				</Col>
				{/* Purchase Price: */}
				<Col>
					<span>
						<strong>Purchase Price:</strong> {data.purchasedPrice ? commafy(data.purchasedPrice) : ''}
					</span>
				</Col>
				<Col>
					<span>
						<strong>Loan to value(LTV):</strong> {data.loanAmt && data.purchasedPrice ? calculateLTV(data).toFixed(2) + '%' : ''}
					</span>
				</Col>
			</Row>
			<Row>
				<Col>
					<span>
						<strong>Down Payment:</strong>{' '}
						{data.downPayment
							? commafy(data.downPayment)
							: data.loanAmt && data.purchasedPrice
								? commafy((data.loanAmt - data.purchasedPrice - data.sellerCarry) * -1)
								: ''}
					</span>
				</Col>
				<Col>
					<span>
						<strong>Interest Rate Offered:</strong> {data.interestRate ? data.interestRate + '%' : ''}
					</span>
				</Col>
				<Col>
					<span>
						<strong>Length of Term:</strong> {data.term}
					</span>
				</Col>
			</Row>
			<Row>
				<Col>
					<span>
						<strong>Any Seller Carry:</strong> {data.sellerCarry ? commafy(data.sellerCarry) : ''}
					</span>
				</Col>

				{/* Escrow Opened */}
				<Col>
					<span>
						<strong>Escrow Opened:</strong> {data.escrowOpened}
					</span>
				</Col>
				<Col>
					<span>
						<strong>Current Estimated Appraisal Value:</strong> {data.currentEstValue ? commafy(data.currentEstValue) : ''}
					</span>
				</Col>
			</Row>
			<hr></hr>
			<Contact data={data} />
		</>
	);
};

const RefiCashOut = ({ data }) => {
	return (
		<>
			<Global data={data} />
			<Address data={data} />
			<Row>
				<Col>
					<span>
						<strong>Loan Amount:</strong> {data.loanAmt ? commafy(data.loanAmt) : ''}
					</span>
				</Col>
				<Col>
					<span>
						<strong>Loan to value(LTV):</strong> {data.loanAmt && data.purchasedPrice ? calculateLTV(data).toFixed(2) + '%' : ''}
					</span>
				</Col>
				<Col>
					<span>
						<strong>Interest Rate Offered:</strong> {data.interestRate ? data.interestRate + '%' : ''}
					</span>
				</Col>
			</Row>
			<Row>
				<Col>
					<span>
						<strong>Length of Term:</strong> {data.term}
					</span>
				</Col>
				<Col>
					<span>
						<strong>Purchase Price:</strong> {data.purchasedPrice ? commafy(data.purchasedPrice) : ''}
					</span>
				</Col><Col>
					<span>
						<strong>Current Estimated Appraisal Value:</strong> {data.currentEstValue ? commafy(data.currentEstValue) : ''}
					</span>
				</Col>
			</Row>
			<MortgageBalance data={data} />
			<Row>
				<Col md={6}>
					<span>
						<strong>Do you have a recent Appraisal Report?:</strong> {data.appraisalReport}
					</span>
				</Col>
				<Col md={6}>
					<span>
						<strong>Do you have a recent Environmental Report?:</strong> {data.environmentalReport}
					</span>
				</Col>
			</Row>
			<Row>
				<Col sm={12}>
					<span>
						<strong>Exit Plan to Pay Back the Loan:</strong> {data.paybackPlan}
					</span>
				</Col>
			</Row>
			<Contact data={data} />
		</>
	)
}
const FixFlip = ({ data }) => {
	return (
		<>
			<Global data={data} />
			<Address data={data} />
			<Row>
				<Col sm={12} md={4}>
					<span>
						<strong>Fix Up Cost:</strong> {data.fixUpCost ? commafy(data.fixUpCost) : ''}
					</span>
				</Col>
			</Row>
			<Row>
				<Col sm={12} md={4}>
					<span>
						<strong>Total Loan Amount:</strong> {commafy(data.loanAmt + data.fixUpCost)}
					</span>
				</Col>
				<Col sm={12} md={4}>
					<span>
						<strong>After Remodeling Value:</strong> {data.afterRemodelingValue}
					</span>
				</Col>
				<Col sm={12} md={4}>
					<span>
						<strong>ARV:</strong> {((data.loanAmt + data.fixUpCost) / data.afterRemodelingValue) * 100}%
					</span>
				</Col>
			</Row>
			<Row>
				<Col sm={12} md={4}>
					<span>
						<strong>Estimated Time To Fix:</strong> {data.estTimeToFix}
					</span>
				</Col>
			</Row>
			<Row>
				<Col sm={12} md={4}>
					<span>
						<strong>Property Remodel/Fixing Experience:</strong> {data.propertyRemodelFixingExp === 'true' ? 'Yes' : 'No'}
					</span>
				</Col>
				{data.propertyRemodelFixingExp === 'true' ? (
					<>
						<Col sm={12} md={4}>
							<span>
								<strong>How Many Properties?:</strong> {data.howManyProperties}
							</span>
						</Col>
						<Col sm={12} md={4}>
							<span>
								<strong>When:</strong> {data.howManyProperties_when}
							</span>
						</Col>
					</>
				) : (
					''
				)}
			</Row>
			<Contact data={data} />
		</>
	);
};

const MortgageBalance = ({ data }) => {
	let totalBalance = 0;
	return data.mortgageBalance.index.map((item, index) => {
		totalBalance = +totalBalance + +item.balanceAmt;
		return (
			<>
				<Row key={index}>
					<Col sm={12} md={3}>
						<span>
							<strong>{index + 1}
								{index + 1 === 1 ? 'st' : ''}
								{index + 1 === 2 ? 'nd' : ''}
								{index + 1 === 3 ? 'rd' : ''} Loan Balance:</strong> {item.balanceAmt ? commafy(item.balanceAmt) : ''}
						</span>
					</Col>
					<Col sm={12} md={3}>
						<span>
							<strong>Lender Type:</strong> {item.lenderType ? item.lenderType : ''}
						</span>
					</Col>
					<Col sm={12} md={3}>
						<span>
							<strong>Maturity Date:</strong> {item.maturityDate ? item.maturityDate : ''}
						</span>
					</Col>
					<Col sm={12} md={3}>
						<span>
							<strong>Current Rate:</strong> {item.currentRate ? item.currentRate + '%' : ''}
						</span>
					</Col>
					{index + 1 === data.mortgageBalance.index.length ? (
						<Col sm={12}>
							<span>
								<strong>Total Amount Owned:</strong> {commafy(totalBalance)}
							</span>
						</Col>
					) : null}
				</Row>
			</>
		);
	});
};

const SecondThird = ({ data }) => {
	return (
		<><Global data={data} />
			<Address data={data} />
			<Row>
				<Col>
					<span>
						<strong>Loan Amount:</strong> {data.loanAmt ? commafy(data.loanAmt) : ''}
					</span>
				</Col>
				<Col>
					<span>
						<strong>Loan to value(LTV):</strong> {data.loanAmt && data.purchasedPrice ? calculateLTV(data).toFixed(2) + '%' : ''}
					</span>
				</Col>
				<Col>
					<span>
						<strong>Interest Rate Offered:</strong> {data.interestRate ? data.interestRate + '%' : ''}
					</span>
				</Col>
			</Row>
			<Row>
				<Col>
					<span>
						<strong>Length of Term:</strong> {data.term}
					</span>
				</Col>
				<Col>
					<span>
						<strong>Purchase Price:</strong> {data.purchasedPrice ? commafy(data.purchasedPrice) : ''}
					</span>
				</Col><Col>
					<span>
						<strong>Current Estimated Appraisal Value:</strong> {data.currentEstValue ? commafy(data.currentEstValue) : ''}
					</span>
				</Col>
			</Row>
			<MortgageBalance data={data} />
			<Row>
				<Col md={6}>
					<span>
						<strong>Do you have a recent Appraisal Report?:</strong> {data.appraisalReport}
					</span>
				</Col>
				<Col md={6}>
					<span>
						<strong>Do you have a recent Environmental Report?:</strong> {data.environmentalReport}
					</span>
				</Col>
			</Row>
			<Row>
				<Col sm={12}>
					<span>
						<strong>Exit Plan to Pay Back the Loan:</strong> {data.paybackPlan}
					</span>
				</Col>
			</Row>
			<Contact data={data} /></>
	)

}

const Contact = ({ data }) => {
	return (
		<>
			<Row>
				<Col sm={12} md={4}>
					<span>
						<strong>Full Name:</strong> {data.firstName + data.lastName}
					</span>
				</Col>
				<Col sm={12} md={4}>
					<span>
						<strong>Email:</strong> {data.email}
					</span>
				</Col>
				<Col sm={12} md={4}>
					<span>
						<strong>Phone:</strong> {formatPhoneNumber(data.phoneNumber)}
					</span>
				</Col>
			</Row>
		</>
	);
};

const Construction = ({ data }) => {
	return (
		<>
			<Global data={data} /><hr></hr>
			<Address data={data} /><hr></hr>
			<Row>
				<Col sm={12} md={4}>
					<span>
						<strong>Zoning:</strong> {data.zoning}
					</span>
				</Col>
				<Col sm={12} md={4}>
					<span>
						<strong>Lot Size:</strong> {data.lotSize}
					</span>
				</Col>
				<Col sm={12} md={4}>
					<span>
						<strong>Land State:</strong> {data.landState === 'improvedLand' ? 'Improved Land' : ''}
						{data.landState === 'rawLand' ? 'Raw Land' : ''}
					</span>
				</Col>
			</Row>
			<Row>
				<Col sm={12} md={6}>
					<span>
						<strong>Ownership:</strong> {data.ownership === 'feeSimple' ? 'Fee Simple' : ''}
						{data.ownership === 'groundLease' ? 'Ground Lease' : ''}
					</span>
				</Col>
				<Col sm={12} md={6}>
					<span>
						<strong>Entitlement Process Completed:</strong> {data.entitlementProcess}
					</span>
				</Col>
			</Row>
			<Row>
				<Col sm={12} md={6}>
					<span>
						<strong>Construction Loan Amount:</strong> {data.loanAmt ? commafy(data.loanAmt) : ''}
					</span>
				</Col>
				<Col sm={12} md={6}>
					<span>
						<strong>Including Land Cost:</strong> {data.includingLandCost === 'true' ? 'Yes' : 'No'}
					</span>
				</Col>
				{data.includingLandCost === 'true' ? (
					<>
						<Col sm={12} md={6}>
							<span>
								<strong>Land Purchase Price:</strong> {data.landPurchase ? commafy(data.landPurchase) : ''}
							</span>
						</Col>
						<Col sm={12} md={6}>
							<span>
								<strong>Down Payment:</strong> {data.landDownPayment ? commafy(data.landDownPayment) : ''}
							</span>
						</Col>
					</>
				) : (
					<>
						<Col sm={12} md={4}>
							<span>
								<strong>Purchased Date:</strong> {data.landPurchasedDate ? commafy(data.landPurchasedDate) : ''}
							</span>
						</Col>
						<Col sm={12} md={4}>
							<span>
								<strong>Land Purchased Price:</strong> {data.landPurchasedPrice ? commafy(data.landPurchasedPrice) : ''}
							</span>
						</Col>
						<Col sm={12} md={4}>
							<span>
								<strong>Current Loan Balance:</strong> {data.landLoanBalance ? commafy(data.landLoanBalance) : ''}
							</span>
						</Col>
					</>
				)}
			</Row>
			<Row>
				<Col>
					<span>
						<strong>Interest Rate Offered:</strong> {data.interestRate ? data.interestRate + '%' : ''}
					</span>
				</Col>
				<Col>
					<span>
						<strong>Length of Term:</strong> {data.term}
					</span>
				</Col>
				<Col>
					<span>
						<strong>Current Estimated Appraisal Value:</strong>  {data.currentEstValue ? commafy(data.currentEstValue) : ''}
					</span>
				</Col>
			</Row>
			<hr></hr>


			<Contact data={data} />
		</>
	);
};

const Bridge = ({ data }) => {
	return (
		<>
			<Global data={data} />
			<Address data={data} />
			<Row>
				<Col>
					<span>
						<strong>Loan Amount:</strong> {data.loanAmt ? commafy(data.loanAmt) : ''}
					</span>
				</Col>
				<Col>
					<span>
						<strong>Loan to value(LTV):</strong> {data.loanAmt && data.purchasedPrice ? calculateLTV(data).toFixed(2) + '%' : ''}
					</span>
				</Col>
				<Col>
					<span>
						<strong>Interest Rate Offered:</strong> {data.interestRate ? data.interestRate + '%' : ''}
					</span>
				</Col>
			</Row>
			<Row>
				<Col>
					<span>
						<strong>Length of Term:</strong> {data.term}
					</span>
				</Col>
				<Col>
					<span>
						<strong>Purchase Price:</strong> {data.purchasedPrice ? commafy(data.purchasedPrice) : ''}
					</span>
				</Col><Col>
					<span>
						<strong>Current Estimated Appraisal Value:</strong> {data.currentEstValue ? commafy(data.currentEstValue) : ''}
					</span>
				</Col>
			</Row>
			<MortgageBalance data={data} />
			<Row>
				<Col md={6}>
					<span>
						<strong>Do you have a recent Appraisal Report?:</strong> {data.appraisalReport}
					</span>
				</Col>
				<Col md={6}>
					<span>
						<strong>Do you have a recent Environmental Report?:</strong> {data.environmentalReport}
					</span>
				</Col>
			</Row>
			<Row>
				<Col sm={12}>
					<span>
						<strong>Exit Plan to Pay Back the Loan:</strong> {data.paybackPlan}
					</span>
				</Col>
			</Row>
			<Contact data={data} />
		</>
	)
}
const LastStep = ({ data }) => {
	/* console.log(data.loanType === 'purchase') */
	return (
		<>
			{ data.loanType && data.loanType === 'purchase' ? <Purchase data={data} /> : ''}
			{ data.loanType && data.loanType === 'refinance' ? <RefiCashOut data={data} /> : ''}
			{ data.loanType && data.loanType === 'fix & flip/Rehab' ? <FixFlip data={data} /> : ''}
			{ data.loanType && data.loanType === '2nd' ? <SecondThird data={data} /> : ''}
			{ data.loanType && data.loanType === '3rd' ? <SecondThird data={data} /> : ''}
			{ data.loanType && data.loanType === 'construction/project' ? <Construction data={data} /> : ''}
			{ data.loanType && data.loanType === 'bridge/mezz' ? <Bridge data={data} /> : ''}
		</>
	)

}


export default LastStep;
