import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { BRStick } from '../../global/svgs';
import { Link } from 'react-router-dom';

const AboutUs = ({ jumpToSectionRef }) => {
	return (
		<section id="home-about-us" ref={jumpToSectionRef}>
			<Container className="py-5">
				<Row className="pt-5">
					<Col xs={12} md={6} className="my-4 overflow-hidden d-flex align-items-center">
						<img alt="piggy bank illust" width="100%" height="auto" src="/banner/home-illust-setting-new-standard.png"></img>
					</Col>
					<Col xs={12} md={6} className="my-4">
						<h2 className="about-us-headline">회사 소개</h2>
						<span className="about-us-subhead">Your Preferred Lender</span>
						<div className="mt-2">
							<BRStick />
						</div>
						<div className="mt-3 pr-3 retina-font">
							<p>
								Yoonimoni는 광범위한 투자자 그룹 및 자금 출처의 네트워크를 통해 주거 및 상업용 부동산 대출 전문회사 입니다. 당사의 풍부한 경험과 준비성 및 유연성 으로
								귀하의 요구를 충족할 수 있는 능력은 당사를 귀하가 선호하는 최적의 대출 기관으로 만듭니다.
							</p>
							<p>Yoonimoni에 연락하시면 귀하의 요청과 요구 사항을 주의 깊게 듣고 다년간의 경험을 가진 업계 전문가가 검토하고 최상의 솔루션을 제시할 것입니다.</p>
						</div>
						<div className="button-wrapper mt-4 row justify-content-center justify-content-sm-start p-3">
							<Link to="/kr/about-us">
								<Button className="primary-button  mr-3 no-deco">
									<span className="button-inner-text">더 알아보기</span>
								</Button>
							</Link>
						</div>
					</Col>
				</Row>
			</Container>
		</section>
	);
};

export default AboutUs;
