import React from 'react';
import { Container, Row, Col, Button, Accordion, Card } from 'react-bootstrap';
import { CenterStick } from '../global/svgs';
import { faqs } from '../global/faqs';
import { Link } from 'react-router-dom';

const ListCreator = ({ array }) => {
	return (
		<ul>
			{array.map((arr, i) => {
				return <li key={i}>{arr}</li>;
			})}
		</ul>
	);
};

const FAQ = ({ activateAccordion, activeAccordion }) => {
	return (
		<section id="home-faq">
			<Container fluid className="py-5">
				<Row className="pt-5 pt-md-0 justify-content-sm-center">
					<Col xs={12} sm={10} md={8} lg={7} className="faq-wrapper">
						<h2 className="faq-headline mb-0">자주 묻는 질문</h2>
						<div className="mt-0 mb-5">
							<CenterStick />
						</div>
						<Accordion>
							{faqs.map((faq, index) => {
								if (index < 5) {
									return (
										<Card key={index} className="faq-card-wrapper">
											<Accordion.Toggle as={Card.Header} eventKey={`${index + 1}`} onClick={e => activateAccordion(`${index + 1}`)} className="faq-card">
												<div className="d-flex justify-content-between">
													<h3 className="faq-header">Q&nbsp;-&nbsp;{faq.q}</h3>
													<span>
														{activeAccordion === index + 1 ? (
															<svg width="14" height="9" viewBox="0 0 14 9" fill="none" xmlns="http://www.w3.org/2000/svg">
																<path d="M1 7.5L7 1.5L13 7.5" stroke="#666666" strokeWidth="1.5" />
															</svg>
														) : (
															<svg width="14" height="9" viewBox="0 0 14 9" fill="none" xmlns="http://www.w3.org/2000/svg">
																<path d="M1 1.5L7 7.5L13 1.5" stroke="#666666" strokeWidth="1.5" />
															</svg>
														)}
													</span>
												</div>
											</Accordion.Toggle>
											<Accordion.Collapse eventKey={`${index + 1}`} className="faq-collapse">
												{typeof faq.a === 'object' ? (
													<Card.Body as={'div'} className="py-0 px-3 my-0 faq-answer">
														<ListCreator array={faq.a} />
													</Card.Body>
												) : (
													<Card.Body className="py-0 px-3 my-0 faq-answer" as="p">
														{faq.a}
													</Card.Body>
												)}
												{/* <Card.Body className="py-0 px-3 my-0 faq-answer" as="p">
													{typeof faq.a === 'object' ? <ListCreator array={faq.a} /> : faq.a}
												</Card.Body> */}
											</Accordion.Collapse>
										</Card>
									);
								} else {
									return null;
								}
							})}
						</Accordion>
					</Col>
					<Col xs={12} className="faq-btn-wrapper mt-5">
						<Button className="primary-button  no-deco">
							{
								<Link to="/kr/faqs">
									<span className="button-inner-text text-no-hover">모든 FAQ 보기</span>
								</Link>
							}
						</Button>
					</Col>
				</Row>
			</Container>
		</section>
	);
};

export default FAQ;
