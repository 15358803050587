import React, { useRef } from 'react';
import Dropzone from 'react-dropzone';
import useResize from '../utils/useResize';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
	dropAreaWrapper: props => ({
		display: 'flex',
		justifyContent: 'space-between',
		position: 'relative',
		marginBottom: '12px',
		marginTop: '8px',
		'& > .overlay': {
			display: 'none',
		},
		'&:hover > .overlay': {
			display: 'block',
			width: '100%',
			height: (props.width / 16) * 9 + 'px',
			lineHeight: (props.width / 16) * 9 + 'px',
			position: 'absolute',
			background: 'rgba(0, 0, 0, 0.5)',
			margin: '0 0 1em',
			marginTop: '16px',
			cursor: 'pointer',
			textAlign: 'center',
			color: 'white',
		},
	}),
	dropArea: props => ({
		border: '1px solid lightgray',
		borderRadius: 4,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		margin: '0 0 1em',
		marginTop: '16px',
		width: '100%',
		height: (props.width / 16) * 9 + 'px',
		cursor: 'pointer',
	}),
	uploadedImage: props => ({
		marginTop: '16px',
		borderRadius: 4,
		width: '100%',
		height: (props.width / 16) * 9 + 'px',
		backgroundSize: 'cover',
		backgroundRepeat: 'no-repeat',
		backgroundPosition: 'center',
		maxHeight: '600px',
		backgroundImage: "url('" + props.imgPath + "')",
		cursor: 'pointer',
	}),
	dropSubhead: {
		color: '#676767',
	},
}));

const thumbsContainer = {
	display: 'flex',
	flexDirection: 'row',
	flexWrap: 'wrap',
	marginTop: 16,
};

const thumb = {
	display: 'inline-flex',
	borderRadius: 2,
	border: '1px solid #eaeaea',
	marginBottom: 8,
	marginRight: 8,
	width: 100,
	height: 100,
	padding: 4,
	boxSizing: 'border-box',
};

const thumbInner = {
	display: 'flex',
	minWidth: 0,
	overflow: 'hidden',
};

const img = {
	display: 'block',
	width: 'auto',
	height: '100%',
};
const ImageDropzone = props => {
	const { FormUploadData, setFormUploadData, Fields, Images, setImages, DeletedImgs, setDeletedImgs, setUploaded } = props;
	const componentRef = useRef();
	const { width } = useResize(componentRef);
	const classes = useStyles({ width, imgPath: Fields.images[0] });

	const onDrop = files => {
		let tmpObj = files.map(file =>
			Object.assign(file, {
				preview: URL.createObjectURL(file),
			})
		);
		if (Images.length + tmpObj.length > 10) {
			setUploaded({
				visibility: true,
				updated: false,
				msg: 'You cannot upload more than 10 images.',
			});
		} else {
			setUploaded({
				visibility: false,
				updated: true,
				msg: '',
			});
			setImages([...Images, ...tmpObj]);
			files.map(file => FormUploadData.append('files', file));
			setFormUploadData(FormUploadData);
		}
	};
	// const onDelete = image => {
	// 	const deleteTarget = Fields.images[0];
	// 	if (deleteTarget === originalImage.path[0]) {
	// 		setOriginalImage({ ...originalImage, deleted: true });
	// 		let newImages = [...Fields.images];
	// 		newImages.splice(0, 1);
	// 		setFields({ ...Fields, images: newImages });
	// 	} else {
	// 		Axios.put('/api/inquiry/put/images/', { imagePath: deleteTarget }).then(response => {
	// 			if (!response.data.success) {
	// 				alert('Failed to delete image');
	// 			} else {
	// 				let newImages = [...Fields.images];
	// 				newImages.splice(0, 1);
	// 				setFields({ ...Fields, images: newImages });
	// 			}
	// 		});
	// 	}
	// };

	const onDelete = image => {
		const currentIndex = Images.indexOf(image);
		if (typeof image === 'string') {
			let deletedImages = [...DeletedImgs];
			deletedImages.push(image);
			setDeletedImgs(deletedImages);
		}
		let newImages = [...Images];
		newImages.splice(currentIndex, 1);
		setImages(newImages);
		FormUploadData.delete(image);
		const newUploadData = new FormData();

		for (var value of FormUploadData.values()) {
			if (value !== image) {
				newUploadData.append('files', value);
			}
		}
		setFormUploadData(newUploadData);
	};

	const thumbs = Images.map((image, i) => {
		return image ? (
			<div style={thumb} key={Images.indexOf(image)} onClick={() => onDelete(image)}>
				<div style={thumbInner}>
					<img src={image.preview ? image.preview : image} style={img} alt={`property-${i}`} />
				</div>
			</div>
		) : (
			<></>
		);
	});

	return (
		<>
			<div className={classes.dropAreaWrapper} ref={componentRef}>
				<Dropzone onDrop={onDrop} multiple={true} maxSize={8000000000}>
					{({ getRootProps, getInputProps }) => (
						<div
							style={{
								width: '100%',
								height: '100px',
								border: '1px solid lightgray',
								alignItems: 'center',
								margin: 'auto',
								display: 'flex',
							}}
							{...getRootProps()}
						>
							<span className="mx-auto">Click or Drop to upload images (Max : 10)</span>
							<input {...getInputProps()} />
						</div>
					)}
				</Dropzone>
			</div>

			{Images.length > 0 ? (
				<div style={{ width: '100%', height: 'auto', overflowX: 'auto' }}>
					<aside style={thumbsContainer}>{thumbs}</aside>
				</div>
			) : null}
		</>
	);
};

export default ImageDropzone;
