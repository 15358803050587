import React, { useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Layout from '../components/global/Layout';

// import downArrow from './../components/HowItWorks/downArrow.png';
// import downArrow from './../../public/HowItWorks/downArrow.png';
// import downMobile from './../components/HowItWorks/downMobile.png';
import { CenterStick, OnelineStick } from '../components/global/svgs';
import { Helmet } from 'react-helmet';
const HowItWorks = props => {
	const currentUserData = props.user.data;

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<Layout currentUserData={currentUserData} history={props.history}>
			<Helmet>
				<meta data-react-helmet="true" charSet="utf-8" />
				<title data-react-helmet="true">How it Works - Yoonimoni</title>
				<meta name="description" content="" />
				<link rel="canonical" data-react-helmet="true" href="https://yoonimoni.com/how-it-works" />
				<meta data-react-helmet="true" property="og:locale" content="en_US" />
				<meta data-react-helmet="true" property="og:type" content="article" />
				<meta data-react-helmet="true" property="og:title" content="How it Works - Yoonimoni" />
				<meta property="og:description" content="" />
				<meta data-react-helmet="true" property="og:url" content="https://yoonimoni.com/how-it-works" />
				<meta data-react-helmet="true" property="og:site_name" content="Yoonimoni" />
				<meta data-react-helmet="true" property="og:image" content="https://yoonimoni.com/og_yoonimoni.png" />
			</Helmet>
			<section className="howItWorks" id="how-it-works">
				<div className="bg-illust-wrapper">
					<figure className="howItWorks-BG" />
				</div>
				<Container>
					<Row>
						<Col>
							<h2 className="main-title mt-71p">How it works</h2>
						</Col>
					</Row>
					<Row>
						<Col>
							<div className="arrow-box mt-3">
								<CenterStick />
								<img className="downArrow mt-3" src="/HowItWorks/downArrow.png" alt="" />
							</div>
						</Col>
					</Row>
					<ol className="mobile-chart-margin">
						{/* 1st row */}
						<li>
							<Row className="list-row">
								<Col className="col-md-7 col-12 step-card">
									<div className="step-img">
										<img className="step-img" src="/HowItWorks/step1.png" alt="first step" />
										<img className="down-mobile" src="/HowItWorks/downMobile.png" alt="pointing bottom right" />
									</div>
									<div className="step-info">
										<h3 className="title">Borrower submits a loan request form.</h3>
										<OnelineStick />
									</div>
								</Col>
								<Col className="row-space row-space-odd">
									<div className="img-box">
										<img className="arrow-img arrow-img-odd " src="/HowItWorks/rightDown.png" alt="pointing bottom right" />
									</div>
								</Col>
							</Row>
						</li>
						{/* 2n row */}
						<li>
							<Row className="list-row">
								<Col className="row-space row-space-even">
									<img className="arrow-img arrow-img-even" src="/HowItWorks/leftDown.png" alt="pointing bottom left" />
								</Col>
								<Col className="col-md-7 col-12 step-card">
									<div className="step-img">
										<img className="step-img" src="/HowItWorks/step2.png" alt="first step" />
										<img className="down-mobile" src="/HowItWorks/downMobile.png" alt="pointing bottom right" />
									</div>
									<div className="step-info">
										<h3 className="title">Yoonimoni contacts borrower for verification & additional information.</h3>
										<OnelineStick />
									</div>
								</Col>
							</Row>
						</li>
						{/* 3st row */}
						<li>
							<Row className="list-row">
								<div className="col-md-7 col-12 step-card">
									<div className="step-img">
										<img className="step-img" src="/HowItWorks/step3.png" alt="first step" />
										<img className="down-mobile" src="/HowItWorks/downMobile.png" alt="pointing bottom right" />
									</div>
									<div className="step-info">
										<h3 className="title">The listing goes up to yoonimoni.com</h3>
										<OnelineStick />
									</div>
								</div>
								<div className="row-space row-space-odd">
									<div className="img-box">
										<img className="arrow-img arrow-img-odd " src="/HowItWorks/rightDown.png" alt="pointing bottom right" />
									</div>
								</div>
							</Row>
						</li>
						{/* 4n row */}
						<li>
							<Row className="list-row">
								<Col className="row-space row-space-even">
									<img className="arrow-img arrow-img-even" src="/HowItWorks/leftDown.png" alt="pointing bottom left" />
								</Col>
								<Col className="col-md-7 col-12 step-card">
									<div className="step-img">
										<img className="step-img" src="/HowItWorks/step4.png" alt="first step" />
										<img className="down-mobile" src="/HowItWorks/downMobile.png" alt="pointing bottom right" />
									</div>
									<div className="step-info">
										<h3 className="title">We connect investors and handle due diligence.</h3>
										<OnelineStick />
									</div>
								</Col>
							</Row>
						</li>{' '}
						{/* 5th row */}
						<li>
							<Row className="list-row">
								<Col className="col-md-7 col-12 step-card">
									<div className="step-img">
										<img className="step-img" src="/HowItWorks/step5.png" alt="first step" />
										<img className="down-mobile" src="/HowItWorks/downMobile.png" alt="pointing bottom right" />
									</div>
									<div className="step-info">
										<h3 className="title">Once the loan is approved, Escrow releases funds.</h3>
										<OnelineStick />
									</div>
								</Col>
								<Col className="row-space row-space-odd">
									<div className="img-box">
										<img className="arrow-img arrow-img-odd " src="/HowItWorks/rightDown.png" alt="pointing bottom right" />
									</div>
								</Col>
							</Row>
						</li>
						{/* 6th row */}
						<li>
							<Row className="list-row">
								<Col className="row-space row-space-even"></Col>
								<Col className="col-md-7 col-12 step-card">
									<div className="step-img">
										<img className="step-img" src="/HowItWorks/step6.png" alt="first step" />
									</div>
									<div className="step-info">
										<h3 className="title">FUNDED!!!</h3>
										<OnelineStick />
									</div>
								</Col>
							</Row>
						</li>
					</ol>
				</Container>
			</section>
		</Layout>
	);
};

export default HowItWorks;
