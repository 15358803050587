import React, { useEffect, useState } from 'react';
import { Carousel } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import axios from 'axios';
const Ribbon = () => {
	const [headlines, setHeadlines] = useState([]);

	const fetch = () => {
		axios.get('/api/setting/').then(res => {
			if (res.status === 200) {
				/* console.log(res.data.headline); */
				setHeadlines(res.data.headline);
			} else {
				console.log('Failed to fetch the headline');
			}
		});
	};

	useEffect(fetch, []);
	if (headlines && headlines.length <= 0) {
		return null;
	} else {
		return (
			<section id="ribbon">
				<Carousel controls={false} indicators={false} interval={3500}>
					{headlines.map((headline, i) => (
						<Carousel.Item key={i} className="text-center hero-ribbon py-2">
							<span>
								{!headline.link && <span className="text-center ellipsis px-2">{headline.text}</span>}

								{headline.link && headline.link.includes('yoonimoni.com') && (
									<Link
										className="no-deco text-center ellipsis px-2"
										to={headline.link.replace('https://', '').replace('http://', '').replace('www.', '').replace('yoonimoni.com', '')}
									>
										{headline.text}
									</Link>
								)}

								{headline.link && !headline.link.includes('yoonimoni.com') && (
									<a className="no-deco text-center ellipsis px-2" href={headline.link} target="_blank">
										{headline.text}
									</a>
								)}
							</span>
						</Carousel.Item>
					))}
				</Carousel>
			</section>
		);
	}
};

export default Ribbon;
