import React, { useState, useRef, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { registerUser } from '../_actions/user_action';
import { Link } from 'react-router-dom';
import ReCAPTCHA from 'react-google-recaptcha';
import Layout from '../components/global/Layout';

/* Material UI */
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import MuiContainer from '@material-ui/core/Container';
import Alert from '@material-ui/lab/Alert';

const REACT_APP_RECAPTCHA_KEY = process.env.REACT_APP_RECAPTCHA_KEY;

const Container = withStyles(theme => ({
	root: {
		[theme.breakpoints.down('xs')]: {
			minHeight: 'unset',
		},
		minHeight: 'calc(100vh - 4rem)',
		alignItems: 'center',
		justifyContent: 'center',
		display: 'flex',
	},
}))(MuiContainer);

const useStyles = makeStyles(theme => ({
	paper: {
		[theme.breakpoints.down('xs')]: {
			paddingTop: theme.spacing(10),
			paddingBottom: theme.spacing(20),
		},
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	form: {
		width: '100%', // Fix IE 11 issue.
		marginTop: theme.spacing(3),
	},
	submit: {
		margin: theme.spacing(3, 0, 2),
		backgroundColor: '#03946d',
		border: '1px solid #03946d',
		borderRadius: '0',
		'&:hover': {
			backgroundColor: '#fafafa',
			color: '#03946d',
		},
	},
	terms: {
		paddingTop: '0 !important',
		paddingBottom: '0 !important',
	},
	formControl: {
		marginTop: '0 !important',
		marginBottom: '0 !important',
	},
}));

const RegisterPage = props => {
	const currentUserData = props.user.data;
	const dispatch = useDispatch();
	const [User, setUser] = useState({
		email: '',
		firstName: '',
		lastName: '',
		password: '',
		confirmPassword: '',
	});
	const [Subscribe, setSubscribe] = useState(true);
	const [Policy, setPolicy] = useState(false);
	const [Warning, setWarning] = useState({
		message: '',
		type: '',
	});
	const reRef = useRef();
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	const onUserHandler = e => {
		const { name, value } = e.target;
		setUser({ ...User, [name]: value });
	};

	const onSubscribeHandler = e => {
		Subscribe ? setSubscribe(false) : setSubscribe(true);
	};

	const onPolicyHandler = e => {
		Policy ? setPolicy(false) : setPolicy(true);
	};

	const onSubmitHandler = async e => {
		e.preventDefault();
		const token = await reRef.current.executeAsync();
		reRef.current.reset();
		// const regexPassword = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
		const regexName = /^[a-z ,.'-]+$/i;

		if (!(User.firstName.match(regexName) && User.lastName.match(regexName))) {
			setWarning({ message: 'First and last name can only contain alphabets', type: 'error' });
			return null;
		}

		if (User.password !== User.confirmPassword) {
			setWarning({ message: 'Password and confirm password do not match', type: 'error' });
			return null;
		}

		// if (!User.password.match(regexPassword)) {
		// 	setWarning({
		// 		message: 'Password must contains minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character',
		// 		type: 'error',
		// 	});
		// 	return null;
		// }

		if (User.password.length <= 6) {
			setWarning({
				message: 'Password must contains minimum six characters.',
				type: 'error',
			});
			return null;
		}

		if (!Policy) {
			setWarning({ message: 'Please check the Terms & Conditions', type: 'error' });
			return null;
		}
		let body = {
			firstName: User.firstName,
			lastName: User.lastName,
			email: User.email,
			password: User.password,
			subscribe: Subscribe,
			token,
		};

		if (props.match.params.id) {
			body = { ...body, referral: props.match.params.id };
		}

		dispatch(registerUser(body)).then(response => {
			if (response.payload.success) {
				props.history.push('/login');
			} else {
				alert('Failed to sign up');
			}
		});
	};

	const classes = useStyles();

	return (
		<Layout currentUserData={currentUserData} history={props.history}>
			<Container component="main" maxWidth="xs">
				<div className={classes.paper}>
					<Typography component="h1" variant="h5">
						Sign up
					</Typography>
					<form className={classes.form} onSubmit={onSubmitHandler}>
						<Grid container spacing={2}>
							{Warning.message ? (
								<Grid item xs={12}>
									<Alert severity={Warning.type}>{Warning.message}</Alert>
								</Grid>
							) : null}
							<Grid item xs={12} sm={6}>
								<TextField
									autoComplete="fname"
									name="firstName"
									variant="outlined"
									required
									fullWidth
									id="firstName"
									label="First Name"
									autoFocus
									value={User.firstName}
									onChange={onUserHandler}
								/>
							</Grid>
							<Grid item xs={12} sm={6}>
								<TextField
									variant="outlined"
									required
									fullWidth
									id="lastName"
									label="Last Name"
									name="lastName"
									autoComplete="lname"
									value={User.lastName}
									onChange={onUserHandler}
								/>
							</Grid>
							<Grid item xs={12}>
								<TextField
									variant="outlined"
									required
									fullWidth
									id="email"
									label="Email Address"
									name="email"
									type="email"
									autoComplete="email"
									value={User.email}
									onChange={onUserHandler}
								/>
							</Grid>
							<Grid item xs={12}>
								<TextField
									variant="outlined"
									required
									fullWidth
									name="password"
									label="Password"
									type="password"
									id="password"
									value={User.password}
									onChange={onUserHandler}
								/>
							</Grid>
							<Grid item xs={12}>
								<TextField
									variant="outlined"
									required
									fullWidth
									name="confirmPassword"
									label="Confirm Password"
									type="password"
									id="confirm-password"
									value={User.confirmPassword}
									onChange={onUserHandler}
								/>
							</Grid>
							<Grid item xs={12} className={classes.terms}>
								<FormControlLabel
									className={classes.formControl}
									control={<Checkbox checked={Subscribe === true ? true : false} onChange={onSubscribeHandler} color="primary" />}
									label={
										<span>
											Subscribe to our company,{' '}
											<a href="https://cozmous.com" target="_blank">
												Cozmous.com
											</a>{' '}
											newsletter.
										</span>
									}
								/>
							</Grid>
							<Grid item xs={12} className={classes.terms}>
								<FormControlLabel
									className={classes.formControl}
									control={<Checkbox checked={Policy === true ? true : false} onChange={onPolicyHandler} color="primary" />}
									label={
										<span>
											I accept the <Link to={'/terms-and-conditions'}>Terms and conditions</Link> and <Link to={'/privacy-policy'}>Privacy Policy</Link>
										</span>
									}
								/>
							</Grid>
						</Grid>
						<Button type="submit" fullWidth variant="contained" color="primary" className={classes.submit}>
							Sign Up
						</Button>
						<Grid container justifyContent="flex-end">
							<Grid item>
								Already have an account?&nbsp;
								<Link to="/login" variant="body2">
									Sign in
								</Link>
							</Grid>
						</Grid>
					</form>
					<ReCAPTCHA sitekey={REACT_APP_RECAPTCHA_KEY} size="invisible" ref={reRef} />
				</div>
			</Container>
		</Layout>
	);
};

export default RegisterPage;
