import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { auth } from '../_actions/user_action';

export default function (SpecificComponent, option, adminRoute = null) {
	//option
	// null => anyone can open
	// true => logged users only
	// false => logged out users only

	const AuthenticationCheck = props => {
		let user = useSelector(state => state.user);
		const dispatch = useDispatch();

		useEffect(() => {
			dispatch(auth()).then(async response => {
				if (await !response.payload.isAuth) {
					if (option) {
						props.history.push('/login');
					}
				} else {
					if (adminRoute && !response.payload.isAdmin) {
						props.history.push('/');
					} else {
						if (option === false) {
							props.history.push('/');
						}
					}
				}
			});
		}, [dispatch, props.history]);

		return <SpecificComponent {...props} user={user} />;
	};
	return AuthenticationCheck;
}
