import React, { useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import DashboardLayout from '../../../components/dashboard/DashboardLayout';

const KRDashboardPage = props => {
	const currentUserData = props.user.data;

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<DashboardLayout currentUserData={currentUserData} history={props.history}>
			<Container>
				<Row>
					<Col>Dashboard Home</Col>
				</Row>
			</Container>
		</DashboardLayout>
	);
};

export default KRDashboardPage;
