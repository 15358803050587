import React, { useState, useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import axios from 'axios';

import DashboardLayout from '../../components/dashboard/DashboardLayout';
import { Container, Row, Col, Button } from 'react-bootstrap';

const SettingPage = props => {
	const currentUserData = props.user.data;
	const [lists, setLists] = useState([]);
	const [headline, setHeadline] = useState({ text: '', link: '' });

	const fetchListItems = () => {
		axios
			.get('/api/setting')
			.then(res => {
				/* console.log(res.data.headline); */
				setLists(res.data.headline);
				return;
			})
			.catch(err => {
				console.log(err);
			});
	};

	useEffect(fetchListItems, []);

	const updateHeadline = array => {
		axios
			.post('/api/setting/update', { headline: array })
			.then(res => {
				console.log('updated headline');
			})
			.catch(err => {
				console.log('axios post did not work');
			});
	};

	const submitHandler = e => {
		e.preventDefault();
		if (!headline.text) return console.log('Text is empty!');
		setLists([...lists, { text: headline.text, link: headline.link }]);
		setHeadline({ text: '', link: '' });
		updateHeadline([...lists, { text: headline.text, link: headline.link }]);
	};

	const DeleteListItem = num => {
		let newHeadlineArray = [...lists];
		newHeadlineArray.splice(num, 1);
		setLists(newHeadlineArray);
		updateHeadline(newHeadlineArray);
	};

	const inputHandler = e => {
		setHeadline({ ...headline, [e.target.name]: e.target.value });
	};

	return (
		<DashboardLayout currentUserData={currentUserData} history={props.history}>
			<Container fluid className="m-26px p-0" id="dashboard-board">
				<Row className="p-0 m-0">
					<Col xs={12} className="p-0">
						<div id="dashboard-form">
							<Row className="p-0 m-0 mb-3">
								<Col xs={12} className="p-0">
									<span className="d-block font-weight-500">Setting</span>
								</Col>
							</Row>
							<Row>
								<Col>
									<Row>
										<Col sm={1} className="d-flex align-items-center">
											<Form.Label>Headline</Form.Label>
										</Col>
										<Col sm={6}>
											<Form.Control type="text" name="text" placeholder="New Headline" value={headline.text} onChange={inputHandler} />
										</Col>
										<Col sm={3}>
											<Form.Control type="url" name="link" placeholder="https://url.com" value={headline.link} onChange={inputHandler} />
										</Col>
										<Col sm={2}>
											<Button className="primary-button mt-2" onClick={submitHandler}>
												Add
											</Button>
										</Col>
									</Row>
								</Col>
							</Row>

							{lists.map((list, i) => {
								return (
									<Row key={i} className="p-2">
										<Col sm="1"></Col>
										<Col sm="6" className="mt-3">
											<span className="ellipsis">{list.text}</span>
										</Col>
										<Col sm="3" className="mt-3">
											<span className="ellipsis">{list.link}</span>
										</Col>
										<Col sm="2">
											<Button
												className="primary-button mt-2"
												onClick={e => {
													e.preventDefault();
													e.stopPropagation();
													DeleteListItem(i);
												}}
											>
												X
											</Button>
										</Col>
									</Row>
								);
							})}
						</div>
					</Col>
				</Row>
			</Container>
		</DashboardLayout>
	);
};

export default SettingPage;
