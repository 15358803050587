import React, { useState } from 'react';
import axios from 'axios';
import { Helmet } from 'react-helmet';
import { Container, Row, Col, Button, Alert } from 'react-bootstrap';
import { Editor } from '@tinymce/tinymce-react';
import Form from 'react-bootstrap/Form';
import Judgements from '../../../../components/Borrowers/Judgements';
import SubjectPropertyAddr from '../../../../components/Borrowers/SubjectPropertyAddr';
import Zoning from '../../../../components/Borrowers/Zoning';
import Construction from '../../../../components/Borrowers/Construction';
import MortgageBalance from '../../../../components/Borrowers/MortgageBalance';
import LoanType from '../../../../components/Borrowers/LoanType';
import BorrowingEntity from '../../../../components/Borrowers/BorrowingEntity';
import PropertyType from '../../../../components/Borrowers/PropertyType';
import Purchase from '../../../../components/Borrowers/Purchase';
import FixFlip from '../../../../components/Borrowers/FixFlip';
import Purpose from '../../../../components/Borrowers/Purpose';
import ContactInformation from '../../../../components/Borrowers/ContactInformation';
import ImageDropzone from '../../../../components/ItemDropzone';
import DashboardLayout from '../../../../components/dashboard/DashboardLayout';

const TINY_MCE_API_KEY = process.env.REACT_APP_TINY_MCE_API_KEY;

const InquiryPage = props => {
	const currentUserData = props.user.data;
	// Images uploader
	const [onLoad, setOnLoad] = useState(false);
	const [Images, setImages] = useState([]);
	const [DeletedImgs, setDeletedImgs] = useState([]);
	const [FormUploadData, setFormUploadData] = useState(new FormData());
	const [uploaded, setUploaded] = useState({
		visibility: false,
		updated: false,
		msg: '',
	});
	const [Warning, setWarning] = useState({
		message: '',
		type: '',
	});

	const emptyTemplate = {
		loanType: '',
		borrowingEntity: '',
		borrowingEntity_desc: '',
		typeOfProperty: '',
		typeOfProperty_desc: '',
		subjectPropertyAddr: {
			line1: '',
			line2: '',
			city: '',
			state: '',
			zip: '',
			country: 'United States',
			APN: '',
		},
		zoning: '',
		lotSize: '',
		landState: '',
		ownership: '',
		entitlementProcess: '',
		includingLandCost: '',
		landPurchase: '',
		landDownPayment: '',
		marketValueAfterCompletion: '',
		landPurchasedDate: '',
		landPurchasedPrice: '',
		landLoanBalance: '',
		description: '',
		occupancy: '',
		loanAmt: '',
		ltv: '',
		downPayment: '',
		sellerCarry: '',
		escrowOpened: '',
		fixUpCost: '',
		afterRemodelingValue: '',
		estTimeToFix: '',
		// finished edit above
		propertyRemodelFixingExp: '',
		howManyProperties: '',
		howManyProperties_when: '',
		purpose: '',
		reqLoanAmt: '',
		currentEstValue: '',
		interestRate: '',
		term: '',
		purchasedDate: '',
		purchasedPrice: '',
		mortgageBalance: {
			index: [
				{
					balanceAmt: '',
					lenderType: '',
					maturityDate: '',
					currentRate: '',
				},
				{
					balanceAmt: '',
					lenderType: '',
					maturityDate: '',
					currentRate: '',
				},
				{
					balanceAmt: '',
					lenderType: '',
					maturityDate: '',
					currentRate: '',
				},
			],
		},
		appraisalReport: '',
		appraisalValue: '',
		environmentalReport: '',
		judgementsOrLiensRecoreded: '',
		totalAmtRecoreded: '',
		dateRecorded: '',
		describeReason: '',
		paybackPlan: '',
		firstName: '',
		lastName: '',
		email: '',
		phoneNumber: '',
		officeMemo: '',
		images: [],
	};
	const [data, setData] = useState(emptyTemplate);
	const radioHandler = (e, name) => {
		const newName = e?.target?.name ? e.target.name : name;
		const newValue = e?.target?.value ? e.target.value : typeof e === 'object' ? e.target.value : e;
		console.log(data);
		setData({ ...data, [newName]: newValue });
	};
	const editorHandler = content => {
		setData({ ...data, officeMemo: content });
	};

	const propertyAddressHandler = e => {
		setData({ ...data, subjectPropertyAddr: { ...data.subjectPropertyAddr, [e.target.name]: e.target.value } });
	};
	const handleSubmit = async (e, status) => {
		e.preventDefault();
		setOnLoad(true);
		let body = Object.assign({}, data);
		body['author'] = currentUserData._id;
		body['status'] = status;
		axios.post('/api/inquiry/post/admin/property', body).then(response => {
			if (response.data.success) {
				const config = {
					header: { 'content-type': 'multipart/form-data' },
				};
				axios.post(`/api/inquiry/post/image/${response.data.data._id}`, FormUploadData, config).then(uploadRes => {
					if (uploadRes.data.success) {
						let newImagesPath = [];
						if (uploadRes.data.uploaded) {
							const imgPaths = uploadRes.data.property.images;
							//Please test with forEach
							imgPaths.map(img => {
								if (!data.images.includes(img)) {
									newImagesPath.push(img);
								}
							});
							setData({ ...data, images: [...data.images, ...newImagesPath] });
							setOnLoad(false);
							setUploaded({
								visibility: true,
								updated: true,
								msg: 'Uploaded successfully.',
							});
							setFormUploadData(new FormData());
							setWarning({ message: 'Uploaded successfully.', type: 'success' });
						} else {
							setOnLoad(false);
							setUploaded({
								visibility: true,
								updated: true,
								msg: 'Uploaded successfully.',
							});
							setFormUploadData(new FormData());
							setWarning({ message: 'Uploaded successfully.', type: 'success' });
						}
						if (DeletedImgs.length > 0) {
							axios.put('/api/inquiry/put/images/', { imagePaths: DeletedImgs }).then(delRes => {
								if (!delRes.data.success) {
									setOnLoad(false);
									setUploaded({
										visibility: true,
										updated: false,
										msg: 'There was an error on updating the property.',
									});
									setWarning({ message: 'There was an error on updating the property.', type: 'danger' });
								} else {
									setOnLoad(false);
									setUploaded({
										visibility: true,
										updated: true,
										msg: 'Uploaded successfully.',
									});
									setDeletedImgs([]);
									setWarning({ message: 'Uploaded successfully.', type: 'success' });
								}
							});
						} else {
							setOnLoad(false);
							setUploaded({
								visibility: true,
								updated: true,
								msg: 'Uploaded successfully.',
							});
							setWarning({ message: 'Uploaded successfully.', type: 'success' });
						}
						// window.scrollTo(0, 0);
					} else {
						setOnLoad(false);
						setUploaded({
							visibility: true,
							updated: false,
							msg: 'Failed to save Image to Server',
						});
						setWarning({ message: 'Failed to save Image to Server', type: 'danger' });
					}
				});
				props.history.push(`/dashboard/admin/edit/inquiry/${response.data.data._id}`);
			} else {
				setWarning({ message: 'Failed to upload the inquiry.', type: 'danger' });
				window.scrollTo(0, 0);
			}
		});
	};
	const mortgageBalanceInputsHandle = (e, i) => {
		const newValue = e.target.value;
		const newName = e.target.name;
		setData(prevState => {
			prevState.mortgageBalance.index[i][newName] = newValue;
			return {
				...prevState,
			};
		});
	};
	const loanTypeRadioHandle = (e, steps) => {
		const newValue = e.target.value;
		setData(prevState => {
			prevState.loanType = newValue;
			return {
				...prevState,
			};
		});
	};
	const contactInputsHandle = e => {
		setData({ ...data, phoneNumber: e });
	};

	const purchaseLoan = () => (
		<>
			<PropertyType handle={radioHandler} data={data} />
			<SubjectPropertyAddr handle={propertyAddressHandler} data={data} />
			<Purchase handle={radioHandler} data={data} />
			<ContactInformation data={data} handle={radioHandler} handle2={contactInputsHandle} />
		</>
	);

	const refiCashOutLoan = () => (
		<>
			<PropertyType handle={radioHandler} data={data} />
			<SubjectPropertyAddr handle={propertyAddressHandler} data={data} />
			<MortgageBalance handle={mortgageBalanceInputsHandle} data={data} number={3} />
			<Purpose data={data} handle={radioHandler} />
			<Judgements data={data} handle={radioHandler} />
			<ContactInformation data={data} handle={radioHandler} handle2={contactInputsHandle} />
		</>
	);

	const fixFlipLoan = () => (
		<>
			<PropertyType handle={radioHandler} data={data} />
			<SubjectPropertyAddr handle={propertyAddressHandler} data={data} />
			<Purchase handle={radioHandler} data={data} />
			<FixFlip handle={radioHandler} data={data} />
			<ContactInformation data={data} handle={radioHandler} handle2={contactInputsHandle} />
		</>
	);

	const secondLoan = () => (
		<>
			<PropertyType handle={radioHandler} data={data} />
			<SubjectPropertyAddr handle={propertyAddressHandler} data={data} />
			<Purpose data={data} handle={radioHandler} />
			<MortgageBalance handle={mortgageBalanceInputsHandle} data={data} number={3} />
			<Judgements data={data} handle={radioHandler} />
			<ContactInformation data={data} handle={radioHandler} handle2={contactInputsHandle} />
		</>
	);

	const thirdLoan = () => (
		<>
			<PropertyType handle={radioHandler} data={data} />
			<SubjectPropertyAddr handle={propertyAddressHandler} data={data} />
			<Purpose data={data} handle={radioHandler} />
			<MortgageBalance handle={mortgageBalanceInputsHandle} data={data} number={3} />
			<Judgements data={data} handle={radioHandler} />
			<ContactInformation data={data} handle={radioHandler} handle2={contactInputsHandle} />
		</>
	);

	const constructionLoan = () => (
		<>
			<SubjectPropertyAddr handle={propertyAddressHandler} data={data} />
			<Zoning data={data} handle={radioHandler} />
			<Construction data={data} handle={radioHandler} />
			<ContactInformation data={data} handle={radioHandler} handle2={contactInputsHandle} />
		</>
	);

	const bridgeLoan = () => (
		<>
			<PropertyType handle={radioHandler} data={data} />
			<SubjectPropertyAddr handle={propertyAddressHandler} data={data} />
			<Purpose data={data} handle={radioHandler} />
			<MortgageBalance handle={mortgageBalanceInputsHandle} data={data} number={3} />
			<Judgements data={data} handle={radioHandler} />
			<ContactInformation data={data} handle={radioHandler} handle2={contactInputsHandle} />
		</>
	);

	const dropZone = () => (
		<>
			<h3>Images</h3>
			<ImageDropzone
				FormUploadData={FormUploadData}
				setFormUploadData={setFormUploadData}
				Fields={data}
				Images={Images}
				setImages={setImages}
				DeletedImgs={DeletedImgs}
				setDeletedImgs={setDeletedImgs}
				setUploaded={setUploaded}
			/>
		</>
	);

	const memoEditor = () => (
		<>
			<h3>Office Memo</h3>
			<Editor
				apiKey={TINY_MCE_API_KEY}
				init={{
					forced_root_block: false,
					placeholder: '',
					height: 300,
					menubar: false,
					plugins: [
						'advlist autolink lists link image',
						'charmap print preview anchor help',
						'searchreplace visualblocks code',
						'insertdatetime media table paste wordcount',
					],
					toolbar: 'undo redo | formatselect | bold italic | alignleft aligncenter alignright | bullist numlist outdent indent | help',
				}}
				value={data.officeMemo}
				onEditorChange={editorHandler}
			/>
		</>
	);

	const detailedloandescription = () => (
		<>
			<h3>Detailed Loan Description</h3>
			<Row>
				<Col sm={12}>
					<Form.Group>
						<Form.Control value={data.loandescription ? data.loandescription : ''} type="text" name={'loandescription'} onChange={radioHandler} />
					</Form.Group>
				</Col>
			</Row>
		</>
	);
	return (
		<DashboardLayout currentUserData={currentUserData} history={props.history}>
			<Helmet>
				<meta data-react-helmet="true" charSet="utf-8" />
				<title data-react-helmet="true">Borrowers - Yoonimoni</title>
				<title
					data-react-helmet="true"
					property="og:description"
					content="Hard money lending made easy! At Yoonimoni, borrowers can submit their property on our page for loan inquiries. All you need is an estimated appraisal value, loan purpose, address, and you’re all done!"
				/>
				<link rel="canonical" data-react-helmet="true" href="https://yoonimoni.com/borrowers/" />
				<meta data-react-helmet="true" property="og:locale" content="en_US" />
				<meta data-react-helmet="true" property="og:type" content="article" />
				<meta data-react-helmet="true" property="og:title" content="Borrowers - Yoonimoni" />
				<title
					data-react-helmet="true"
					property="og:description"
					content="Hard money lending made easy! At Yoonimoni, borrowers can submit their property on our page for loan inquiries. All you need is an estimated appraisal value, loan purpose, address, and you’re all done!"
				/>
				<meta data-react-helmet="true" property="og:url" content="https://yoonimoni.com/borrowers/" />
				<meta data-react-helmet="true" property="og:site_name" content="Yoonimoni" />
				<meta data-react-helmet="true" property="og:image" content="https://yoonimoni.com/og_yoonimoni.png" />
			</Helmet>
			<Container fluid className="m-26px p-0" id="dashboard-board">
				<Row className="p-0 m-0 justify-content-center">
					<Col xs={12} className="p-0">
						<div id="dashboard-form">
							<Row className="p-0 m-0">
								<Col xs={12} className="p-0">
									<span className="d-block font-weight-500 w-100">Update Inquiry</span>
								</Col>
								{Warning.message ? (
									<Col xs={12} className="pb-2 p-0">
										<Alert className="form-input" variant={Warning.type}>
											{Warning.message}
										</Alert>
									</Col>
								) : null}
								<Col xs={12} md={11} lg={12} className="p-0">
									{/* 0 step */}
									{<LoanType handle={loanTypeRadioHandle} data={data} />}
									{<BorrowingEntity handle={radioHandler} data={data} />}
									{data.loanType.length > 0 ? (
										<>
											{data.loanType === 'purchase' ? purchaseLoan() : null}
											{data.loanType === 'refinance' ? refiCashOutLoan() : null}
											{data.loanType === 'fix & flip/Rehab' ? fixFlipLoan() : null}
											{data.loanType === '2nd' ? secondLoan() : null}
											{data.loanType === '3rd' ? thirdLoan() : null}
											{data.loanType === 'construction/project' ? constructionLoan() : null}
											{data.loanType === 'bridge/mezz' ? bridgeLoan() : null}
											{/* agent only */}
											<h2 className="d-block w-100 text-center">Admin Only</h2>
											{detailedloandescription()}
											{dropZone()}
											{memoEditor()}
										</>
									) : null}
								</Col>
							</Row>
							<Row className="mt-2">
								<Col>
									<Button className="primary-button no-radius" onClick={e => handleSubmit(e, 'verified')}>
										Publish
									</Button>
									<Button className="primary-button ml-2 no-radius" onClick={e => handleSubmit(e, 'pending')}>
										Save as pending
									</Button>
								</Col>
							</Row>
						</div>
					</Col>
				</Row>
			</Container>
		</DashboardLayout>
	);
	// } else {
	// 	return <Redirect to={'/'} />;
	// }
};

export default InquiryPage;
