import React, { useState, useEffect } from 'react';
import axios from 'axios';

import { makeStyles, withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import MuiContainer from '@material-ui/core/Container';
import Alert from '@material-ui/lab/Alert';
import Layout from '../components/global/Layout';

const Container = withStyles(theme => ({
	root: {
		[theme.breakpoints.down('xs')]: {
			minHeight: 'unset',
		},
		minHeight: 'calc(100vh - 4rem)',
		alignItems: 'center',
		justifyContent: 'center',
		display: 'flex',
	},
}))(MuiContainer);

const useStyles = makeStyles(theme => ({
	paper: {
		[theme.breakpoints.down('xs')]: {
			paddingTop: theme.spacing(10),
			paddingBottom: theme.spacing(20),
		},
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		width: '100%',
	},
	form: {
		width: '100%', // Fix IE 11 issue.
		marginTop: theme.spacing(1),
	},
	submit: {
		margin: theme.spacing(3, 0, 2),
		backgroundColor: '#03946d',
		border: '1px solid #03946d',
		borderRadius: '0',
		'&:hover': {
			backgroundColor: '#fafafa',
			color: '#03946d',
		},
	},
}));
const ForgotPassword = props => {
	// const dispatch = useDispatch();
	const currentUserData = props.user.data;

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	const [Email, setEmail] = useState('');
	const [Warning, setWarning] = useState({
		message: '',
		type: '',
	});
	const classes = useStyles();

	const onEmailHandler = e => {
		setEmail(e.currentTarget.value);
	};

	const onSubmitHandler = e => {
		e.preventDefault();

		let body = {
			email: Email,
		};

		axios.post('/api/reset/forgotpassword', body).then(response => {
			if (response.data.success) {
				setWarning({ message: 'We have just sent you a link to reset your password. This link is valid for 30 minues!', type: 'success' });
				/* props.history.push(`property/${response.data.id}`); */
			} else {
				setWarning({ message: 'No account found!', type: 'error' });
			}
		});
	};

	return (
		<Layout currentUserData={currentUserData} history={props.history}>
			<Container component="main" maxWidth="xs">
				<div className={classes.paper}>
					<Typography component="h1" variant="h5">
						Forgot Password?
					</Typography>
					<form className={classes.form} onSubmit={onSubmitHandler}>
						{Warning.message ? <Alert severity={Warning.type}>{Warning.message}</Alert> : null}
						<TextField
							variant="outlined"
							margin="normal"
							required
							fullWidth
							id="email"
							label="Email Address"
							name="email"
							autoComplete="email"
							autoFocus
							value={Email}
							onChange={onEmailHandler}
						/>
						<br />
						<Button type="submit" fullWidth variant="contained" color="primary" className={classes.submit}>
							Email Reset Link
						</Button>
					</form>
				</div>
			</Container>
		</Layout>
	);
};

export default ForgotPassword;
