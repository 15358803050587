import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Layout from '../../components/kr/global/Layout';

/* material UI */
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';

const useStyles = makeStyles(theme => ({
	paper: {
		marginTop: theme.spacing(8),
		marginBottom: theme.spacing(20),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	form: {
		width: '100%', // Fix IE 11 issue.
		marginTop: theme.spacing(1),
	},
	submit: {
		margin: theme.spacing(3, 0, 2),
		backgroundColor: '#03946d',
		border: '1px solid #03946d',
		borderRadius: '0',
		'&:hover': {
			backgroundColor: '#fafafa',
			color: '#03946d',
		},
	},
}));

const KRResetPasswordPage = props => {
	const currentUserData = props.user.data;
	const classes = useStyles();
	const token = props.match.params.id;
	const [Email, setEmail] = useState('');
	const [Password, setPassword] = useState({
		newPass: '',
		confirmPass: '',
	});

	const fetch = () => {
		axios.get(`/api/reset/get/email/${token}`).then(res => {
			if (res.data && res.data.user) {
				setEmail(res.data.user.email);
			} else {
				alert('Expired Password Reset Link');
			}
		});
	};

	useEffect(fetch, []);

	const onPasswordHandler = e => {
		const { name, value } = e.target;
		setPassword({ ...Password, [name]: value });
	};

	const onSubmitHandler = e => {
		e.preventDefault();

		if (Password.newPass !== Password.confirmPass) {
			return alert('Password and confirm password do not match');
		}

		let body = {
			email: Email,
			password: Password.newPass,
		};

		axios.put('/api/users/resetPassword', body).then(response => {
			if (response.data.success) {
				props.history.push(`/kr/login/`);
			} else {
				alert('Failed to update password');
			}
		});
	};

	return (
		<Layout currentUserData={currentUserData} history={props.history}>
			<Container component="main" maxWidth="xs">
				<div className={classes.paper}>
					<Typography component="h1" variant="h5">
						Reset Password
					</Typography>
					<form className={classes.form} onSubmit={onSubmitHandler}>
						<Grid item xs={12}>
							<TextField
								variant="outlined"
								margin="normal"
								required
								fullWidth
								name="newPass"
								label="New Password"
								type="password"
								id="password"
								value={Password.newPass}
								onChange={onPasswordHandler}
							/>
						</Grid>
						<Grid item xs={12}>
							<TextField
								variant="outlined"
								required
								margin="normal"
								fullWidth
								name="confirmPass"
								label="Confirm Password"
								type="password"
								id="confirmPass"
								value={Password.confirmPass}
								onChange={onPasswordHandler}
							/>
						</Grid>
						<Button type="submit" fullWidth variant="contained" color="primary" className={classes.submit}>
							Reset
						</Button>
					</form>
				</div>
			</Container>
		</Layout>
	);
};

export default KRResetPasswordPage;
