import React from 'react';
import { Row, Col } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { calculateLTV, showWhichTD } from '../utils/functions';
import CurrencyInput from 'react-currency-input-field';

const Purpose = ({ handle, data }) => {
	return (
		<Row>
			<Col sm={12}>
				<h3>
					{/* {data.loanType === 'refinance' ? 'refinance' : ''}
					{data.loanType === 'fix & flip/Rehab' || data.loanType === 'bridge/mezz' ? data.loanType.replace('-', '/') : ''}
					{data.loanType !== 'refinance' && data.loanType !== 'fix & flip/Rehab' && data.loanType !== 'bridge/mezz' ? data.loanType : ''} Loan */}
					{data.loanType === 'refinance' ? 'refinance' : ''}
					{data.loanType === 'fix & flip/Rehab' || data.loanType === 'bridge/mezz' ? data.loanType.replace('-', '/') : ''}
					{data.loanType !== 'refinance' && data.loanType !== 'fix & flip/Rehab' && data.loanType !== 'bridge/mezz' ? data.loanType : ''} Loan
				</h3>
			</Col>
			<Col sm={12} md={4} lg={4}>
				<Form.Group>
					<Form.Label>Loan Amount</Form.Label>
					<CurrencyInput
						name={'loanAmt'}
						decimalsLimit={2}
						allowNegativeValue={false}
						maxLength={10}
						prefix="$"
						className="form-control"
						onValueChange={(value, name) => handle(value, name)}
						defaultValue={data.loanAmt ? data.loanAmt : ''}
					/>
				</Form.Group>
			</Col>
			{/* <Col sm={12} md={4} lg={4}>
				<Form.Group>
					<Form.Label>Current Estimated Appraisal Value</Form.Label>
					<CurrencyInput
						name={'currentEstValue'}
						decimalsLimit={2}
						allowNegativeValue={false}
						maxLength={10}
						prefix="$"
						className="form-control"
						onValueChange={(value, name) => handle(value, name)}
						defaultValue={data.currentEstValue ? data.currentEstValue : ''}
					/>
				</Form.Group>
			</Col> */}
			<Col sm={12} md={4} lg={4}>
				<Form.Group>
					<Form.Label>{showWhichTD(data.mortgageBalance.index) !== '1st ' ? 'CLTV' : 'LTV'}</Form.Label>
					<InputGroup>
						<Form.Control value={calculateLTV(data).toFixed(2)} type={'number'} name={'ltv'} disabled></Form.Control>
						<InputGroup.Append>
							<InputGroup.Text id="basic-addon1">%</InputGroup.Text>
						</InputGroup.Append>
					</InputGroup>
				</Form.Group>
			</Col>
			<Col sm={12} md={4} lg={4}>
				<Form.Group>
					<Form.Label>Interest Rate Offered</Form.Label>
					<InputGroup>
						<Form.Control value={data.interestRate ? data.interestRate : ''} type={'number'} name={'interestRate'} onChange={handle}></Form.Control>
						<InputGroup.Append>
							<InputGroup.Text id="basic-addon1">%</InputGroup.Text>
						</InputGroup.Append>
					</InputGroup>
				</Form.Group>
			</Col>
			<Col sm={12} md={4} lg={4}>
				<Form.Group>
					<Form.Label>Requsted Term</Form.Label>
					<InputGroup>
						<Form.Control value={data.term ? data.term : ''} type={'text'} name={'term'} onChange={handle}></Form.Control>
					</InputGroup>
				</Form.Group>
			</Col>
			<Col sm={12} md={4} lg={4}>
				<Form.Group>
					<Form.Label>purchased price</Form.Label>
					<CurrencyInput
						name={'purchasedPrice'}
						decimalsLimit={2}
						allowNegativeValue={false}
						maxLength={10}
						prefix="$"
						className="form-control"
						onValueChange={(value, name) => handle(value, name)}
						defaultValue={data.purchasedPrice ? data.purchasedPrice : ''}
					/>
				</Form.Group>
			</Col>
			<Col sm={12} md={4} lg={4}>
				<Form.Group>
					<Form.Label>Current Estimated Appraisal Value</Form.Label>
					<CurrencyInput
						name={'currentEstValue'}
						decimalsLimit={2}
						allowNegativeValue={false}
						maxLength={10}
						prefix="$"
						className="form-control"
						onValueChange={(value, name) => handle(value, name)}
						defaultValue={data.currentEstValue ? data.currentEstValue : ''}
					/>
				</Form.Group>
			</Col>
		</Row>
	);
};

export default Purpose;
