import React from 'react';
import { Row, Col } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import CurrencyInput from 'react-currency-input-field';

const MortgageBalance = ({ handle, data, number }) => {
	const items = [
		{
			placeholder: '1st. loan balance',
			name: 'balanceAmt',
			type: 'number',
			prepend: '$',
			grid: {
				sm: '12',
				md: '3',
				lg: '3',
			},
			i: 0
		},
		{
			placeholder: 'lender type',
			name: 'lenderType',
			type: 'text',
			grid: {
				sm: '12',
				md: '3',
				lg: '3',
			}, i: 0

		},
		{
			placeholder: 'Maturity Date',
			name: 'maturityDate',
			type: 'text',
			grid: {
				sm: '12',
				md: '3',
				lg: '3',
			}, i: 0

		},
		{
			placeholder: 'current rate',
			name: 'currentRate',
			type: 'number',
			append: '%',
			grid: {
				sm: '12',
				md: '3',
				lg: '3',
			}, i: 0

		}, {
			placeholder: '2nd. loan balance',
			name: 'balanceAmt',
			type: 'number',
			prepend: '$',
			grid: {
				sm: '12',
				md: '3',
				lg: '3',
			}, i: 1

		},
		{
			placeholder: 'lender type',
			name: 'lenderType',
			type: 'text',
			grid: {
				sm: '12',
				md: '3',
				lg: '3',
			}, i: 1
		},
		{
			placeholder: 'Maturity Date',
			name: 'maturityDate',
			type: 'text',
			grid: {
				sm: '12',
				md: '3',
				lg: '3',
			}, i: 1
		},
		{
			placeholder: 'current rate',
			name: 'currentRate',
			type: 'number',
			append: '%',
			grid: {
				sm: '12',
				md: '3',
				lg: '3',
			}, i: 1
		}, {
			placeholder: '3rd. loan balance',
			name: 'balanceAmt',
			type: 'number',
			prepend: '$',
			grid: {
				sm: '12',
				md: '3',
				lg: '3',
			}, i: 2

		},
		{
			placeholder: 'lender type',
			name: 'lenderType',
			type: 'text',
			grid: {
				sm: '12',
				md: '3',
				lg: '3',
			}, i: 2
		},
		{
			placeholder: 'Maturity Date',
			name: 'maturityDate',
			type: 'text',
			grid: {
				sm: '12',
				md: '3',
				lg: '3',
			}, i: 2
		},
		{
			placeholder: 'current rate',
			name: 'currentRate',
			type: 'number',
			append: '%',
			grid: {
				sm: '12',
				md: '3',
				lg: '3',
			}, i: 2
		},
	];

	let temp = [];
	let tempStr = [];
	tempStr = items.map((item, index) => {
		return (
			<>
				<Col key={index} sm={item.grid.sm ? item.grid.sm : null} md={item.grid.md ? item.grid.md : null} lg={item.grid.lg ? item.grid.lg : null}>
					<Form.Label>{item.placeholder}</Form.Label>
					{
						item.name === 'balanceAmt' ?
							(
								<>
									<CurrencyInput
										key={index}
										name={item.name}
										decimalsLimit={2}
										allowNegativeValue={false}
										maxLength={10}
										prefix={item.prepend}
										className="form-control"
										onValueChange={(value, name) => handle(value, item.i, name)}
										defaultValue={data.mortgageBalance.index[item.i][item.name] ? data.mortgageBalance.index[item.i][item.name] : ''}
									/>
								</>
							) :
							(
								<InputGroup key={index}>
									{item.prepend ? (
										<InputGroup.Prepend>
											<InputGroup.Text id="basic-addon1">{item.prepend}</InputGroup.Text>
										</InputGroup.Prepend>
									) : null}
									<Form.Control
										type={item.type}
										value={data.mortgageBalance.index[item.i][item.name] ? data.mortgageBalance.index[item.i][item.name] : ''}
										name={item.name}
										onChange={e => handle(e, item.i)}
									></Form.Control>
									{item.append ? (
										<InputGroup.Append>
											<InputGroup.Text id="basic-addon2">{item.append}</InputGroup.Text>
										</InputGroup.Append>
									) : null}
								</InputGroup>
							)
					}
				</Col>
			</>
		);
	});
	temp.push(tempStr);

	return (
		<Row>
			<Col sm={12}>
				<h3>Current Mortgage Balance on Subject Property</h3>
			</Col>
			{temp}
			<Col sm={12} md={3}>
				<Form.Label>Total Amount Owned</Form.Label>
				<CurrencyInput
					disabled
					decimalsLimit={2}
					allowNegativeValue={false}
					maxLength={10}
					prefix="$"
					className="form-control"
					value={data.mortgageBalance.index.reduce((prev, cur) => +prev + + cur.balanceAmt, +0)}
				/>
			</Col>
			<Col sm={12} md={3}></Col>
			<Col sm={12} md={3}></Col>
			<Col sm={12} md={3}></Col>
			<Col sm={12} md={3}></Col>
		</Row>
	);
};

export default MortgageBalance;
