import React from 'react';
import { Row, Col } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';

const PropertyType = ({ handle, data }) => {
	const items = [{ name: 'commercial' }, { name: 'multi-family' }, { name: 'industrial' }, { name: 'res. (1-4)' }, { name: 'land' }, { name: 'other', other: '' }];

	return (
		<Row>
			<Col sm={12}>
				<h3>
					Property Type<sup className="asterisk">*</sup>
				</h3>
				{items.map((item, index) => {
					return (
						<Row key={index}>
							<Col>
								<Form.Check
									key={item.name}
									inline
									label={item.name}
									value={item.name ? item.name : ''}
									checked={item.name === data.typeOfProperty ? true : false}
									type="radio"
									id={`inline-${item.name}`}
									className="d-block"
									onChange={handle}
									name="typeOfProperty"
								/>
								{item.name === 'other' ? (
									<Row>
										<input
											onChange={handle}
											name="typeOfProperty_desc"
											value={data.typeOfProperty_desc ? data.typeOfProperty_desc : ''}
											style={{ visibility: data.typeOfProperty === 'other' ? 'visible' : 'hidden' }}
										></input>
									</Row>
								) : null}
							</Col>
						</Row>
					);
				})}
			</Col>
		</Row>
	);
};

export default PropertyType;
