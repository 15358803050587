import React, { useState } from 'react';
import { Arrowhead } from '../../components/global/svgs';
import { Container, Row, Col, Button, Form, Alert, Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { priceTag } from '../utils/functions';
import PhoneInput from 'react-phone-input-2';

const SideInfo = ({ propertyInfo, currentUserData }) => {
	const [modalShow, setModalShow] = useState(false);
	const [Contact, setContact] = useState({
		firstName: currentUserData && currentUserData.firstName ? currentUserData.firstName : '',
		lastName: currentUserData && currentUserData.lastName ? currentUserData.lastName : '',
		email: currentUserData && currentUserData.email ? currentUserData.email : '',
		phoneNumber: '',
		message: '',
	});

	const [alert, setAlert] = useState({
		status: '',
		message: '',
	});

	const onContactHandler = e => {
		const { name, value } = e.target;

		setContact({ ...Contact, [name]: value });
	};

	const phoneNumberHandler = e => {
		setContact({ ...Contact, phoneNumber: e });
	};

	const onSubmitHandler = async e => {
		e.preventDefault();
		setAlert({ status: 'secondary', message: 'Sending a message...' });
		let body = Object.assign({}, Contact);
		body = { ...body, propertyInfo };
		axios.post('/api/mail/invest', body).then(res => {
			if (res.data.success) {
				setAlert({ status: 'success', message: 'We will be contacting you shortly!' });
			} else {
				// alert('Failed to send message!');
				setAlert({ status: 'warning', message: 'Failed to send message!' });
			}
		});
	};

	return (
		<section className="side-info w-100 bg-white mb-5">
			<Container className="bg-white pr-0">
				<Row>
					<Col className="p-0">
						<span className="d-block mt-2">
							Total Loan Amount:
							<span className="side-info-price d-block mt-1">
								{propertyInfo.loanType === 'fix & flip/Rehab' ? priceTag(propertyInfo.loanAmt + propertyInfo.fixUpCost) : priceTag(propertyInfo.loanAmt)}
							</span>
						</span>
						<span className="d-block mt-3">
							Loan Type: <span className="side-loan-type text-capitalize">{propertyInfo.loanType}</span>
						</span>
						<span className="d-block mt-2">Contact:</span>
						<div className="side-contact">
							<span className="d-block mt-2 ml-0">
								<Arrowhead />
								<span>john@yoonimoni.com</span>
							</span>

							<span className="d-block mt-2 ml-0">
								<Arrowhead />
								<span>Los Angeles, CA</span>
							</span>
						</div>
						{propertyInfo.status === 'complete' ? (
							<Button className="primary-button mr-3 mt-3 funded-disabled" disabled>
								<span className="button-inner-text">FUNDED!</span>
							</Button>
						) : currentUserData && currentUserData.isAuth ? (
							currentUserData.role === 'admin' ? (
								<>
									<Button className="primary-button mr-3 mt-3" onClick={() => setModalShow(true)}>
										<span className="button-inner-text">Invest!</span>
									</Button>
									<Link to={`/dashboard/admin/edit/inquiry/${propertyInfo._id}`}>
										<Button className="primary-invert-button mr-3 mt-3">
											<span className="button-inner-text">Edit</span>
										</Button>
									</Link>
								</>
							) : (
								<Button className="primary-button mr-3 mt-3" onClick={() => setModalShow(true)}>
									<span className="button-inner-text">Invest!</span>
								</Button>
							)
						) : (
							<Link to="/login">
								<Button className="primary-button mr-3 mt-3">
									<span className="button-inner-text">Login to Invest</span>
								</Button>
							</Link>
						)}
						<Modal show={modalShow} onHide={() => setModalShow(false)} centered>
							<Modal.Header closeButton>
								<Modal.Title>Want to Invest?</Modal.Title>
							</Modal.Header>
							<Modal.Body>
								<Container className="bg-white px-0">
									<form onSubmit={onSubmitHandler}>
										<Row>
											<Col xs={12}>{alert.message ? <Alert variant={alert.status}>{alert.message}</Alert> : <></>}</Col>
											<Col xs={12} sm={6} className="pr-sm-2 pl-sm-3 px-3 mb-3">
												<Form.Control
													required
													type="text"
													name="firstName"
													value={Contact.firstName}
													onChange={onContactHandler}
													placeholder="First Name"
												/>
											</Col>
											<Col xs={12} sm={6} className="pl-sm-2 pr-sm-3 px-3 mb-3">
												<Form.Control required type="text" name="lastName" value={Contact.lastName} onChange={onContactHandler} placeholder="Last Name" />
											</Col>
											<Col xs={12} className="px-3 mb-3">
												<Form.Control
													required
													type="email"
													name="email"
													autoComplete="email"
													value={Contact.email}
													onChange={onContactHandler}
													placeholder="Email Address"
												/>
											</Col>
											<Col xs={12} className="px-3 mb-3">
												<PhoneInput
													specialLabel={false}
													country="us"
													onlyCountries={['us']}
													disableDropdown
													disableCountryCode
													value={Contact?.phoneNumber ? Contact.phoneNumber.toString() : ''}
													placeholder={'Phone Number'}
													name="phoneNumber"
													onChange={phoneNumberHandler}
												/>
											</Col>
											<Col xs={12} className="px-3 mb-3">
												<Form.Control
													required
													name="message"
													value={Contact.message}
													onChange={onContactHandler}
													as="textarea"
													rows="5"
													style={{ resize: 'none' }}
													placeholder="Your Message"
												/>
											</Col>
										</Row>

										<div className="button-wrapper mt-2">
											<Button type="submit" className="primary-button  mr-3">
												<span className="button-inner-text">Send Message</span>
											</Button>
										</div>
									</form>
								</Container>
							</Modal.Body>
						</Modal>
					</Col>
				</Row>
			</Container>
		</section>
	);
};

export default SideInfo;
