import React, { useEffect } from 'react';
import './styles/App.scss';
import { Switch, Route, useLocation } from 'react-router-dom';
import Homepage from './pages/Home';
import LoginPage from './pages/Login';
import RegisterPage from './pages/Register';
import ForgotPasswordPage from './pages/ForgotPassword';
import Auth from './hoc/auth';
import BorrowersPage from './pages/Borrowers';
import BorrowerPage from './pages/Borrower';
import InvestorsPage from './pages/Investors';
import FundedPage from './pages/Funded';
import BlogPage from './pages/Blog';
import PostPage from './pages/Post';
import AboutUsPage from './pages/AboutUs';
import ResetPasswordPage from './pages/ResetPassword';
import NotFoundPage from './pages/NotFound';
import TermsAndConditionsPage from './pages/TermsAndConditions';
import SingleItemPage from './pages/SingleItem';
import HowItWorksPage from './pages/HowItWorks';
import LoanParametersPage from './pages/LendingGuidelines';

// Dashboard
import UsersPage from './pages/dashboard/Users';
import MyAccountPage from './pages/dashboard/MyAccount';
import MyRequestsPage from './pages/dashboard/MyInquiries';
import DashboardPage from './pages/dashboard';
import UserPage from './pages/dashboard/admin/edit/User';
import InquiriesPage from './pages/dashboard/Inquiries';
import MarketingPage from './pages/dashboard/Marketing';
import AdminUpdateInquiryPage from './pages/dashboard/admin/edit/Inquiry';
import UserUpdateInquiryPage from './pages/dashboard/user/edit/Inquiry';
import InquiryPage from './pages/dashboard/admin/create/Inquiry';
import SettingPage from './pages/dashboard/Setting';
import ReferralPage from './pages/dashboard/Referral';

import Footer from './components/global/Footer';
import FAQsPage from './pages/FAQs';
import PrivacyPolicyPage from './pages/PrivacyPolicy';
import RedirectPage from './pages/Redirect';
import FeeSchedulePage from './pages/FeeSchedule';
import CompletionPage from './pages/Completion';

/**********************************
 * 			KR IMPORT			  *
 *********************************/
import KRHomepage from './pages/kr/Home';
import KRLoginPage from './pages/kr/Login';
import KRRegisterPage from './pages/kr/Register';
import KRForgotPasswordPage from './pages/kr/ForgotPassword';
import KRBorrowersPage from './pages/kr/Borrowers';
import KRBorrowerPage from './pages/kr/Borrower';
import KRInvestorsPage from './pages/kr/Investors';
import KRBlogPage from './pages/kr/Blog';
import KRPostPage from './pages/kr/Post';
import KRAboutUsPage from './pages/kr/AboutUs';
import KRResetPasswordPage from './pages/kr/ResetPassword';
import KRNotFoundPage from './pages/kr/NotFound';
import KRTermsAndConditionsPage from './pages/kr/TermsAndConditions';
import KRSingleItemPage from './pages/kr/SingleItem';
import KRHowItWorksPage from './pages/kr/HowItWorks';
import KRLoanParametersPage from './pages/kr/LendingGuidelines';
import KRFAQsPage from './pages/kr/FAQs';
import KRPrivacyPolicyPage from './pages/kr/PrivacyPolicy';
import KRRedirectPage from './pages/kr/Redirect';
import KRFeeSchedulePage from './pages/kr/FeeSchedule';
import KRCompletionPage from './pages/kr/Completion';

import KRFooter from './components/kr/global/Footer';

// KR Dashboard
import KRUsersPage from './pages/kr/dashboard/Users';
import KRMyAccountPage from './pages/kr/dashboard/MyAccount';
import KRMyRequestsPage from './pages/kr/dashboard/MyInquiries';
import KRDashboardPage from './pages/kr/dashboard';
import KRUserPage from './pages/kr/dashboard/admin/edit/User';
import KRInquiriesPage from './pages/kr/dashboard/Inquiries';
import KRMarketingPage from './pages/kr/dashboard/Marketing';
import KRAdminUpdateInquiryPage from './pages/kr/dashboard/admin/edit/Inquiry';
import KRUserUpdateInquiryPage from './pages/kr/dashboard/user/edit/Inquiry';
import KRInquiryPage from './pages/kr/dashboard/admin/create/Inquiry';
import KRSettingPage from './pages/kr/dashboard/Setting';
import KRReferralPage from './pages/kr/dashboard/Referral';

//Google Analytics
import ReactGa from 'react-ga';

const App = props => {
	const currPath = useLocation().pathname;

	useEffect(() => {
		ReactGa.initialize('UA-179313814-1');
		//to report page view
		ReactGa.pageview(window.location.pathname + window.location.search);
	}, []);
	// null => anyone can open
	// true => logged users only
	// false => logged out users only
	return (
		<>
			<Switch>
				<Route exact path="/" component={Auth(Homepage, null)} />
				<Route exact path="/login" component={Auth(LoginPage, false)} />
				<Route exact path="/register/:id?" component={Auth(RegisterPage, false)} />
				<Route exact path="/forgotpassword" component={Auth(ForgotPasswordPage, false)} />
				<Route exact path="/borrower" component={Auth(BorrowerPage, null)} />
				<Route exact path="/borrowers" component={Auth(BorrowersPage, null)} />
				<Route exact path="/completion" component={Auth(CompletionPage, null)} />
				<Route exact path="/investors" component={Auth(InvestorsPage, null)} />
				<Route exact path="/funded" component={Auth(FundedPage, null)} />
				<Route exact path="/blog" component={Auth(BlogPage, null)} />
				{/* <Route exact path="/property/:id" component={PropertyPage} /> */}
				<Route exact path="/property/:id" component={Auth(SingleItemPage, null)} />
				<Route exact path="/post/:id" component={Auth(PostPage, null)} />
				<Route exact path="/about-us" component={Auth(AboutUsPage, null)} />
				<Route exact path="/how-it-works" component={Auth(HowItWorksPage, null)} />
				<Route path="/reset/:id" component={Auth(ResetPasswordPage, false)} />
				<Route exact path="/terms-and-conditions" component={Auth(TermsAndConditionsPage, null)} />
				<Route exact path="/privacy-policy" component={Auth(PrivacyPolicyPage, null)} />
				<Route exact path="/fee-schedule" component={Auth(FeeSchedulePage, null)} />
				<Route exact path="/faqs" component={Auth(FAQsPage, null)} />
				<Route exact path="/i/:id" component={Auth(RedirectPage, null)} />
				<Route exact path="/loan-parameters" component={Auth(LoanParametersPage, null)} />
				<Route exact path="/404" component={Auth(NotFoundPage, null)} />
				{/* dashboard */}
				<Route exact path={`/dashboard`} component={Auth(DashboardPage, true)} />
				<Route exact path={`/dashboard/my-inquiries`} component={Auth(MyRequestsPage, true)} />
				<Route exact path={`/dashboard/my-account`} component={Auth(MyAccountPage, true)} />
				<Route exact path={`/dashboard/users`} component={Auth(UsersPage, true)} />
				<Route exact path={`/dashboard/user/:uid`} component={Auth(UserPage, true)} />
				<Route exact path={`/dashboard/inquiries`} component={Auth(InquiriesPage, true)} />
				<Route exact path={`/dashboard/marketing`} component={Auth(MarketingPage, true)} />
				<Route exact path={`/dashboard/admin/edit/inquiry/:id`} component={Auth(AdminUpdateInquiryPage, null)} />
				<Route exact path={`/dashboard/user/edit/inquiry/:id`} component={Auth(UserUpdateInquiryPage, true)} />
				<Route exact path={`/dashboard/admin/create/inquiry`} component={Auth(InquiryPage, true)} />
				<Route exact path={`/dashboard/setting`} component={Auth(SettingPage, true)} />
				<Route exact path={`/dashboard/referral`} component={Auth(ReferralPage, true)} />
				{/* 
					KR 
				*/}
				<Route exact path="/kr/" component={Auth(KRHomepage, null)} />
				<Route exact path="/kr/login" component={Auth(KRLoginPage, false)} />
				<Route exact path="/kr/register/:id?" component={Auth(KRRegisterPage, false)} />
				<Route exact path="/kr/forgotpassword" component={Auth(KRForgotPasswordPage, false)} />
				<Route exact path="/kr/borrower" component={Auth(KRBorrowerPage, null)} />
				<Route exact path="/kr/borrowers" component={Auth(KRBorrowersPage, null)} />
				<Route exact path="/kr/completion" component={Auth(KRCompletionPage, null)} />
				<Route exact path="/kr/investors" component={Auth(KRInvestorsPage, null)} />
				<Route exact path="/kr/blog" component={Auth(KRBlogPage, null)} />
				{/* <Route exact path="/property/:id" component={PropertyPage} /> */}
				<Route exact path="/kr/property/:id" component={Auth(KRSingleItemPage, null)} />
				<Route exact path="/kr/post/:id" component={Auth(KRPostPage, null)} />
				<Route exact path="/kr/about-us" component={Auth(KRAboutUsPage, null)} />
				<Route exact path="/kr/how-it-works" component={Auth(KRHowItWorksPage, null)} />
				<Route path="/kr/reset/:id" component={Auth(KRResetPasswordPage, false)} />
				<Route exact path="/kr/terms-and-conditions" component={Auth(KRTermsAndConditionsPage, null)} />
				<Route exact path="/kr/privacy-policy" component={Auth(KRPrivacyPolicyPage, null)} />
				<Route exact path="/kr/fee-schedule" component={Auth(KRFeeSchedulePage, null)} />
				<Route exact path="/kr/faqs" component={Auth(KRFAQsPage, null)} />
				<Route exact path="/kr/i/:id" component={Auth(KRRedirectPage, null)} />
				<Route exact path="/kr/loan-parameters" component={Auth(KRLoanParametersPage, null)} />
				<Route exact path="/kr/404" component={Auth(KRNotFoundPage, null)} />
				{/* dashboard */}
				<Route exact path={`/kr/dashboard`} component={Auth(KRDashboardPage, true)} />
				<Route exact path={`/kr/dashboard/my-inquiries`} component={Auth(KRMyRequestsPage, true)} />
				<Route exact path={`/kr/dashboard/my-account`} component={Auth(KRMyAccountPage, true)} />
				<Route exact path={`/kr/dashboard/users`} component={Auth(KRUsersPage, true)} />
				<Route exact path={`/kr/dashboard/user/:uid`} component={Auth(KRUserPage, true)} />
				<Route exact path={`/kr/dashboard/inquiries`} component={Auth(KRInquiriesPage, true)} />
				<Route exact path={`/kr/dashboard/marketing`} component={Auth(KRMarketingPage, true)} />
				<Route exact path={`/kr/dashboard/admin/edit/inquiry/:id`} component={Auth(KRAdminUpdateInquiryPage, null)} />
				<Route exact path={`/kr/dashboard/user/edit/inquiry/:id`} component={Auth(KRUserUpdateInquiryPage, true)} />
				<Route exact path={`/kr/dashboard/admin/create/inquiry`} component={Auth(KRInquiryPage, true)} />
				<Route exact path={`/kr/dashboard/setting`} component={Auth(KRSettingPage, true)} />
				<Route exact path={`/kr/dashboard/referral`} component={Auth(KRReferralPage, true)} />
				<Route component={Auth(NotFoundPage, null)} />
			</Switch>
			<Footers />
			{/* {currPath.includes('dashboard') || currPath === '/' || currPath === '/kr' ? null : <Footer />} */}
		</>
	);
};

const Footers = () => {
	const currPath = useLocation().pathname;

	if (currPath.includes('dashboard') || currPath === '/' || currPath === '/kr/' || currPath === '/kr') {
		return <></>;
	} else if (currPath.includes('/kr')) {
		return <KRFooter />;
	} else {
		return <Footer />;
	}
};
export default App;
