import React, { useRef } from 'react';
import GoogleMapReact from 'google-map-react';
import { Container, Row, Col, Carousel, Button } from 'react-bootstrap';
import { showWhichTD, priceTag, shortenPrice, DisplayLTV } from '../utils/functions';
import { Link } from 'react-router-dom';

const GOOGLE_MAP_API = process.env.REACT_APP_GOOGLE_MAP_API_KEY;

const Chip = ({ property, propHover, setPropHover, propClick, setPropClick }) => {
	return (
		<div>
			<Button
				className={`label-chip ${propHover === property._id ? `selected` : ``} border-1-primary-color`}
				onMouseEnter={e => {
					e.preventDefault();
					setPropHover(property._id);
				}}
				onMouseLeave={e => {
					e.preventDefault();
					setPropHover(null);
				}}
				onClick={e => {
					e.preventDefault();
					e.stopPropagation();
					if (propClick === property._id) {
						setPropClick(null);
					} else {
						setPropClick(property._id);
					}
				}}
			>
				<span>{`$${shortenPrice(property.loanAmt)}`}</span>
			</Button>
			<CardChip property={property} propClick={propClick} />
		</div>
	);
};

const CardChip = ({ property, propClick }) => {
	return (
		<Container style={{ display: propClick === property._id ? 'block' : 'none' }} className="card-modal bg-white" onClick={e => e.stopPropagation()}>
			<Row>
				<Carousel
					indicators={true}
					interval={null}
					nextIcon={
						<div className="nextIcon">
							<svg width="5" height="8" viewBox="0 0 5 8" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M1 0.999969L4 3.99997L1 6.99997" stroke="#303030" />
							</svg>
						</div>
					}
					prevIcon={
						<div className="prevIcon">
							<svg width="5" height="8" viewBox="0 0 5 8" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M4 0.999969L1 3.99997L4 6.99997" stroke="#303030" />
							</svg>
						</div>
					}
				>
					{property.images.length > 0 ? (
						property.images.map((image, index) => (
							<Carousel.Item key={index}>
								<Link to={`/property/${property._id}`} className="text-decoration-none invest-cta">
									<Container fluid>
										<Row className="header">
											<Col>
												<img
													src={image ? image : '/property/image-from-rawpixel-id-558306-jpeg.jpg'}
													width="225px"
													height="160px"
													alt="invest property"
													className="invest-property-img"
													onError={e => {
														e.target.onerror = null;
														e.target.src = '/property/image-from-rawpixel-id-558306-jpeg.jpg';
													}}
												/>
											</Col>
										</Row>
									</Container>
								</Link>
							</Carousel.Item>
						))
					) : (
						<Carousel.Item key="no-image">
							<Link to={`/property/${property._id}`} className="text-decoration-none invest-cta">
								<Container fluid>
									<Row className="header">
										<Col>
											<img
												src="/property/image-from-rawpixel-id-558306-jpeg.jpg"
												width="225px"
												height="160px"
												alt="invest property"
												className="invest-property-img"
												onError={e => {
													e.target.onerror = null;
													e.target.src = '/property/image-from-rawpixel-id-558306-jpeg.jpg';
												}}
											/>
										</Col>
									</Row>
								</Container>
							</Link>
						</Carousel.Item>
					)}
				</Carousel>
				<Row className="content m-8px w-100">
					<Link to={`/property/${property._id}`} className="text-decoration-none invest-cta">
						<Col>
							<Row className="">
								<Col>
									<span className="invest-card-subhead text-capitalize">{property.loanType}</span>
								</Col>
							</Row>
							<Row>
								<Col>
									<span className="invest-amt-val">{property.loanAmt ? `${priceTag(property.loanAmt)}` : '-'}</span>
								</Col>
								<Col className="text-right">
									<span className="invest-interest ">Interest: {property.interestRate ? property.interestRate : '-'}%</span>
								</Col>
							</Row>
							<hr className="mx-21px"></hr>
							<Row>
								<Col>
									<span className="d-block">
										City: <span className="bold-black">{property.subjectPropertyAddr.city ? property.subjectPropertyAddr.city : '-'}</span>
									</span>
									<span className="d-block">
										Type of Loan Request: <span className="bold-black">{property.loanType}</span>
									</span>
									<span className="d-block">
										<DisplayLTV singleProp={property} color="black" />
									</span>
									<span className="d-block">
										Lien Position: <span className="bold-black">{property.lienPos ? property.lienPos : showWhichTD(property.mortgageBalance.index)}
										</span>
									</span>
								</Col>
							</Row>
						</Col>
					</Link>
				</Row>
			</Row>
		</Container>
	);
};
const Maps = ({ setCenter, Center, setMapBounds, properties, propHover, setPropHover, propClick, setPropClick }) => {
	const googleMapRef = useRef();

	const zoom = 9;

	const handleChange = ({ center, bounds, zoom }) => {
		setMapBounds(bounds);
		setCenter(center);
	};
	const handleMapClick = () => {
		setPropClick(null);
	};

	return (
		<section id="inner-map" className="w-100">
			<GoogleMapReact
				ref={googleMapRef}
				bootstrapURLKeys={{ key: GOOGLE_MAP_API }}
				defaultCenter={{
					lat: 34.5414563,
					lng: -118.6166781,
				}}
				// center={{
				// 	lat: Center.lat ? Center.lat : 34.052235,
				// 	lng: Center.lng ? Center.lng : -118.243683,
				// }}
				center={Center}
				defaultZoom={zoom}
				onChange={handleChange}
				options={{
					maxZoom: 13,
					clickableIcons: false,
				}}
				onChildClick={handleMapClick}
				onClick={handleMapClick}
				onDrag={handleMapClick}
				width="100%"
			>
				{properties.map((property, index) => {
					if (property.subjectPropertyAddr && property.subjectPropertyAddr.location) {
						return (
							<Chip
								key={index}
								property={property}
								lat={property.subjectPropertyAddr.location.coordinates[1]}
								lng={property.subjectPropertyAddr.location.coordinates[0]}
								propHover={propHover}
								propClick={propClick}
								setPropClick={setPropClick}
								setPropHover={setPropHover}
								className="hover-z-index"
							/>
						);
					} else {
						return null;
					}
				})}
			</GoogleMapReact>
		</section>
	);
};

export default Maps;
