import React from 'react';
import { Row, Col } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import CurrencyInput from 'react-currency-input-field';
import { calculateLTV } from '../utils/functions';

const Construction = ({ data, handle }) => {
	return (
		<Row>
			<Col sm={12}>
				<h3>Construction/Project Loan</h3>
			</Col>
			<Col sm={12} md={6} lg={6}>
				<Form.Group>
					<Form.Label>Construction Loan Amount</Form.Label>
					<CurrencyInput
						name={'loanAmt'}
						decimalsLimit={2}
						allowNegativeValue={false}
						maxLength={10}
						prefix="$"
						className="form-control"
						onValueChange={(value, name) => handle(value, name)}
						defaultValue={data.loanAmt ? data.loanAmt : ''}
					/>
				</Form.Group>
			</Col>
			<Col sm={12} md={6} lg={6}>
				<Form.Label>Including Land Cost?</Form.Label>
				<Form.Group className="d-flex mt-10px radioBtns">
					<Form.Check
						type="radio"
						label="yes"
						value="true"
						checked={data.includingLandCost === 'true' ? true : false}
						onChange={handle}
						name={'includingLandCost'}
						className="mr-5 my-auto"
					/>
					<Form.Check
						type="radio"
						label="no"
						value="false"
						checked={data.includingLandCost === 'false' ? true : false}
						onChange={handle}
						name={'includingLandCost'}
						className="mr-5 my-auto"
					/>
				</Form.Group>
			</Col>
			{data.includingLandCost === 'true' ? (
				<>
					<Col md={6}>
						<Form.Group>
							<Form.Label>Land Purchase</Form.Label>
							<CurrencyInput
								name={'landPurchase'}
								decimalsLimit={2}
								allowNegativeValue={false}
								maxLength={10}
								prefix="$"
								className="form-control"
								onValueChange={(value, name) => handle(value, name)}
								defaultValue={data.landPurchase ? data.landPurchase : ''}
							/>
						</Form.Group>
					</Col>
					<Col md={6}>
						<Form.Group>
							<Form.Label>Down Payment</Form.Label>
							<CurrencyInput
								name={'landDownPayment'}
								decimalsLimit={2}
								allowNegativeValue={false}
								maxLength={10}
								prefix="$"
								className="form-control"
								onValueChange={(value, name) => handle(value, name)}
								defaultValue={data.landDownPayment ? data.landDownPayment : ''}
							/>
						</Form.Group>
					</Col>
					<Col md={6}>
						<Form.Group>
							<Form.Label>Total Loan Amount</Form.Label>
							<CurrencyInput
								name={'totalLoanAmt'}
								decimalsLimit={2}
								allowNegativeValue={false}
								maxLength={10}
								prefix="$"
								className="form-control"
								value={data.landPurchase && data.landDownPayment && data.loanAmt ? data.landPurchase - data.landDownPayment + parseInt(data.loanAmt) : ''}
							/>
						</Form.Group>
					</Col>


				</>
			) : null}
			{data.includingLandCost === 'false' ? (
				<>
					<Col md={3}>
						<Form.Group>
							<Form.Label>Purchased Date</Form.Label>
							<InputGroup>
								<Form.Control
									value={data.landPurchasedDate ? data.landPurchasedDate : ''}
									type={'text'}
									name={'landPurchasedDate'}
									onChange={handle}
								></Form.Control>
							</InputGroup>
						</Form.Group>
					</Col>
					<Col md={3}>
						<Form.Group>
							<Form.Label>Land Purchased Price</Form.Label>
							<InputGroup>
								<CurrencyInput
									name={'landPurchasedPrice'}
									decimalsLimit={2}
									allowNegativeValue={false}
									maxLength={10}
									prefix="$"
									className="form-control"
									onValueChange={(value, name) => handle(value, name)}
									defaultValue={data.landPurchasedPrice ? data.landPurchasedPrice : ''}
								/>
							</InputGroup>
						</Form.Group>
					</Col>
					<Col md={3}>
						<Form.Group>
							<Form.Label>Current Loan Balance</Form.Label>
							<InputGroup>
								<CurrencyInput
									name={'landLoanBalance'}
									decimalsLimit={2}
									allowNegativeValue={false}
									maxLength={10}
									prefix="$"
									className="form-control"
									onValueChange={(value, name) => handle(value, name)}
									defaultValue={data.landLoanBalance ? +data.landLoanBalance : ''}
								/>
							</InputGroup>
						</Form.Group>
					</Col>
					<Col md={3}>
						<Form.Group>
							<Form.Label>Total Loan Amount</Form.Label>
							<CurrencyInput
								name={'totalLoanAmt'}
								decimalsLimit={2}
								allowNegativeValue={false}
								maxLength={10}
								prefix="$"
								className="form-control"
								value={(isNaN(+data.landLoanBalance) ? '' : +data.landLoanBalance) + (isNaN(+data.loanAmt) ? '' : +data.loanAmt) ? (isNaN(+data.landLoanBalance) ? '' : +data.landLoanBalance) + (isNaN(+data.loanAmt) ? '' : +data.loanAmt) : ''}
							/>
						</Form.Group>
					</Col>
				</>
			) : null}
			<Col md={6}>
				<Form.Group>
					<Form.Label>Market Value After Completiton</Form.Label>
					<CurrencyInput
						name={'marketValueAfterCompletion'}
						decimalsLimit={2}
						allowNegativeValue={false}
						maxLength={10}
						prefix="$"
						className="form-control"
						onValueChange={(value, name) => handle(value, name)}
						defaultValue={data.marketValueAfterCompletion ? data.marketValueAfterCompletion : ''}
					/>
				</Form.Group>
			</Col>
			<Col md={6}>
				<Form.Group>
					<Form.Label>Loan to value(LTV)</Form.Label>
					<InputGroup>
						<Form.Control
							name={'ltv'}
							className="form-control"
							value={
								data.loanAmt && data.marketValueAfterCompletion
									? calculateLTV(data).toFixed(2)
									: ''
							}
						></Form.Control>
						<InputGroup.Append>
							<InputGroup.Text id="basic-addon2">%</InputGroup.Text>
						</InputGroup.Append>
					</InputGroup>
				</Form.Group>
			</Col>
			<Col sm={6}>
				<Form.Group>
					<Form.Label>Interest Rate Offered</Form.Label>
					<InputGroup>
						<Form.Control value={data.interestRate ? data.interestRate : ''} type={'number'} name={'interestRate'} onChange={handle}></Form.Control>
						<InputGroup.Append>
							<InputGroup.Text id="basic-addon1">%</InputGroup.Text>
						</InputGroup.Append>
					</InputGroup>
				</Form.Group>
			</Col>
			<Col sm={6}>
				<Form.Group>
					<Form.Label>Requsted Term</Form.Label>
					<InputGroup>
						<Form.Control value={data.term ? data.term : ''} type={'text'} name={'term'} onChange={handle}></Form.Control>
					</InputGroup>
				</Form.Group>
			</Col>
			{/* <Col sm={6}>
				<Form.Group>
					<Form.Label>Current Estimated Appraisal Value</Form.Label>
					<CurrencyInput
						name={'currentEstValue'}
						decimalsLimit={2}
						allowNegativeValue={false}
						maxLength={10}
						prefix="$"
						className="form-control"
						onValueChange={(value, name) => handle(value, name)}
						defaultValue={data.currentEstValue ? data.currentEstValue : ''}
					/>
				</Form.Group>
			</Col> */}
		</Row>
	);
};

export default Construction;
