import React from 'react';
import MainNavbar from '../nav/Navbar';
import Ribbon from '../Home/Ribbon';
import Translation from '../../Translation/Translation';

const Layout = props => {
	const { children, currentUserData } = props;
	return (
		<div className="d-flex">
			<section id="layout" className="w-100">
				<MainNavbar currentUserData={currentUserData} history={props.history}></MainNavbar>
				{children}
				<Translation></Translation>
			</section>
		</div>
	);
};

export default Layout;
