import React from 'react';
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import useResize from '../../utils/useResize';

const DashboardNavbar = props => {
	const { toggleHandler, toggled } = props;
	const logoutHandler = () => {
		axios.get(`/api/users/logout`).then(response => {
			if (response.data.success) {
				props.history.push('/login');
			} else {
				alert('Log Out Failed');
			}
		});
	};
	const { width } = useResize();

	return (
		<Navbar className="w-100 justify-content-between" id="dashboard-navbar">
			{width < 768 ? (
				<div className="menu" onClick={toggleHandler}>
					<FontAwesomeIcon icon={faBars} />
				</div>
			) : (
				''
			)}
			<div />
			<Nav>
				<NavDropdown
					title={
						<div className="profile-pill">
							<div className="profile-wrapper">
								<img alt="user default" src="/user/default.png" width="40px" height="40px" />
							</div>
							<div className="down-carrot-wrapper">
								<svg width="17" height="10" viewBox="0 0 17 10" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path d="M0.5 1L8.5 9L16.5 1" stroke="#666666" />
								</svg>
							</div>
						</div>
					}
					id="nav-acc-dropdown"
					alignRight
				>
					<NavDropdown.Item as={Link} className="no-deco dark-font" to={'/dashboard/my-inquiries'}>
						Dashboard
					</NavDropdown.Item>
					<NavDropdown.Divider />
					<NavDropdown.Item onClick={logoutHandler}>Logout</NavDropdown.Item>
				</NavDropdown>
			</Nav>
		</Navbar>
	);
};

export default DashboardNavbar;
