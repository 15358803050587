import React, { useState, useRef } from 'react';
import { Container, Row, Col, Button, Form } from 'react-bootstrap';
import ReCAPTCHA from 'react-google-recaptcha';
import axios from 'axios';
import { BRStick } from '../global/svgs';
import Alert from 'react-bootstrap/Alert';
import PhoneInput from 'react-phone-input-2';

const REACT_APP_RECAPTCHA_KEY = process.env.REACT_APP_RECAPTCHA_KEY;

const Contact = props => {
	const [Contact, setContact] = useState({
		firstName: '',
		lastName: '',
		email: '',
		phoneNumber: '',
		message: '',
	});

	const [alert, setAlert] = useState({
		status: '',
		message: '',
	});

	const { contactRef } = props;

	const reRef = useRef();

	const onContactHandler = e => {
		const { name, value } = e.target;

		setContact({ ...Contact, [name]: value });
	};

	const phoneNumberHandler = e => {
		setContact({ ...Contact, phoneNumber: e });
	};

	const onSubmitHandler = async e => {
		e.preventDefault();
		setAlert({ status: 'secondary', message: 'Sending a message...' });
		const token = await reRef.current.executeAsync();
		reRef.current.reset();
		let body = Object.assign({}, Contact);
		body = { ...body, token };
		axios.post('/api/mail/contact', body).then(res => {
			if (res.data.success) {
				setAlert({ status: 'success', message: 'We will be contacting you shortly!' });
			} else {
				// alert('Failed to send message!');
				setAlert({ status: 'warning', message: 'Failed to send message!' });
			}
		});
	};

	return (
		<section id="home-contact-us" ref={contactRef}>
			<div className="bg-illust-wrapper">
				<figure className="left-waffle" />
				<figure className="right-waffle" />
			</div>
			<Container className="py-5" fluid>
				<Row className="py-5 justify-content-sm-center">
					<Col xs={12} md={5} className="my-4 pr-md-5 pl-md-0 px-4">
						<div className="contact-us-map">
							<iframe
								src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3305.2827459556925!2d-118.30068428478478!3d34.06226548060336!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80c2c77d76d36f95%3A0x323aaaf467c15887!2s3435%20Wilshire%20Blvd%2014th%20floor%2C%20Los%20Angeles%2C%20CA%2090010!5e0!3m2!1sen!2sus!4v1614128577136!5m2!1sen!2sus"
								width="100%"
								height="100%"
								allowFullScreen=""
								style={{ border: '0' }}
								loading="lazy"
								title="googlemap"
							></iframe>
						</div>
					</Col>
					<Col xs={12} md={5} className="my-4 px-md-0 px-5 pt-md-3 pt-0">
						<h2 className="contact-us-headline">Contact Us</h2>
						<span className="contact-us-subhead">Have any questions or concerns?</span>
						<div className="mt-2">
							<BRStick />
						</div>
						<div className="mt-3">
							{alert.status.length > 0 ? (
								<Alert variant={alert.status}>{alert.message}</Alert>
							) : (
								<form onSubmit={onSubmitHandler}>
									<Row>
										<Col xs={12} sm={6} className="pr-sm-2 pl-sm-3 px-3 mb-3">
											<Form.Control required type="text" name="firstName" value={Contact.firstName} onChange={onContactHandler} placeholder="First Name" />
										</Col>
										<Col xs={12} sm={6} className="pl-sm-2 pr-sm-3 px-3 mb-3">
											<Form.Control required type="text" name="lastName" value={Contact.lastName} onChange={onContactHandler} placeholder="Last Name" />
										</Col>
										<Col xs={12} className="px-3 mb-3">
											<Form.Control
												required
												type="email"
												name="email"
												autoComplete="email"
												value={Contact.email}
												onChange={onContactHandler}
												placeholder="Email Address"
											/>
										</Col>
										<Col xs={12} className="px-3 mb-3">
											<PhoneInput
												specialLabel={false}
												country="us"
												onlyCountries={['us']}
												disableDropdown
												disableCountryCode
												value={Contact?.phoneNumber ? Contact.phoneNumber.toString() : ''}
												placeholder={'Phone Number'}
												name="phoneNumber"
												onChange={phoneNumberHandler}
											/>
										</Col>
										<Col xs={12} className="px-3 mb-3">
											<Form.Control
												required
												name="message"
												value={Contact.message}
												onChange={onContactHandler}
												as="textarea"
												rows="5"
												style={{ resize: 'none' }}
												placeholder="Your Message"
											/>
										</Col>
									</Row>

									<Row xs={12} className="button-wrapper mt-2 justify-content-center justify-content-md-start ml-0">
										<Button type="submit" className="primary-button  mr-3">
											<span className="button-inner-text">Send Message</span>
										</Button>
									</Row>
								</form>
							)}
						</div>
						<ReCAPTCHA sitekey={REACT_APP_RECAPTCHA_KEY} size="invisible" badge={'bottomleft'} ref={reRef} />
					</Col>
				</Row>
			</Container>
		</section>
	);
};

export default Contact;
