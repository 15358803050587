export const faqs = [
	{
		q: '브릿지론/하드머니가 무엇인가요?',
		a:
			'하드 머니는 은행, 신용 조합 및 기타 기관 대출 기관과 같은 전통적인 대출 시스템 외부에 존재하는 자본 유형입니다. 하드 머니 자금 조달은 개인 투자자와 개인으로부터 나옵니다. 개인자금이라고도 합니다.',
	},
	{
		q: '하드 머니 대출이란 무엇입니까?',
		a: '하드 머니 론은 부동산을 담보로 하는 대출의 한 유형입니다. 경질 대출은 차용인의 신용 가치가 아니라 주로 담보로 사용되는 자산의 가치를 기반으로 하는 조건이 있습니다.',
	},
	{
		q: '하드 머니 대출의 이점은 무엇입니까?',
		a: [
			'은행 대출에 비해 신청 절차가 빠르고 쉽습니다.',
			'하드 머니는 은행 대출에 비해 요구 사항과 필요한 서류가 훨씬 적습니다',
			'하드 머니는 일반적으로 5~10일 이내에 매우 빠르게 자금을 조달할 수 있습니다',
			'하드 머니 융자를 포함한 구매 제안은 종종 기존 은행 융자에 비해 더 강력한 제안으로 간주됩니다',
			'하드 머니 조달을 통해 구매자가 더 나은 구매 가격을 협상할 수 있습니다',
			'차용자(채무자)는 낮은 신용 점수, 불충분한 소득 이력, 최근 공매도, 판매, 압류 또는 파산과 같은 문제가 있어도 여전히 어려운 자금 조달을 받을 수 있습니다',
			'다른 곳에 자본을 재투자하기 위해 기존 자산에서 신속하게 자본을 빼내다',
			'차용자(채무자)는 기존 대출자로부터 대출 한도에 도달하면 하드 자금 조달을 얻을 수 있습니다',
		],
	},
	{
		q: '돈을 빌려주는 사람들은 누구입니까?',
		a:
			'하드 머니 투자자(프라이빗 머니 렌더)는 차용인과 투자자 사이의 중개자 역할을 하는 모기지 브로커입니다. 현금 대출 기관은 당사자를 모아 조건에 동의하고 대출 문서를 작성하며 대출 서비스를 제공할 책임이 있습니다. 어떤 경우에는 현금 대출 기관이 대출 기관이 관리하는 펀드를 통해 차용인에게 자본을 제공하는 투자자입니다.',
	},
	{ q: '금리는 얼마입니까?', a: '이자율은 부동산의 위치와 상황에 따라 다릅니다.' },
	{
		q: '기간은 어떻게 됩니까?',
		a: '일반적인 하드 머니 기간은 일반적으로 12개월이지만 특정 상황 및 차용인에 따라 3-4년의 더 긴 기간이 제공될 수 있습니다.',
	},
	{
		q: '은행 대출보다 하드 머니 대출 금리가 높은 이유는?',
		a:
			'은행은 예금자로부터 돈을 보유하고 매우 낮은 이자를 지급합니다. 그런 다음 은행은 이 돈을 차용자에게 더 높지만 상대적으로 낮은 이자율로 대출합니다. 하드 머니 대출 기관은 대출 기관이 감수하는 위험 증가로 인해 더 높은 이자율을 기대하는 개인 투자자(또는 대출 자체에 자금을 조달)로부터 자금을 받습니다.',
	},
	{
		q: '하드 머니 대출에서 자금을 받는 데 얼마나 걸립니까?',
		a:
			'사업 목적 대출의 경우, 하드 머니 대출은 1~2주 이내에 자금을 조달할 수 있습니다. 차용인이 필요로 하는 경우 신뢰할 수 있는 하드 머니 대출 기관은 3-5일 만에 대출 자금을 조달할 수 있습니다. 은행이 엄격한 지침에 해당하는 경우 동일한 대출 요청에 자금을 조달하는 데 30일 이상이 소요될 수 있으므로 이는 하드 머니 대출을 사용하는 데 큰 이점입니다.',
	},
	{
		q: '신탁 증서 투자는 어떤 유형의 자산으로 보호됩니까?',
		a:
			'신탁 증서의 담보로 사용되는 부동산 유형은 대출에 따라 다릅니다. 신탁 증서 투자자는 대출이 투자자의 투자 기준을 충족하는지 여부를 결정하기 전에 대출의 특정 자산과 상황을 검토할 수 있습니다.',
	},
	{
		q: '신탁 증서에 투자하면 무엇을 받습니까?',
		a:
			'약속 어음, 공증된 신탁 증서, 대출 기관 소유권 보험 증권, 소유권 회사에 대한 소유권 보고서, 차용인의 개인 보증, 모기지 요약서, 모기지 신청서, 모기지 제공 계약서, 부동산 감정 보고서를 받습니다. , 그리고 차용인의 신용 보고서.',
	},
	{
		q: '차용인이 대출금을 상환하지 않으면 어떻게 됩니까?',
		a:
			'차용인이 대출금을 상환하지 못하면 신탁 증서 투자자는 투자를 회수하기 위해 해당 부동산을 압류할 수 있습니다. 낮은 LTV(Loan to Value Ratio)를 통해 신탁 증서 투자자는 초기 투자를 회수하고 차용인이 채무 불이행 시 상당한 이익을 얻을 수 있도록 할인된 가격으로 부동산을 신속하게 매각할 수 있습니다.',
	},
	{
		q: '최소/최대 대출 금액은 얼마입니까?',
		a: '우리는 부동산 유형, 위치, 가치 대부금 및 기타 여러 상황에 따라 $30,000에서 $600,000,000까지 대출합니다.',
	},
	{
		q: '신용불량자도 대출을 받을 수 있나요?',
		a:
			'하드 머니 대금업자는 차용인의 신용 및 FICO 점수에 훨씬 덜 관심이 있습니다. 경질 대출 기관은 대신 구매하거나 재융자할 부동산의 자산에 중점을 둡니다. 신용 점수만으로는 신용이 나쁜 차용인이 고액 대출을 받는 것을 막을 수 없습니다.',
	},
];
