import React from 'react';
import { Row, Col } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';

const SubjectPropertyAddr = ({ handle, data }) => {
	const items = [
		{
			placeholder: 'address line 1',
			name: 'line1',
			required: true,
			grid: {
				sm: '12',
				md: '6',
				lg: '6',
			},
		},
		{
			placeholder: 'address line 2',
			name: 'line2',
			grid: {
				sm: '12',
				md: '6',
				lg: '6',
			},
		},
		{
			placeholder: 'city',
			name: 'city',
			required: true,
			grid: {
				sm: '12',
				md: '2',
				lg: '2',
			},
		},
		{
			placeholder: 'state',
			name: 'state',
			required: true,
			grid: {
				sm: '12',
				md: '2',
				lg: '2',
			},
		},
		{
			placeholder: 'zip',
			name: 'zip',
			required: true,
			grid: {
				sm: '12',
				md: '2',
				lg: '2',
			},
		},
		{
			placeholder: 'APN#',
			name: 'APN',
			type: 'text',
			grid: {
				sm: '12',
				md: '4',
				lg: '4',
			},
		},
		{
			placeholder: 'description',
			name: 'description',
			type: 'text',
			grid: {
				sm: '12',
				md: '12',
				lg: '12',
			},
		},
	];
	return (
		<Row>
			<Col sm={12}>
				<h3>{data.loanType === 'construction/project' ? 'Site Information' : 'Subject Property Address'}</h3>
				<Row>
					{items.map((item, index) => {
						if (data.loanType === 'construction/project' && item.name === 'line2') {
							return <></>;
						} else {
							return (
								<Col key={index} sm={item.grid.sm ? item.grid.sm : null} md={item.grid.md ? item.grid.md : null} lg={item.grid.lg ? item.grid.lg : null}>
									<Form.Group key={index}>
										<Form.Label>
											{item.placeholder}
											{item.required === true ? <sup className="asterisk red">*</sup> : ''}
										</Form.Label>
										<Form.Control
											value={data.subjectPropertyAddr[item.name] ? data.subjectPropertyAddr[item.name] : ''}
											type="text"
											name={item.name}
											onChange={handle}
											id={item.name}
										/>
									</Form.Group>
								</Col>
							);
						}
					})}
				</Row>
			</Col>
		</Row>
	);
};

export default SubjectPropertyAddr;
