import React from 'react';
import Paper from '@material-ui/core/Paper';
import GoogleMapReact from 'google-map-react';
import { makeStyles } from '@material-ui/core/styles';

const GOOGLE_MAP_API = process.env.REACT_APP_GOOGLE_MAP_API_KEY;

const useStyles = makeStyles(theme => ({
	googleMapApi: {
		height: '500px',
		alignItems: 'center',
		margin: 'auto',
		backgroundColor: '#ccc',
		marginBottom: '2rem',
	},
}));

const LocationPin = () => {
	return <div className="pulse" />;
};

const Maps = ({ propertyInfo }) => {
	const classes = useStyles();
	const zoom = 13;
	const Center = {
		lat: propertyInfo.subjectPropertyAddr.location.coordinates[1],
		lng: propertyInfo.subjectPropertyAddr.location.coordinates[0],
	};

	return (
		<Paper className={classes.googleMapApi}>
			<GoogleMapReact bootstrapURLKeys={{ key: GOOGLE_MAP_API }} defaultCenter={Center} defaultZoom={zoom} options={{ maxZoom: 13 }}>
				<LocationPin key="map-marker" lat={propertyInfo.subjectPropertyAddr.location.coordinates[1]} lng={propertyInfo.subjectPropertyAddr.location.coordinates[0]} />
			</GoogleMapReact>
		</Paper>
	);
};

export default Maps;
