import React from 'react';
import './index.css';
import App from './App';
import { Provider } from 'react-redux';
import { applyMiddleware, createStore } from 'redux';
import promiseMiddleware from 'redux-promise';
import ReduxThunk from 'redux-thunk';
import Reducer from './_reducers';
import { BrowserRouter as Router } from 'react-router-dom';
import axios from 'axios';
import { hydrate, render } from 'react-dom';

if (process.env.NODE_ENV === 'production') {
	axios.defaults.baseURL = process.env.REACT_APP_PROXY_URL || 'http://127.0.0.1:5002';
	axios.defaults.withCredentials = true;
}

const createStoreWithMiddleware = applyMiddleware(promiseMiddleware, ReduxThunk)(createStore);

const APP = (
	<Provider store={createStoreWithMiddleware(Reducer, window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__())}>
		<Router>
			{/* <ScrollToTop> */}
			<App />
			{/* </ScrollToTop> */}
		</Router>
	</Provider>
);

const rootElement = document.getElementById('root');
if (rootElement.hasChildNodes()) {
	hydrate(APP, rootElement);
} else {
	render(APP, rootElement);
}
