export const faqs = [
	{
		q: 'What is hard money?',
		a:
			'Hard money is a type of capital that exists outside of traditional lending systems such as banks, credit unions and other institutional lenders. Hard money funding comes from private investors and individuals. It is also known as private money.',
	},
	{
		q: 'What is a hard money loan?',
		a:
			'A hard money loan is a type of loan that is secured by real property. Hard money loans have terms that are based mainly on the value of the property being used as collateral, not on the credit worthiness of the borrower.',
	},
	{
		q: 'What are the benefits to a hard money loan?',
		a: [
			'The application process is quick and easy compared to a bank loan',
			'Hard money loans have far fewer requirements and necessary documentation compared with bank loans',
			'Hard money loans can be funded very quickly, usually within 5-10 days',
			'Purchase offers that include hard money financing are often considered a stronger offer compared to conventional bank financing',
			'Hard money financing may allow the buyer to negotiate a better purchase price',
			'A borrower can still obtain hard money financing even if they have issues such as low credit scores, insufficient income history, recent short, sales, foreclosures or bankruptcies',
			'Quickly pulling out equity out of an existing property in order to reinvest capital elsewhere',
			'A borrower can obtain hard money financing once they have hit a limit on loans from conventional lenders',
		],
	},
	{
		q: 'Who are hard money lenders?',
		a:
			'A hard money lender (private money lender) is a mortgage broker who acts as the intermediary between the borrower and the investor. The hard money lender is responsible for bringing the parties together to agree on terms, completing the loan documentation and servicing the loan. In some cases the hard money lender is an investor providing the capital to the borrower through a fund managed by the lender.',
	},
	{ q: 'What are the interest rates?', a: 'The interest rate depends on the location and circumstances of the property.' },
	{
		q: 'What are the terms?',
		a: 'A typical hard money term is generally 12 months, but longer terms of 3-4 years may be available depending on the specific situation and borrower.',
	},
	{
		q: 'Why are hard money loan interest rates higher than loans from banks?',
		a:
			'Banks hold money from their depositors and pay them an extremely low interest rate. The bank then loans this money out to borrowers at a higher but relatively low interest rate. Hard money lenders get funds from private investors (or fund the loans themselves) who expect a higher interest rate due to the increased risk taken on by the lender.',
	},
	{
		q: 'How long does it take to receive funding from a hard money loan?',
		a:
			'For a business purpose loan, the hard money loan can be funded within a week or two. If needed by the borrower, a reliable hard money lender can fund a loan in a matter of 3-5 days. This is a huge benefit to using hard money loans as banks could take 30 days or longer to fund the same loan request if it were to fall within their stringent guidelines.',
	},
	{
		q: 'What type of property will the trust deed investment be secured by?',
		a:
			'The type of property used as collateral for the trust deed will vary from loan to loan. The trust deed investor will be able to review the specific property and circumstances of the loan prior to deciding whether or not the loan meets the investor’s investment criteria.',
	},
	{
		q: 'When you invest in a trust deed, what do you receive?',
		a:
			'You receive a promissory note, a notarized deed of trust, lender title insurance policy, a title report for the title company, a personal guarantee from the borrower, mortgage summary, the mortgage application, a mortgage serving agreement, an appraisal report of the property, and the credit report of the borrower.',
	},
	{
		q: 'What happens if a borrower doesn’t pay back the loan?',
		a:
			'If the borrower fails to pay back the loan, the trust deed investor is able to foreclose on the property in order to recover their investment. A lower loan to value ratio (LTV) allows the trust deed investor to sell the property quickly at a discounted price in order to recover their initial investment and possibly make a significant profit if a borrower defaults.',
	},
	{
		q: 'What is your minimum/maximum loan amount?',
		a: 'We lend out anywhere from $30,000 to $600,000,000 depending on the property type, location, loan to value, and many other circumstances.',
	},
	{
		q: 'Can I still get a hard money loan with bad credit?',
		a:
			'Hard money lenders are much less concerned with a borrower’s credit and FICO scores. Hard money lenders instead focus on the equity in the property being purchased or refinanced. Credit scores alone should not prevent a borrower with a bad credit from obtaining a hard money loan.',
	},
];
